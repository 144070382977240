import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
}                   from '@angular/core';
import { debounce } from '@evermed-sdk/core';
import {
    Checkpoint,
    ContinuitySelector,
    File as FileEntity,
    FileDownloader,
    FileReferenceInterface,
    NotExistsError,
    Resume,
    UnauthorizedError,
}                             from '@evermed/core';
import { Store }              from '@ngxs/store';

@Component({
    selector:        'app-pdf-viewer',
    templateUrl:     './pdf-viewer.component.html',
    styleUrls:       ['./pdf-viewer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfViewerComponent implements OnChanges, OnDestroy {

    @Input()
    public file: FileEntity = null;

    /**
     * Note that embedded component do not use zero-base indexing of
     * page, while we are using for progress tracking. Continuity must
     * be aware of that fact.
     */
    public page: number = 1;

    /**
     * Reference to a Blob of downloaded PDF. Note that a reference MUST
     * be cleaned up after new PDF is loaded or component is destroyed,
     * otherwise, we will have a memory leak.
     */
    public pdf: string = null;

    /**
     * A simple flag denoting type of error that occurred while trying to
     * download PDF file. NULL means "no error".
     */
    public error: 'notExists' | 'unauthorized' | 'unknown' | null = null;

    public busy: boolean = true;

    private readonly _downloader: FileDownloader;

    private readonly _cdr: ChangeDetectorRef;

    private readonly _store: Store;

    public constructor(
        downloader: FileDownloader,
        cdr: ChangeDetectorRef,
        store: Store,
    ) {
        this._downloader = downloader;
        this._cdr        = cdr;
        this._store      = store;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // file is not subject of the change
        if (!changes.file) {
            return;
        }

        let previous: FileReferenceInterface = changes.file.previousValue;
        let current: FileReferenceInterface  = changes.file.currentValue;

        // there is no current value associated at all, so we will just remove it
        if (!current) {
            this.release();
            this._cdr.markForCheck();
            return;
        }

        // it is actually a same file reference, source have not been changed.
        if (previous && previous.getIdentifier() === current.getIdentifier()) {
            return;
        }

        this.setUpDocument().then(/* noop */);
    }

    public ngOnDestroy(): void {
        this.release();
    }

    public pageChange: (page: number) => Promise<void> = debounce(async (page: number): Promise<void> => {
        // fire and forget
        this._store.dispatch(new Checkpoint(this.file, page - 1));
    }, 1000);

    private async setUpDocument(): Promise<void> {
        this.release();
        this.busy = true;
        this._cdr.markForCheck();

        try {
            await this._store.dispatch(new Resume(this.file)).toPromise();
            this.page = this._store.selectSnapshot<number>(ContinuitySelector.resume(this.file)) + 1;
        } catch (e) {
            // noop
        }

        try {
            let data: Blob = await this._downloader.download(this.file);
            this.pdf       = URL.createObjectURL(data);
        } catch (e) {
            if (e instanceof NotExistsError) {
                this.error = 'notExists';
                return;
            }

            if (e instanceof UnauthorizedError) {
                this.error = 'unauthorized';
                return;
            }

            this.error = 'unknown';

            throw e;
        } finally {
            this.busy = false;
            this._cdr.markForCheck();
        }
    }

    private release(): void {
        this.error = null;

        if (null === this.pdf) {
            return;
        }

        try {
            URL.revokeObjectURL(this.pdf);
        } catch (e) {
            // noop
        }

        this.pdf  = null;
        this.page = 1;
    }

}
