/**
 * Localization depends on the compilation environment, so therefore, this is
 * the right place to import and register necessary locales.
 */
import { registerLocaleData } from '@angular/common';

import localeEN from '@angular/common/locales/en';
import localeES from '@angular/common/locales/es';
import localePT from '@angular/common/locales/pt';
import localeRU from '@angular/common/locales/ru';
import localeZH from '@angular/common/locales/zh';
import localeFR from '@angular/common/locales/fr';
import localeDE from '@angular/common/locales/de';
import localeIT from '@angular/common/locales/it';

registerLocaleData(localeEN);
registerLocaleData(localeES);
registerLocaleData(localePT);
registerLocaleData(localeRU);
registerLocaleData(localeZH); // ZH is locale code for China
registerLocaleData(localeFR);
registerLocaleData(localeDE);
registerLocaleData(localeIT);

export const environment: any = {
    production:              true,
    endpoint:                'https://api.evermedtv.com/api',
    webSocketEndpoint:       'wss://rts.evermedtv.com',
    googleTagManagerEnabled: true,
    locales:                 ['en', 'ru', 'zh', 'es', 'pt', 'fr', 'de', 'it'],
    googleTagManagerId:      'GTM-KGSVDMT',
    googleTagManagerAuth:    null,
    googleTagManagerPreview: null,
    googleAnalyticsEnabled:  true,
    googleAnalyticsId:       'UA-123440629-1',
    chineseMarket:           false,
    icpLicenceNo:            null,
    landingPageTargetUrl:    'https://landing.evermedtv.com/landing-page',
    googleReCaptchaEnabled:  true,
    googleReCaptchaSiteKey:  '6LfG3nwcAAAAALuOquhRgML2yk55E0rJXJcIgXU-',

    cdnFilters: [
        /^https?:\/\/.+s3\.amazonaws\.com\/.+/ig,           // Every S3 bucket on us-east-1 region is actually a CDN
        /^https?:\/\/.+s3-.+\.amazonaws\.com\/.+/ig,        // Every S3 bucket on on any other region is actually a CDN
        /^https?:\/\/.+-cdn\.evermedtv\.com\/.+/ig,         // of course, every EvermedTV CDN is covered with one expression
    ],
};
