import {
    DOCUMENT,
    ViewportScroller,
}                                from '@angular/common';
import {
    Inject,
    NgModule,
}                                from '@angular/core';
import {
    Routes,
    RouterModule,
    Event,
    Scroll,
    Router,
}                                from '@angular/router';
import { AuthGuard }             from '@evermed/core';
import {
    filter,
    pairwise,
}                                from 'rxjs/operators';
import { PageComponent }         from '../shared';
import { Error404PageComponent } from '../static/pages/error/error-404.component';

const routes: Routes = [
    {
        path:      '',
        component: PageComponent,
        children:  [
            {
                path:         '',
                loadChildren: (): Promise<any> => import('../static/static.module').then((m: any) => m.StaticModule),
            },
            {
                path:         '',
                loadChildren: (): Promise<any> => import('../user/user.module').then((m: any) => m.UserModule),
            },
            {
                path:         '',
                loadChildren: (): Promise<any> => import('../products/products.module').then((m: any) => m.ProductsModule),
                canActivate:  [AuthGuard],
            },
            {
                path:         '',
                loadChildren: (): Promise<any> => import('../video/video.module').then((m: any) => m.VideoModule),
            },
            {
                path:         '',
                loadChildren: (): Promise<any> => import('../search/search.module').then((m: any) => m.SearchModule),
            },
            {
                path:         '',
                loadChildren: (): Promise<any> => import('../event/event.module').then((m: any) => m.EventModule),
                canActivate:  [AuthGuard],
            },
            {
                path:         '',
                loadChildren: (): Promise<any> => import('../document/document.module').then((m: any) => m.DocumentModule),
            },
            {
                path:         '',
                loadChildren: (): Promise<any> => import('../virtual-booth/virtual-booth.module').then((m: any) => m.VirtualBoothModule),
                canActivate:  [AuthGuard],
            },
            {
                path:         '',
                loadChildren: (): Promise<any> => import('../contact/contact.module').then((m: any) => m.ContactModule),
            },
        ],
    },
    {
        // TODO: Should we redirect here to "/error404" page instead of including component from different module?
        path:      '**',
        component: Error404PageComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            /**
             * Currently disabled, hack implemented below.
             */
            scrollPositionRestoration: 'disabled',
            initialNavigation:         'enabled',
        })],
    exports: [RouterModule],
})
export class AppRoutingModule {

    public constructor(
        router: Router,
        viewportScroller: ViewportScroller,
        @Inject(DOCUMENT) document: Document,
    ) {
        /**
         * Hack that we have to implement due to unresolved issue reported on github.
         * In general, for some subroutes, we do not want to navigate to top, therefore,
         * we manually store state within route navigation to skip scroll to top.
         *
         * @see https://github.com/angular/angular/issues/26744
         *
         * Hack is based on gist on github.
         *
         * @see https://gist.github.com/iffa/9c820072135d25a6372d58075fe264dd
         */
        router.events.pipe(
            filter((e: Event): e is Scroll => e instanceof Scroll),
            pairwise(),
        ).subscribe((e: [Scroll, Scroll]): void => {
            let previous: Scroll = e[0];
            let current: Scroll  = e[1];

            if (current.position) {
                viewportScroller.scrollToPosition(current.position);
                return;
            }

            if (current.anchor) {
                // Anchor navigation
                viewportScroller.scrollToAnchor(current.anchor);
                return;
            }

            if (previous.routerEvent.url === current.routerEvent.url) {
                return;
            }

            if (document.defaultView.history.state.noscroll) {
                return;
            }

            viewportScroller.scrollToPosition([0, 0]);
        });
    }

}
