import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';

@Component({
    selector:        'app-infinite-scroll-container',
    templateUrl:     './infinite-scroll-container.component.html',
    styleUrls:       ['./infinite-scroll-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteScrollContainerComponent {

    @Input()
    public scrollX: boolean = false;

    @Input()
    public scrollY: boolean = true;

    @HostBinding('class')
    public get scrolling(): string {
        if (this.scrollX && this.scrollY) {
            return 'booth';
        }

        if (!this.scrollX && !this.scrollY) {
            return 'none';
        }

        if (this.scrollX) {
            return 'scroll-x';
        }

        return 'scroll-y';
    }

}
