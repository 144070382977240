import { ComponentRef } from '@angular/core';
import { Store }        from '@ngxs/store';
import { filter }       from 'rxjs/operators';
import {
    User,
    UserSelector,
    VerifyConsentable,
}                       from '@evermed/core';
import { AppComponent } from '../app.component';

export function requireUserProfileConsents(
    store: Store,
): (ref: ComponentRef<any>) => void {
    return (ref: ComponentRef<any>): void => {

        if (!(ref.instance instanceof AppComponent)) {
            return;
        }

        store
            .select(UserSelector.current)
            .pipe(filter((user: User | null): boolean => !!user))
            .subscribe((user: User): void => {
                store.dispatch(new VerifyConsentable(user));
            });
    };
}
