import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';

@Component({
    selector:        'app-register-to-access-content-advisory',
    templateUrl:     './register-to-access-content-advisory.component.html',
    styleUrls:       ['./register-to-access-content-advisory.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterToAccessContentAdvisoryComponent {

}
