import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
}                     from '@angular/core';
import {
    Select,
    Store,
}                     from '@ngxs/store';
import { Observable } from 'rxjs';
import {
    LocaleSelector,
    SetLocale,
}                     from '@evermed/core';

@Component({
    selector:        'app-locale-picker',
    templateUrl:     './locale-picker.component.html',
    styleUrls:       ['./locale-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalePickerComponent {

    @Select(LocaleSelector.current)
    public readonly current$: Observable<string>;

    @Select(LocaleSelector.locales)
    public readonly locales$: Observable<string[]>;

    public get open(): boolean {
        return this._open;
    }

    public get busy(): boolean {
        return this._busy;
    }

    private _open: boolean = false;

    private _busy: boolean = false;

    private readonly _store: Store;

    private readonly _cdr: ChangeDetectorRef;

    public constructor(store: Store, cdr: ChangeDetectorRef) {
        this._store = store;
        this._cdr   = cdr;
    }

    public async set(locale: string): Promise<void> {
        this._open = false;
        this._busy = true;

        this._cdr.markForCheck();

        await this._store.dispatch(new SetLocale(locale)).toPromise();

        this._busy = false;
        this._cdr.markForCheck();
    }

    public toggle(): void {
        this._open = !this._open;
    }

    public close(): void {
        this._open = false;
    }

}
