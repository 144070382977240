import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
}                                 from '@angular/core';
import { AuthMetadataInterface }  from '@evermed-sdk/core';
import { VideoOnDemandInterface } from '@evermed/core';

@Component({
    selector:        'app-video-on-demand-preview-player',
    templateUrl:     './video-on-demand-preview-player.component.html',
    styleUrls:       ['./video-on-demand-preview-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoOnDemandPreviewPlayerComponent implements OnChanges {

    @Input()
    public video: VideoOnDemandInterface;

    @Input()
    public watermark: boolean = true;

    public metadata: AuthMetadataInterface = null;

    public thumbnail: AuthMetadataInterface = null;

    public showOverlay: boolean = false;

    private readonly _cdr: ChangeDetectorRef;

    public constructor(cdr: ChangeDetectorRef) {
        this._cdr = cdr;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.video) {
            return;
        }

        if (changes.video.currentValue?.getIdentifier() === changes.video.previousValue?.getIdentifier()) {
            // Video has been changed, but not an actual playback data.
            // In that matter, we will propagate change, but not re-render
            // the player.
            this._cdr.markForCheck();
            return;
        }

        if (!changes.video.currentValue) {
            this.metadata    = null;
            this.thumbnail   = null;
            this.showOverlay = false;
            return;
        }

        this.showOverlay = false;
        this.thumbnail   = this.video.thumbnail;
        this.metadata    = null;
        this._cdr.markForCheck();

        this.loadMetadata().then(/* noop */);
    }

    public onError(): void {
        this.metadata = null;
    }

    private async loadMetadata(): Promise<void> {
        let metadata: AuthMetadataInterface = null;

        try {
            metadata = await this.video.video.metadata;
        } catch (e) {
            // noop
        }

        this.metadata    = metadata && metadata.accessible ? metadata : null;
        this.showOverlay = null === this.metadata;
        this._cdr.markForCheck();
    }

}
