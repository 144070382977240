import * as CountryData    from 'i18n-iso-countries/langs/en.json';
import { ChoiceInterface } from '@evermed/core';

export class Country implements ChoiceInterface {

    private readonly _code: string;

    private readonly _name: string;

    public constructor(code: string, name: string) {
        this._code = code;
        this._name = name;
    }

    public get code(): string {
        return this._code;
    }

    public get name(): string {
        return this._name;
    }

    public get label(): string {
        return this._name;
    }

    public get value(): string {
        return this._code;
    }

    // eslint-disable-next-line class-methods-use-this
    public get children(): ChoiceInterface[] {
        return [];
    }

}

let countries: Country[] = [];

export function getCountries(): Country[] {
    if (0 === countries.length) {
        Object
            .entries(CountryData.countries)
            .forEach((row: [string, string]): void => {
                let [key, value]: [string, string] = row;

                // Kosovo is not official part of ISO standards, dirty fix of #EV-317
                if ('XK' === key) {
                    return;
                }

                countries.push(new Country(key, Array.isArray(value) ? value[0] : value));
            });
    }

    return countries;
}
