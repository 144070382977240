import {
    Component,
    forwardRef,
    Input,
    Type,
    ViewChild,
}                         from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
}                         from '@angular/forms';
import {
    UntilDestroy,
    untilDestroyed,
}                         from '@ngneat/until-destroy';
import {
    DatePickerComponent as DatePicker,
    IDatePickerDirectiveConfig,
}                         from 'ng2-date-picker';
import moment             from 'moment';
import { Store }          from '@ngxs/store';
import { LocaleSelector } from '@evermed/core';

@UntilDestroy()
@Component({
    selector:    'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls:   ['./date-picker.component.scss'],
    providers:   [{
        // eslint-disable-next-line no-use-before-define
        useExisting: forwardRef((): Type<DatePickerComponent> => DatePickerComponent),
        multi:       true,
        provide:     NG_VALUE_ACCESSOR,
    }],
})
export class DatePickerComponent implements ControlValueAccessor {

    @Input()
    public placeholder: string = '';

    @Input()
    public time: boolean = false;

    @Input()
    public disabled: boolean;

    public config: IDatePickerDirectiveConfig = {
        weekDayFormat:  'dd',
        monthFormat:    'MMMM, YYYY',
        dayBtnFormat:   'D',
        firstDayOfWeek: 'mo',
        format:         moment.localeData('en').longDateFormat('L'),
        locale:         'en',
    };

    public value: any;

    private _onChangeHandler: (_: any) => void;

    private _onTouchedHandler: () => void;

    @ViewChild('picker')
    private readonly _datePicker: DatePicker;

    public constructor(store: Store) {
        store.select(LocaleSelector.current).pipe(untilDestroyed(this)).subscribe((locale: string): void => {
            if (!locale) {
                return;
            }

            this.config = {
                ...this.config,
                ...{
                    format: moment.localeData(locale).longDateFormat('L'),
                    locale: locale,
                },
            };
        });
    }

    public change(event: any): void {
        if (!this._onChangeHandler) {
            return;
        }

        this._onChangeHandler(event ? event.toDate() : null);
        this._onTouchedHandler();
    }

    public clear(): void {
        this.value = null;
    }

    public registerOnChange(fn: any): void {
        this._onChangeHandler = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouchedHandler = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(obj: any): void {
        this.value = obj;
    }

}
