import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
}                                from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
}                                from '@angular/forms';
import { Deferred }              from '@evermed-sdk/core';
import { TranslateService }      from '@ngx-translate/core';
import {
    BehaviorSubject,
    Observable,
    Subject,
    Unsubscribable,
}                                from 'rxjs';
import { ChoiceInterface }       from '@evermed/core';
import { ChoiceSelectComponent } from '../choice-select/choice-select.component';

@Component({
    selector:        'app-enum-select',
    templateUrl:     './enum-select.component.html',
    styleUrls:       ['./enum-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers:       [
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line no-use-before-define
            useExisting: forwardRef(() => EnumSelectComponent),
            multi:       true,
        },
    ],
})
export class EnumSelectComponent implements ControlValueAccessor, AfterViewInit, OnChanges {

    @Input()
    public enum: { [key: string]: string } | Observable<{ [key: string]: string }>;

    @Input()
    public labelPrefix: string;

    @Input()
    public class: string;

    @Input()
    public placeholder: string;

    @Input()
    public disabled: boolean = true;

    @Input()
    public multiple: boolean = false;

    @Input()
    public required: boolean = false;

    public choices: Subject<ChoiceInterface[]> = new BehaviorSubject([]);

    public value: string | string[];

    @ViewChild('select')
    private readonly _select: ChoiceSelectComponent;

    private _onChangeHandler: (value: any) => void;

    private _onTouchedHandler: () => void;

    private _subscription: Unsubscribable = null;

    private readonly _cdr: ChangeDetectorRef;

    private readonly _hostElement: ElementRef;

    private readonly _translator: TranslateService;

    public constructor(
        cdr: ChangeDetectorRef,
        hostElement: ElementRef,
        translator: TranslateService,
    ) {
        this._cdr         = cdr;
        this._hostElement = hostElement;
        this._translator  = translator;
    }

    public ngAfterViewInit(): void {
        this._hostElement.nativeElement.classList.remove(this.class);
        this._select.registerOnChange((value: string | string[]): void => {
            this._onChangeHandler(value);
            this.value = value;
            this._cdr.markForCheck();
        });
        this._select.registerOnTouched(this._onTouchedHandler);
        this.writeValue(this.value);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.enum) {
            return;
        }

        if (this._subscription) {
            this._subscription.unsubscribe();
        }

        if (this.enum instanceof Observable) {
            this._subscription = this.enum.subscribe(this.createChoices.bind(this));
            return;
        }

        this.createChoices(this.enum).then(/* noop */);
    }

    public registerOnChange(fn: any): void {
        this._onChangeHandler = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouchedHandler = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this._cdr.markForCheck();
    }

    public writeValue(obj: any): void {
        this.value = obj;
        this._cdr.markForCheck();

        if (this._select) {
            this._select.writeValue(obj);
        }
    }

    private async createChoices(enumeration: { [key: string]: string }): Promise<void> {
        let keys: string[]                       = Object.values(enumeration);
        let promises: Promise<ChoiceInterface>[] = [];

        keys.forEach((key: string): void => {
            let deferred: Deferred<ChoiceInterface> = new Deferred<ChoiceInterface>();
            let labelTransKey: string               = this.labelPrefix ? `${this.labelPrefix}.${key}` : key;

            this._translator.get(labelTransKey).toPromise().then((label: string): void => {
                deferred.resolve({
                    label: label,
                    value: key,
                });
            });

            promises.push(deferred.promise);
        });

        let choices: ChoiceInterface[] = await Promise.all(promises);

        this.choices.next(choices);
    }

}
