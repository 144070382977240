import { DpDatePickerModule }             from 'ng2-date-picker';
import { NgxExtendedPdfViewerModule }     from 'ngx-extended-pdf-viewer';
import { ToastrModule }                   from 'ngx-toastr';
import { NgModule }                       from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
}                                         from '@angular/forms';
import { CommonModule }                   from '@angular/common';
import { RouterModule }                   from '@angular/router';
import { TranslateModule }                from '@ngx-translate/core';
import {
    DynamicIoModule,
    DynamicModule,
}                                         from 'ng-dynamic-component';
import { CoreModule }                     from '@evermed/core';
import { EvermedComponentsAngularModule } from '@evermed/components-angular';
import {
    EVERMED_ENCAPSULATED_COMPONENTS,
    EVERMED_SHARED_COMPONENTS,
    InterestsCarouselsRendererComponent,
}                                         from './components';
import { EVERMED_SHARED_DIRECTIVES }      from './directives';
import { EVERMED_SHARED_PIPES }           from './pipes';
import { EVERMED_SHARED_SERVICES }        from './services';

@NgModule({
    imports:      [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
        CoreModule,
        EvermedComponentsAngularModule,
        DynamicIoModule,
        DpDatePickerModule,
        // Needs to be registered for root.
        ToastrModule.forRoot({
            preventDuplicates: true,
        }),
        DynamicModule,
        NgxExtendedPdfViewerModule,
    ],
    declarations: [
        ...EVERMED_SHARED_DIRECTIVES,
        ...EVERMED_SHARED_PIPES,
        ...EVERMED_SHARED_COMPONENTS,
        ...EVERMED_ENCAPSULATED_COMPONENTS,
    ],
    exports:      [
        ...EVERMED_SHARED_DIRECTIVES,
        ...EVERMED_SHARED_PIPES,
        ...EVERMED_SHARED_COMPONENTS,
        InterestsCarouselsRendererComponent,
    ],
    providers:    [
        ...EVERMED_SHARED_SERVICES,
    ],
})
export class SharedModule {
}
