/* eslint-disable max-len */
import { DOCUMENT }     from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
}                       from '@angular/core';
import {
    LazyLoadedIterableInterface,
    isLazyLoadedIterable,
}                       from '@evermed/core';
import {
    UntilDestroy,
    untilDestroyed,
}                       from '@ngneat/until-destroy';
import { Store }        from '@ngxs/store';
import {
    fromEvent,
    Observable,
    Unsubscribable,
}                       from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * NOTE: this component does not handle changes in observables.
 */
@UntilDestroy()
@Component({
    selector:        'app-infinite-scroll',
    templateUrl:     './infinite-scroll.component.html',
    styleUrls:       ['./infinite-scroll.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteScrollComponent implements OnInit, OnChanges {

    /**
     * Collection for which infinite scroller is in charge to load, provided by parent component, or state.
     */
    @Input()
    public collection: Observable<LazyLoadedIterableInterface<any>> | LazyLoadedIterableInterface<any> | string | null;

    /**
     * A loading state, provided by either parent component, or state.
     */
    @Input()
    public loading: Observable<boolean> | boolean | string;

    /**
     * Inverse color of spinner.
     */
    @Input()
    public inverse: boolean = false;

    /**
     * Spinner type.
     */
    @Input()
    public spinner: 'circle' | 'spin' = 'circle';

    /**
     * Disable component.
     */
    @Input()
    public disabled: boolean = false;

    /**
     * Distance max distance of scroller from the bottom which triggers infinite scroll.
     */
    @Input()
    public threshold: number = 0;

    /**
     * Trigger loading immediately on load.
     */
    @Input()
    public initialize: boolean = true;

    @Output('infiniteScroll')
    private readonly _onScroll: EventEmitter<void> = new EventEmitter<void>();

    private readonly _cdr: ChangeDetectorRef;

    private readonly _store: Store;

    private readonly _elementRef: ElementRef;

    private readonly _document: Document;

    /**
     * A scrolling element which is being observed.
     */
    private _scrollContainer: Node;

    /**
     * Infinite scroll container.
     */
    private _element: HTMLElement;

    /**
     * A current collection value.
     */
    private _collection: LazyLoadedIterableInterface<any> | null = null;

    /**
     * A current collection value subscription.
     */
    private _collectionSubscription: Unsubscribable | null = null;

    /**
     * A current loading state indicator.
     */
    private _loading: boolean = null;

    /**
     * A current loading state indicator subscription.
     */
    private _loadingSubscription: Unsubscribable | null = null;

    public constructor(
        store: Store,
        cdr: ChangeDetectorRef,
        elementRef: ElementRef,
        @Inject(DOCUMENT) document: Document,
    ) {
        this._store      = store;
        this._cdr        = cdr;
        this._elementRef = elementRef;
        this._document   = document;
    }

    public ngOnInit(): void {
        this._element         = this._elementRef.nativeElement;
        this._scrollContainer = this._element.closest('app-infinite-scroll-container') || this._document;

        let listener: Observable<Event> = fromEvent(this._scrollContainer, 'scroll', {
            passive: true,
        });

        listener
            .pipe(untilDestroyed(this))
            .pipe(debounceTime(100))
            .subscribe((): void => {
                this.onScroll().then(/* noop */);
            });

        if (this.initialize) {
            this._onScroll.emit();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.collection) {
            this.setCollectionSubscription();
        }

        if (changes.loading) {
            this.setLoadingSubscription();
        }
    }

    public isLoading(): boolean {
        return this._loading;
    }

    public isDisabled(): boolean {
        let fullyLoaded: boolean = null !== this._collection && false === this._collection.hasMore;
        return this.disabled || this.isLoading() || fullyLoaded || !this._scrollContainer;
    }

    private async onScroll(): Promise<void> {
        if (this.isDisabled()) {
            return;
        }

        if (this._scrollContainer === this._document) {
            let window: Window             = this._document.defaultView;
            let rect: DOMRect | ClientRect = this._element.getBoundingClientRect();
            let top: number                = rect.top;

            if (top - this.threshold < window.innerHeight) {
                this._onScroll.emit();
                this._cdr.markForCheck();
            }

            return;
        }

        let scrollContainer: HTMLElement = this._scrollContainer as HTMLElement;
        let scrollTop: number            = scrollContainer.scrollTop;
        let scrollHeight: number         = scrollContainer.scrollHeight;
        let height: number               = scrollContainer.offsetHeight;
        let infiniteHeight: number       = this._element.offsetHeight;
        let distanceFromInfinite: number = scrollHeight - infiniteHeight - scrollTop - this.threshold - height;

        if (distanceFromInfinite < 0) {
            this._onScroll.emit();
            this._cdr.markForCheck();
        }
    }

    private setCollectionSubscription(): void {
        this._collection = null;
        this._cdr.markForCheck();

        this._collectionSubscription?.unsubscribe();
        this._collectionSubscription = null;

        if (!this.collection) {
            return;
        }

        if (isLazyLoadedIterable<any>(this.collection)) {
            this._collection = this.collection as LazyLoadedIterableInterface<any>;
            this._cdr.markForCheck();

            if (this._collection.hasMore) {
                // recalculate scroll position again.
                this.onScroll().then(/* noop */);
            }

            return;
        }

        let collection: Observable<LazyLoadedIterableInterface<any> | null>;
        collection = ('string' === typeof this.collection ? this._store.select(this.collection) : this.collection) as Observable<LazyLoadedIterableInterface<any> | null>;

        this._collectionSubscription = collection
            .pipe(untilDestroyed(this))
            .subscribe((current: LazyLoadedIterableInterface<any> | null): void => {
                this._collection = current;
                this._cdr.markForCheck();

                if (null !== current && current.hasMore) {
                    // recalculate scroll position again.
                    this.onScroll().then(/* noop */);
                }
            });
    }

    private setLoadingSubscription(): void {
        this._loading = null;
        this._cdr.markForCheck();

        if (this._loadingSubscription) {
            this._loadingSubscription.unsubscribe();
            this._loadingSubscription = null;
        }

        if (undefined === this.loading || null === this.loading) {
            return;
        }

        if ('boolean' === typeof this.loading) {
            this._loading = this.loading;
            this._cdr.markForCheck();

            if (false === this.loading) {
                // recalculate scroll position again.
                this.onScroll().then(/* noop */);
            }

            return;
        }

        let loading: Observable<boolean>;
        loading = 'string' === typeof this.loading ? this._store.select(this.loading) : this.loading;

        this._loadingSubscription = loading
            .pipe(untilDestroyed(this))
            .subscribe((current: boolean): void => {
                this._loading = current;
                this._cdr.markForCheck();

                if (false === current) {
                    // recalculate scroll position again.
                    this.onScroll().then(/* noop */);
                }
            });
    }

}
