import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChange,
    SimpleChanges,
    Type,
}                       from '@angular/core';
import {
    CursoredCollection,
    Equals,
    EventReference,
    Interest,
    InterestBookshelf,
    InterestPlaylist,
} from '@evermed/core';
import {
    BehaviorSubject,
    Observable,
    Subject,
}                       from 'rxjs';
import { CarouselData } from './carousel-data';

/**
 * This component should not be exposed for public usage, its purpose is
 * only to render carousels properly. Other components will utilise this
 * component for rendering.
 */
@Component({
    selector:        'app-interests-carousels-renderer',
    templateUrl:     './interests-carousels-renderer.component.html',
    styleUrls:       ['./interests-carousels-renderer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InterestsCarouselsRendererComponent implements OnChanges {

    /**
     * How many carousels to load per iteration.
     */
    private static readonly BATCH_SIZE = 4;

    @Input()
    public event: EventReference | null;

    @Input()
    public interests: Interest[];

    /**
     * Reference to a function which will execute a selection of
     * appropriate playlist/bookshelf.
     */
    @Input()
    public select: (interest: Interest, event: EventReference | null) => Observable<InterestPlaylist | InterestBookshelf>;

    /**
     * Reference to a function which will dispatch a loading of
     * appropriate playlist/bookshelf.
     */
    @Input()
    public dispatch: (interest: Interest, event: EventReference | null) => Promise<void>;

    /**
     * Reference to a function which will navigate to a page
     * which displays all content for given interest and event.
     */
    @Input()
    public navigate: (interest: Interest, event: EventReference) => void;

    /**
     * Reference to a component to dynamically render video/document card.
     */
    @Input()
    public component: Type<any>;

    public readonly loading$: Subject<boolean> = new BehaviorSubject(false);

    public readonly collection$: BehaviorSubject<CursoredCollection<CarouselData>> = new BehaviorSubject(CursoredCollection.empty<CarouselData>());

    private readonly _cdr: ChangeDetectorRef;

    public constructor(cdr: ChangeDetectorRef) {
        this._cdr = cdr;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // if event has changed, we should reset state.
        // Note that we need to on event change clear state,
        // but not to trigger loading. Interest change will
        // trigger loading.
        if (changes.event) {
            this.onEventInputChanged(changes.event);
        }

        // if interests changed, we should introspect what should be loaded
        // and what should remain as it is.
        if (changes.interests) {
            this.onInterestInputChanged(changes.interests);
        }
    }

    public async load(): Promise<void> {
        // there is nothing to load, so we will just break execution
        if (!this.interests || 0 === this.interests.length) {
            this.collection$.next(CursoredCollection.empty<CarouselData>());
            this._cdr.markForCheck();
            return;
        }

        let loaded: CursoredCollection<CarouselData> = this.collection$.value;
        let pending: Interest[]                      = this.interests.filter((interest: Interest): boolean => !loaded.has(interest.getIdentifier()));

        // all interest playlist has been loaded already.
        if (0 === pending.length) {
            this.collection$.next(loaded.clone({
                hasMore: false,
            }));
            this._cdr.markForCheck();
            return;
        }

        this.loading$.next(true);
        this._cdr.markForCheck();

        let batch: Interest[]         = pending.slice(0, InterestsCarouselsRendererComponent.BATCH_SIZE);
        let hasMore: boolean          = batch < pending;
        let promises: Promise<void>[] = [];

        batch.forEach((interest: Interest): void => {
            let observable: Observable<InterestPlaylist | InterestBookshelf> = this.select(interest, this.event);
            let promise: Promise<void>                                       = this.dispatch(interest, this.event);

            loaded = loaded.append(new CarouselData(interest, observable));
            promises.push(promise);
        });

        await Promise.all(promises);

        this.collection$.next(loaded.clone({
            hasMore: hasMore,
        }));
        this.loading$.next(false);
        this._cdr.markForCheck();
    }

    private onEventInputChanged(change: SimpleChange): void {
        let previous: string = change.previousValue?.getIdentifier();
        let current: string  = change.currentValue?.getIdentifier();

        if (previous === current) {
            return;
        }

        // clear everything
        this.loading$.next(false);
        this.collection$.next(CursoredCollection.empty());
        this._cdr.markForCheck();
    }

    private onInterestInputChanged(change: SimpleChange): void {
        let previous: Interest[] = change.previousValue || [];
        let current: Interest[]  = change.currentValue || [];
        let identifier           = (interest: Interest): string => interest.getIdentifier();
        let equals: boolean      = Equals.collections(previous.map(identifier), current.map(identifier));

        if (equals) {
            return;
        }

        this.loading$.next(false);
        this.collection$.next(CursoredCollection.empty());
        this._cdr.markForCheck();

        this.load().then(/*  noop */);
    }

}
