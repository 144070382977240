export interface CarouselConfigurationInterface {

    /**
     * Slide duration.
     */
    duration: number;

    /**
     * Easing animation.
     */
    easing: string;

    /**
     * Responsive breakpoints. If not provided, they will be determined
     * by "reference" property and default configuration.
     */
    breakpoints?: number | { [resolution: number]: number } | null;

    /**
     * When analysing which breakpoint should be applied, what
     * should be used as reference, window or container of carousel?
     */
    reference: 'window' | 'container';

    /**
     * Start from given index, zero based.
     */
    startIndex: number;

    /**
     * Enable draggable.
     */
    draggable: boolean;

    /**
     * Enable multiple draggable.
     */
    multipleDrag: boolean;

    /**
     * Move treshold distance in pixels.
     */
    threshold: number;

    /**
     * Should behave as carousel or slider? (Currently, only slider is implemented).
     */
    loop: boolean;

    /**
     * Render RTL direction.
     */
    rtl: boolean;

    /**
     * Function to invoke prior to carousel rendering.
     */
    beforeRender: () => Promise<void>;
}

const defaultContainerBreakpoints: { [size: number]: number } = {
    576:  1,
    768:  2,
    920:  3,
    1200: 4,
};

const defaultWindowBreakpoints: { [size: number]: number } = {
    576:  1,
    768:  2,
    920:  3,
    1200: 4,
};

export function createDefaultCarouselConfiguration(configuration: Partial<CarouselConfigurationInterface> = {}): CarouselConfigurationInterface {

    let normalized: CarouselConfigurationInterface = {
        ...{
            duration:     200,
            easing:       'ease-out',
            startIndex:   0,
            draggable:    true,
            reference:    'window',
            multipleDrag: true,
            threshold:    20,
            loop:         false,
            rtl:          false,
            beforeRender: (): Promise<void> => Promise.resolve(), // default, dummy function
        },
        ...(configuration || {}),
    };

    if (!normalized.breakpoints) {
        normalized.breakpoints = 'window' === normalized.reference ? defaultWindowBreakpoints : defaultContainerBreakpoints;
    }

    return normalized;
}
