import {
    IdentifiableInterface,
    Interest,
    InterestPlaylist,
    InterestBookshelf,
}                     from '@evermed/core';
import { Observable } from 'rxjs';

/**
 * Simple value object, containing an interest and observable
 * to interest playlist which needs to be rendered.
 */
export class CarouselData implements IdentifiableInterface {

    private readonly _interest: Interest;

    private readonly _subject: Observable<InterestPlaylist | InterestBookshelf>;

    public constructor(interest: Interest, subject: Observable<InterestPlaylist | InterestBookshelf>) {
        this._interest = interest;
        this._subject  = subject;
    }

    public getIdentifier(): string {
        return this._interest.getIdentifier();
    }

    public get interest(): Interest {
        return this._interest;
    }

    public get subject$(): Observable<InterestPlaylist | InterestBookshelf> {
        return this._subject;
    }

}
