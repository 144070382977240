export {
    GoogleTagManagerInterface,
    GOOGLE_TAG_MANAGER,
    GOOGLE_TAG_MANAGER_ENABLED,
    GOOGLE_TAG_MANAGER_ID,
    GOOGLE_TAG_MANAGER_AUTH,
    GOOGLE_TAG_MANAGER_PREVIEW,
}                               from './google-tag-manager.interface';
export { GoogleTagManager }     from './google-tag-manager';
export { NullGoogleTagManager } from './null-google-tag-manager';
