import { UntilDestroy, untilDestroyed }  from '@ngneat/until-destroy';
import {
    ChangeDetectorRef,
    Directive,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef,
}                                        from '@angular/core';
import { Select, Store }                 from '@ngxs/store';
import { User, UserSelector, UserState } from '@evermed/core';
import { Observable }                    from 'rxjs';

// evermedHideIfHasRole
@UntilDestroy()
@Directive({
    selector: '[evermedIsGranted]',
})
export class IsGrantedDirective implements OnInit, OnChanges {

    @Select(UserSelector.current)
    public user$: Observable<User>;

    @Input('evermedIsGranted')
    public attributes!: string | string[];

    private readonly _templateRef: TemplateRef<any>;

    private readonly _viewContainer: ViewContainerRef;

    private readonly _cdr: ChangeDetectorRef;

    private _user: User | null;

    private _visible: boolean = false;

    public constructor(
        templateRef: TemplateRef<any>,
        viewContainer: ViewContainerRef,
        cdr: ChangeDetectorRef,
        store: Store,
    ) {
        this._templateRef   = templateRef;
        this._viewContainer = viewContainer;
        this._cdr           = cdr;
        this._user          = store.selectSnapshot(UserState).current;
    }

    public ngOnInit(): void {
        this.user$.pipe(untilDestroyed(this)).subscribe((user: User | null): void => {
            this._user = user;
            this.handle();
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.attributes) {
            return;
        }

        this.handle();
    }

    private handle(): void {
        if (null === this._user) {
            this.show();
            return;
        }

        let attributes: string[] = Array.isArray(this.attributes) ? this.attributes : [this.attributes];

        for (let i: number = 0; i < attributes.length; i++) {
            if (-1 !== this._user.roles.indexOf(attributes[i])) {
                this.hide();
                return;
            }
        }

        this.show();
    }

    private hide(): void {
        if (!this._visible) {
            return;
        }

        this._visible = false;
        this._viewContainer.clear();
        this._cdr.markForCheck();
    }

    protected show(): void {
        if (this._visible) {
            return;
        }

        this._visible = true;
        this._viewContainer.createEmbeddedView(this._templateRef);
        this._cdr.markForCheck();
    }

}
