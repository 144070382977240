import {
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
}                                         from '@angular/core';
import { Router }                         from '@angular/router';
import {
    AbstractProduct,
    AuthSelector,
}                                         from '@evermed/core';
import {
    UntilDestroy,
    untilDestroyed,
}                                         from '@ngneat/until-destroy';
import { TranslateService }               from '@ngx-translate/core';
import { Store }                          from '@ngxs/store';
import { fromEvent }                      from 'rxjs';
import { RedeemAccessCodeModalComponent } from '../../components';
import {
    Toast,
    ModalController,
    ModalReference,
}                                         from '../../services';

@UntilDestroy()
@Directive({
    selector: '[evermedRedeemAccessCode]',
})
export class RedeemAccessCodeDirective implements OnInit {

    @Input('evermedRedeemAccessCode')
    public product: AbstractProduct = null;

    @Output()
    public readonly busy: EventEmitter<boolean> = new EventEmitter<boolean>();

    private readonly _elementRef: ElementRef;

    private readonly _modalController: ModalController;

    private readonly _store: Store;

    private readonly _toast: Toast;

    private readonly _router: Router;

    private readonly _translator: TranslateService;

    private _busy: boolean = false;

    public constructor(
        elementRef: ElementRef,
        modalController: ModalController,
        store: Store,
        toast: Toast,
        router: Router,
        translator: TranslateService,
    ) {
        this._elementRef      = elementRef;
        this._modalController = modalController;
        this._store           = store;
        this._toast           = toast;
        this._router          = router;
        this._translator      = translator;
    }

    public ngOnInit(): void {
        fromEvent(this._elementRef.nativeElement, 'click')
            .pipe(untilDestroyed(this))
            .subscribe(this.redeem.bind(this));

        this.busy.emit(false);
    }

    private async redeem(): Promise<void> {
        let authenticated: boolean = this._store.selectSnapshot<boolean>(AuthSelector.isAuthenticated);

        if (!authenticated) {
            await this._router.navigate(['/register']);
            await this._toast.warning('product.redeem.toast.unauthenticated', null, {
                title: 'product.redeem.toast.unauthenticated',
            });
            return;
        }

        if (this._busy) {
            return;
        }

        this._busy = true;
        this.busy.emit(true);

        let key: string                                               = `product.redeem.modal.${this.product ? 'redeemProduct' : 'redeemAccessCode'}.title`;
        let title: string                                             = await this._translator.get(key, {
            product: this.product ? this.product.name : null,
        }).toPromise();
        let reference: ModalReference<RedeemAccessCodeModalComponent> = await this._modalController.create(RedeemAccessCodeModalComponent, {
            title:    title,
            size:     'lg',
            cssClass: this.product ? 'redeem-product-modal' : 'redeem-access-code-modal',
            inputs:   {
                product: this.product,
            },
        });

        await reference.dismissed$.toPromise();

        this._busy = false;
        this.busy.emit(false);
    }

}
