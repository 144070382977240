/* eslint-disable prefer-destructuring */
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
}                                 from '@angular/core';
import {
    AuthMetadataInterface,
    Cookie,
    Header,
    NotAccessible,
    QueryString,
    Unauthorized,
}                                 from '@evermed-sdk/core';
import { HttpClient }             from '@runopencode/http';
import { Router }                 from '@runopencode/router';
import { FileReferenceInterface } from '@evermed/core';
import { Toast }                  from '../../../services';

@Component({
    selector:        'app-download',
    templateUrl:     './download.component.html',
    styleUrls:       ['./download.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadComponent {

    @Input()
    public file: FileReferenceInterface;

    @Input()
    public prefix: boolean = true;

    @Input()
    public size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';

    @Input()
    public set label(label: string) {
        this._label = label;
    }

    public get label(): string {
        if (null === this._label) {
            return this.file.type || '';
        }

        return this._label;
    }

    public disabled: boolean = false;

    public busy: boolean = false;

    public ready: boolean = false;

    public readonly http: HttpClient;

    public readonly router: Router;

    @ViewChild('link')
    private _link: ElementRef;

    @ViewChild('analytics')
    private _analytics: ElementRef;

    private _label: string = null;

    private readonly _toast: Toast;

    private readonly _cdr: ChangeDetectorRef;

    public constructor(
        toast: Toast,
        cdr: ChangeDetectorRef,
        http: HttpClient,
        router: Router,
    ) {
        this._toast = toast;
        this._cdr   = cdr;
        this.http   = http;
        this.router = router;
    }

    public get type(): string | null {
        return this.file.type || null;
    }

    public get mime(): string | null {
        return this.file.file.mime;
    }

    public async download(event: Event): Promise<void> {
        if (this.ready) {
            this.ready = false;
            return;
        }

        event.preventDefault();

        if (this.disabled || this.busy) {
            return;
        }

        this.busy = true;
        try {
            let metadata: AuthMetadataInterface = await this.file.file.metadata;

            if (metadata instanceof Unauthorized) {
                this._toast.error(
                    'file.noPrivileges.toast.error.message',
                    null,
                    {
                        title: 'file.noPrivileges.toast.error.title',
                    },
                ).then(/* noop */);
                return;
            }

            if (
                metadata instanceof NotAccessible
                ||
                metadata instanceof Header // not implemented
                ||
                metadata instanceof Cookie // not implemented
            ) {
                this._toast.error(
                    'file.noAccess.toast.error.message',
                    null,
                    {
                        title: 'file.noAccess.toast.error.title',
                    },
                ).then(/* noop */);
                return;
            }

            let url: string                = metadata.url;
            let element: HTMLAnchorElement = this._link.nativeElement;

            if (metadata instanceof QueryString) {
                url += `?${metadata.query}`;
            }

            element.setAttribute('href', url);
            // Download is occurring, enable analytics to register download.
            this.ready                             = true;
            this._analytics.nativeElement.disabled = false;
            element.click();
            this._analytics.nativeElement.disabled = true;
        } catch (e) {
            this._toast.error(
                'file.notAvailable.toast.error.message',
                null,
                {
                    title: 'file.notAvailable.toast.error.title',
                },
            ).then(/* noop */);
        } finally {
            this.busy = false;
            this._cdr.markForCheck();
        }
    }

}

