import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Type,
    ViewChild,
}                           from '@angular/core';
import {
    NavigationStart,
    Router,
} from '@angular/router';
import { timeout }          from '@evermed/core';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';
import { DynamicComponent } from 'ng-dynamic-component';

@UntilDestroy()
@Component({
    selector:        'app-modal',
    templateUrl:     './modal.component.html',
    styleUrls:       ['./modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent<T> implements OnInit, AfterViewInit {

    @Input()
    public title: string | null = null;

    @Input()
    public dismissable: boolean = true;

    @Input()
    public componentType: Type<T>;

    @Input()
    public inputs: { [name: string]: any } = {};

    @Input()
    public cssClass: string = '';

    @Input()
    public size: 'sm' | 'lg' | 'xl';

    @Output()
    public readonly displayed: EventEmitter<T> = new EventEmitter<T>();

    @Output()
    public readonly dismissed: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('modalRef')
    private readonly modal: ElementRef;

    @ViewChild(DynamicComponent)
    private readonly componentRef: DynamicComponent;

    private readonly _router: Router;

    public constructor(router: Router) {
        this._router = router;
    }

    public ngOnInit(): void {
        this._router.events.pipe(untilDestroyed(this)).subscribe((event: NavigationStart): void => {
            // on back-forward button, a popup will remain opened.
            // we will detect that and close it in order to prevent
            // stacking of multiple popups.
            if ('popstate' === event.navigationTrigger) {
                this.dismiss().then(/* noop */);
            }
        });
    }

    public ngAfterViewInit(): void {
        this.modal.nativeElement.classList.add('show');
        this.displayed.emit(this.componentRef.componentRef.instance);
        this.displayed.complete();
    }

    public async dismiss(): Promise<void> {
        this.modal.nativeElement.classList.remove('show');

        // TODO wait on animation end?
        await timeout(300);

        this.dismissed.emit();
        this.dismissed.complete();
    }

}
