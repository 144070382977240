import { Type }                       from '@angular/core';
import { ApplyForWaitlistDirective }  from './apply-for-waitlist/apply-for-waitlist.directive';
import { BackgroundImageDirective }   from './background-image/background-image.directive';
import { ClickOutsideDirective }      from './click-outside/click-outside.directive';
import { ContinuityLoggerDirective }  from './continuity-logger/continuity-logger.directive';
import { DownloadFileDirective }      from './download-file/download-file.directive';
import { EqualizeDirective }          from './equalize/equalize.directive';
import { FormControlErrorsDirective } from './form-errors/form-control-errors.directive';
import { FormSubmitDirective }        from './form-errors/form-submit-directive.directive';
import { RedeemAccessCodeDirective }  from './redeem-access-code/redeem-access-code.directive';
import {
    HiddenIfDirective,
    VisibleIfDirective,
}                                     from './responsive';
import { IsGrantedDirective }         from './roles';

export { FormControlErrorsDirective } from './form-errors/form-control-errors.directive';
export { FormSubmitDirective }        from './form-errors/form-submit-directive.directive';
export { ApplyForWaitlistDirective }  from './apply-for-waitlist/apply-for-waitlist.directive';
export { BackgroundImageDirective }   from './background-image/background-image.directive';
export { RedeemAccessCodeDirective }  from './redeem-access-code/redeem-access-code.directive';
export { DownloadFileDirective }      from './download-file/download-file.directive';
export { ContinuityLoggerDirective }  from './continuity-logger/continuity-logger.directive';
export { EqualizeDirective }          from './equalize/equalize.directive';

export const EVERMED_SHARED_DIRECTIVES: Type<any>[] = [
    FormControlErrorsDirective,
    FormSubmitDirective,
    ApplyForWaitlistDirective,
    BackgroundImageDirective,
    RedeemAccessCodeDirective,
    DownloadFileDirective,
    ContinuityLoggerDirective,
    EqualizeDirective,
    VisibleIfDirective,
    HiddenIfDirective,
    ClickOutsideDirective,
    IsGrantedDirective,
];
