import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
} from '@angular/core';

@Component({
    selector:        'app-form-control-error',
    templateUrl:     './form-control-error.component.html',
    styleUrls:       ['./form-control-error.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormControlErrorComponent implements OnChanges {

    @Input()
    public error: string | null = null;

    @Input()
    public hidden: boolean = true;

    private readonly _cdr: ChangeDetectorRef;

    public constructor(cdr: ChangeDetectorRef) {
        this._cdr = cdr;
    }

    /**
     * For some reason, Angular is too late to render error message,
     * therefore, we will take control in regards to change detection.
     *
     * This will be the public method that will be invoked by directive
     * to fix issue with late rendering.
     */
    public ngOnChanges(): void {
        this._cdr.markForCheck();
    }

}
