import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
}                                 from '@angular/core';
import { FileReferenceInterface } from '@evermed/core';

@Component({
    selector:        'app-document-viewer-modal',
    templateUrl:     './document-viewer-modal.component.html',
    styleUrls:       ['./document-viewer-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentViewerModalComponent implements OnChanges {

    @Input()
    public dismissRef: () => void;

    @Input()
    public reference: FileReferenceInterface = null;

    private readonly _cdr: ChangeDetectorRef;

    public constructor(cdr: ChangeDetectorRef) {
        this._cdr = cdr;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.reference) {
            return;
        }

        this._cdr.markForCheck();
    }

}
