import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    Input,
    OnInit,
}                     from '@angular/core';
import {
    AuthSelector,
    VideoOnDemandInterface,
}                     from '@evermed/core';
import {
    UntilDestroy,
    untilDestroyed,
}                     from '@ngneat/until-destroy';
import { Select }     from '@ngxs/store';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
    selector:        'app-video-preview-watermark',
    templateUrl:     './video-preview-watermark.component.html',
    styleUrls:       ['./video-preview-watermark.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPreviewWatermarkComponent implements OnInit {

    @Input()
    public video: VideoOnDemandInterface;

    public get anonymous(): boolean {
        return !!this.video && !this._authenticated;
    }

    public get waitlistApplicable(): boolean {
        return !this.anonymous && !this.video.waitlist;
    }

    public get alreadyOnWaitlist(): boolean {
        return !this.anonymous && this.video.waitlist;
    }

    private _authenticated: boolean = false;

    @Select(AuthSelector.isAuthenticated)
    private readonly authenticated$: Observable<boolean>;

    private readonly _cdr: ChangeDetectorRef;

    public constructor(cdr: ChangeDetectorRef) {
        this._cdr = cdr;
    }

    public ngOnInit(): void {
        this.authenticated$.pipe(untilDestroyed(this)).subscribe((authenticated: boolean): void => {
            this._authenticated = authenticated;
            this._cdr.markForCheck();
        });
    }

    @HostListener('click', ['$event'])
    public onClick(event: Event): void {
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.preventDefault();
    }
}
