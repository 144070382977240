import { Router }    from '@angular/router';
import {
    Actions,
    ofActionDispatched,
}                    from '@ngxs/store';
import { LoggedOut } from '@evermed/core';

export function logoutInitializer(actions: Actions, router: Router, document: Document): () => Promise<void> {
    return (): Promise<void> => {
        actions.pipe(ofActionDispatched(LoggedOut)).subscribe((action: LoggedOut): void => {
            if (null !== action.redirect) {
                document.location.href = action.redirect;
                return;
            }

            if (action.silent) {
                return;
            }

            router.navigateByUrl('/').then(/* noop */);
        });

        return Promise.resolve();
    };
}
