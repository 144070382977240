import {
    Directive,
    ElementRef,
    Input,
}                                  from '@angular/core';
import {
    Checkpoint,
    IdentifiableInterface,
}                                  from '@evermed/core';
import {
    UntilDestroy,
    untilDestroyed,
}                                  from '@ngneat/until-destroy';
import { Store }                   from '@ngxs/store';
import { fromEvent }               from 'rxjs';
import { EvermedVideoPlayer } from '@evermed/components-angular';
import { PdfViewerComponent } from '../../components';

@UntilDestroy()
@Directive({
    selector: '[evermedContinuityLogger]',
})
export class ContinuityLoggerDirective {

    @Input('evermedContinuityLogger')
    public subject?: string | IdentifiableInterface;

    private readonly _store: Store;

    private _element: HTMLMediaElement | EvermedVideoPlayer | PdfViewerComponent;

    public constructor(
        store: Store,
        elementRef: ElementRef,
    ) {
        this._store   = store;
        this._element = elementRef.nativeElement;
        fromEvent(this._element, 'pause').pipe(untilDestroyed(this)).subscribe(this.log.bind(this));
        fromEvent(this._element, 'ended').pipe(untilDestroyed(this)).subscribe(this.log.bind(this));
    }

    private async log(): Promise<void> {
        let checkpoint: number = undefined !== this._element.currentTime ? this._element.currentTime : this._element.page;

        if (!checkpoint) {
            return;
        }

        await this._store.dispatch(new Checkpoint(this.subject, checkpoint)).toPromise();
    }

}
