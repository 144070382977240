import {
    APP_INITIALIZER,
    Provider,
}                            from '@angular/core';
import { Router }            from '@angular/router';
import { Actions }           from '@ngxs/store';
import { DOCUMENT }          from '@angular/common';
import { logoutInitializer } from './logout-initializer';

export { logoutInitializer } from './logout-initializer';

export const APPLICATION_INITIALIZERS: Provider[] = [
    {
        provide:    APP_INITIALIZER,
        useFactory: logoutInitializer,
        multi:      true,
        deps:       [Actions, Router, DOCUMENT],
    },
];
