import { ComponentRef }     from '@angular/core';
import {
    AuthSelector,
    ConsentableInterface,
    ConsentType,
    RequiredConsent,
    User,
    UserSelector,
    VerifyConsentable,
}                           from '@evermed/core';
import { TranslateService } from '@ngx-translate/core';
import {
    Actions,
    ofActionDispatched,
    Store,
}                       from '@ngxs/store';
import { camelize }     from '../../../functions';
import {
    ModalController,
    ModalReference,
    UserConsentModalComponent,
}                       from '../../shared';
import { AppComponent } from '../app.component';

/**
 * This is a queue of required consents which needs to be processed.
 */
let queue: Map<RequiredConsent, string> = new Map<RequiredConsent, string>();

/**
 * This is a flag denoting if queue is in processing state.
 */
let processing: boolean = false;

/**
 * This is extracted function from major logic below. It will just extract
 * all required consents from store for given subject.
 */
function fetchRequiredConsents(store: Store, subject: ConsentableInterface): RequiredConsent[] {
    let requiredConsents: RequiredConsent[] = [];
    let userConsents: ConsentType[]         = [ConsentType.SSO_REGISTRATION_POLICY, ConsentType.PROJECT_TERMS];

    // Required consents may be fetched by subject and type
    subject
        .getSupportedConsentTypes()
        .filter((type: ConsentType): boolean => !userConsents.includes(type))
        .forEach((type: ConsentType): void => {
            let requiredConsent: RequiredConsent | null = store.selectSnapshot(UserSelector.requiredConsent(subject, type));
            if (null === requiredConsent) {
                return;
            }

            requiredConsents.push(requiredConsent);
        });

    if (subject instanceof User) {
        // Exception is SSO_REGISTRATION_POLICY when User is subject,
        // where server will send SSO id which client app does not have,
        // so we will fetch those by type only.
        store
            .selectSnapshot(UserSelector.requiredConsentsByType(...userConsents))
            .forEach((requiredConsent: RequiredConsent): void => {
                requiredConsents.push(requiredConsent);
            });
    }

    return requiredConsents;
}

/**
 * This is extracted, recursive function which will process a queue
 * of required consents, one by one.
 */
async function processConsents(queue: Map<RequiredConsent, string>, modalController: ModalController): Promise<void> {
    if (0 === queue.size) {
        return;
    }

    // take first consent and title for modal
    let consent: RequiredConsent = queue.keys().next().value;
    let title: string            = queue.values().next().value;

    // remove it from the queue
    queue.delete(consent);

    let reference: ModalReference<UserConsentModalComponent> = await modalController
        .create<UserConsentModalComponent>(UserConsentModalComponent, {
            title:       title,
            inputs:      {
                consent: consent,
            },
            dismissable: false,
            cssClass:    '',
            size:        'lg',
        });

    await reference.dismissed$.toPromise();

    return processConsents(queue, modalController);
}

export function bootstrapConsentCollection(
    actions: Actions,
    store: Store,
    modalController: ModalController,
    translator: TranslateService,
): (ref: ComponentRef<any>) => void {

    return (ref: ComponentRef<any>): void => {

        if (!(ref.instance instanceof AppComponent)) {
            return;
        }

        actions.pipe(ofActionDispatched(VerifyConsentable)).subscribe(async (action: VerifyConsentable): Promise<void> => {
            let authenticated: boolean = store.selectSnapshot(AuthSelector.isAuthenticated);

            // for now, anonymous users do not get any custom consent screen.
            if (!authenticated) {
                return;
            }

            let requiredConsents: RequiredConsent[] = fetchRequiredConsents(store, action.subject);

            for (let i: number = 0; i < requiredConsents.length; i++) {
                let requiredConsent: RequiredConsent = requiredConsents[i];
                let title: string                    = await translator.get(`userConsent.title.${camelize(requiredConsent.type.toString())}`, {
                    label: action.subject.label,
                }).toPromise();

                queue.set(requiredConsent, title);
            }

            // there is already active processing, so there is no need to trigger it.
            if (processing) {
                return;
            }

            // we are triggering recursive processing
            processing = true;
            await processConsents(queue, modalController);
            processing = false;
        });
    };
}
