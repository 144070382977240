import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    Output,
    Type,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
    GOOGLE_RE_CAPTCHA,
    GoogleReCaptchaInterface,
} from '@evermed/core';

@Component({
    templateUrl: './re-captcha.component.html',
    selector:    'app-recaptcha',
    styleUrls:   ['./re-captcha.component.scss'],
    providers:   [{
        // eslint-disable-next-line no-use-before-define
        useExisting: forwardRef((): Type<ReCaptchaComponent> => ReCaptchaComponent),
        provide:     NG_VALUE_ACCESSOR,
        multi:       true,
    }],
})
export class ReCaptchaComponent implements ControlValueAccessor {

    @Input()
    public set disabled(value: boolean) {
        this._disabled = value;
    }

    public get disabled(): boolean {
        return this._disabled || !this._recaptcha.enabled;
    }

    @Input()
    public set value(value: string | null) {
        if (this.disabled) {
            return;
        }

        if (this._value === value) {
            return;
        }

        this._value = value;
        this._onChangeHandler(value);
        this._onTouchedHandler();
        this.change.emit(value);
    }

    public get value(): string | null {
        return this._value || null;
    }

    @Output()
    public readonly change: EventEmitter<string | null> = new EventEmitter<string | null>();

    private _value: string | null;

    private _disabled: boolean = false;

    private _onChangeHandler: (_: any) => void = (_: any): void => {};

    private _onTouchedHandler: () => void = (): void => {};

    private readonly _recaptcha: GoogleReCaptchaInterface;

    public constructor(@Inject(GOOGLE_RE_CAPTCHA) recaptcha: GoogleReCaptchaInterface) {
        this._recaptcha = recaptcha;
    }

    public writeValue(value: any): void {
        this._value = value;
    }

    public registerOnChange(fn: any): void {
        this._onChangeHandler = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouchedHandler = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this._disabled = isDisabled;
    }

}
