import {
    animate,
    query,
    stagger,
    style,
    transition,
    trigger,
    AnimationTriggerMetadata,
} from '@angular/animations';

export const animations: AnimationTriggerMetadata[] = [
    trigger('fadeIn', [
        transition(':enter', [
            query('*', style({
                opacity: 0,
            })),
            query('*', stagger('100ms', [
                animate('.5s ease-in', style({
                    opacity: 1,
                })),
            ])),
        ]),
    ]),
    trigger('fadeOut', [
        transition(':leave', [
            query('*', style({
                opacity:   1,
            })),
            query('*', stagger('100ms', [
                animate('.5s ease-out', style({
                    opacity:    0,
                })),
            ])),
        ]),
    ]),
];
