import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild,
}                from '@angular/core';
import {
    RequiredConsent,
    ConsentProvided,
}                from '@evermed/core';
import { Store } from '@ngxs/store';
import { Toast } from '../../../services';

@Component({
    selector:        'app-user-consent-modal',
    templateUrl:     './user-consent-modal.component.html',
    styleUrls:       ['./user-consent-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserConsentModalComponent implements AfterViewInit {

    @Input()
    public dismissRef: () => void;

    @Input()
    public consent: RequiredConsent;

    public get accepted(): boolean {
        return this._accepted && this._read;
    }

    public get read(): boolean {
        return this._read;
    }

    public get busy(): boolean {
        return this._busy;
    }

    private _accepted: boolean = false;

    private _read: boolean = false;

    private _busy: boolean = false;

    private readonly _store: Store;

    private readonly _toast: Toast;

    private readonly _cdr: ChangeDetectorRef;

    @ViewChild('acceptedCheckbox')
    private readonly _acceptCheckbox: ElementRef;

    @ViewChild('termsTextarea')
    private readonly _termsTextarea: ElementRef;

    public constructor(
        store: Store,
        toast: Toast,
        cdr: ChangeDetectorRef,
    ) {
        this._store = store;
        this._toast = toast;
        this._cdr   = cdr;
    }

    public ngAfterViewInit(): void {
        // Fixing the glitch: we have to wait some time after view has been
        // rendered and trigger re-calculation of scrolling position in
        // case when text is of less height then textarea is.
        setTimeout((): void => {
            this.reading();
        }, 1000);
    }

    public accept(): void {
        this._accepted = (this._acceptCheckbox.nativeElement as HTMLInputElement).checked;
    }

    public reading(): void {
        if (this._read) {
            return; // there is no need for additional calculations.
        }

        let textarea: HTMLTextAreaElement = this._termsTextarea.nativeElement as HTMLTextAreaElement;
        let height: number                = textarea.offsetHeight + 20; // decrease height just a little as a safety margin.

        if (textarea.scrollHeight <= (textarea.scrollTop + height)) {
            this._read = true;
            this._cdr.markForCheck();
        }
    }

    public async submit(): Promise<void> {
        this._busy = true;
        this._cdr.markForCheck();

        try {
            await this._store.dispatch(new ConsentProvided(this.consent)).toPromise();
        } catch (e) {
            this._toast.error('userConsent.toast.error').then(/* noop */);
        } finally {
            this.dismissRef();
            this._busy = false;
            this._cdr.markForCheck();
        }
    }

}
