import {
    ChangeDetectionStrategy,
    Component,
    Input,
}                   from '@angular/core';
import { Router }   from '@angular/router';
import { Interest } from '@evermed/core';

@Component({
    selector:        'app-interests-labels',
    templateUrl:     './interests-labels.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InterestsLabelsComponent {

    @Input()
    public interests: Interest[] = null;

    @Input()
    public link: string | string[] = null;

    private readonly _router: Router;

    public constructor(router: Router) {
        this._router = router;
    }

    public command(interest: Interest): string[] {
        let command: string[] = Array.isArray(this.link) ? this.link : [this.link];
        command               = command.map((piece: string): string => piece.replace(':interest', interest.id));

        return command;
    }

}
