import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
}                                  from '@angular/core';
import {
    AuthMetadataInterface,
    AuthMetadataPayloadInterface,
}                                  from '@evermed-sdk/core';
import { HTMLEvermedImageElement } from '@evermed/components';

@Directive({
    selector: '[evermedBackgroundImage]',
})
export class BackgroundImageDirective implements OnChanges {

    @Input('evermedBackgroundImage')
    public evermedBackgroundImage: object = null;

    private readonly _element: HTMLElement;

    public constructor(
        elementRef: ElementRef,
    ) {
        this._element = elementRef.nativeElement;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.evermedBackgroundImage) {
            return;
        }

        this.onSourceChanged(changes.evermedBackgroundImage.currentValue).then(/* noop */);
    }

    private async onSourceChanged(source: AuthMetadataInterface | AuthMetadataPayloadInterface): Promise<void> {
        // eslint-disable-next-line angular/document-service
        let image: HTMLEvermedImageElement = document.createElement('evermed-image');
        image.style.visibility             = 'hidden';
        image.style.height                 = '0px';
        image.style.width                  = '0px';
        image.style.overflow               = 'hidden';
        image.style.position               = 'absolute';

        this._element.appendChild(image);

        image.src                              = source;
        let data: string                       = await image.ready();
        this._element.style.backgroundImage    = `url(${data})`;
        this._element.style.backgroundSize     = 'cover';
        this._element.style.backgroundPosition = 'center center';

        this._element.removeChild(image);
    }

}
