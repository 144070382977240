import { Type }                    from '@angular/core';
import { CookiesConsentComponent } from './cookies-consent/cookies-consent.component';
import { FooterComponent }         from './footer/footer.component';
import { MenuSearchBarComponent }  from './menu-search-bar/menu-search-bar.component';
import { MenuComponent }           from './menu/menu.component';
import { LocalePickerComponent }   from './locale-picker/locale-picker.component';

export const EVERMED_APPLICATION_COMPONENTS: Type<any>[] = [
    FooterComponent,
    MenuComponent,
    MenuSearchBarComponent,
    CookiesConsentComponent,
    LocalePickerComponent,
];
