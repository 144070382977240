import { Component } from '@angular/core';

/* eslint-disable no-multi-spaces */
@Component({
    selector:    'app-static-error-404-page',
    styleUrls:   ['./error-404.component.scss'],
    templateUrl: './error-404.component.html',
})
export class Error404PageComponent {
}
