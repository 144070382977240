import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
}                       from '@angular/core';
import {
    AuthMetadataInterface,
    AuthMetadataPayloadInterface,
    RoundRobinQueue,
}                       from '@evermed-sdk/core';
import {
    LiveStreamUrlInterface,
    timeout,
}                       from '@evermed/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * This component wraps around evermed-video-player and tries
 * to recover stream playback. It will try again to use same URL
 * over and over. If redundant URL is provided, after 2 failed attempt
 * it will try different URL.
 *
 * Problem with this algorithm is that there is no "STOP" logic implemented,
 * which should be revised later (if necessary).
 */
@Component({
    selector:        'app-live-stream-player',
    templateUrl:     './live-stream-player.component.html',
    styleUrls:       ['./live-stream-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiveStreamPlayerComponent implements OnChanges {

    @Input()
    public stream: LiveStreamUrlInterface[];

    @Input()
    public thumbnail: AuthMetadataInterface | AuthMetadataPayloadInterface | string | null;

    @Input()
    public language: string = 'en';

    public current: LiveStreamUrlInterface = null;

    private _source: RoundRobinQueue<LiveStreamUrlInterface>;

    private readonly _cdr: ChangeDetectorRef;

    private _recovering: boolean = false;

    public readonly _sanitizer: DomSanitizer;

    public constructor(cdr: ChangeDetectorRef, sanitizer: DomSanitizer) {
        this._sanitizer = sanitizer;
        this._cdr = cdr;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.stream && !changes.language) {
            return;
        }

        // if (!changes.stream.currentValue) {
        //     this.current = null;
        //     this._cdr.markForCheck();
        //     return;
        // }

        // eslint-disable-next-line max-len
        let streams: LiveStreamUrlInterface[] = this.stream.filter((stream: LiveStreamUrlInterface) => stream.language === this.language);

        // TODO: first stream even if language is changed
        if (0 === streams.length) {
            this.current = null;
            this._cdr.markForCheck();
            return;
        }

        let recovery: LiveStreamUrlInterface[] = [];

        streams.forEach((stream: LiveStreamUrlInterface): void => {
            for (let i: number = 0; i < 2; i++) {
                recovery.push(stream);
            }
        });

        this._source = new RoundRobinQueue<LiveStreamUrlInterface>(recovery);
        this.current = this._source.next();
        this._cdr.markForCheck();
    }

    public async onError(): Promise<void> {
        if (this._recovering) {
            return;
        }

        this._recovering = true;

        // Nullify previous playback.
        await timeout(1500);
        this.current = {
            mime:     null,
            type:     null,
            url:      null,
            language: this.current.language,
        };
        this._cdr.markForCheck();

        // After nullification, try to reconnect
        await timeout(500);
        this.current = this._source.next();
        this._cdr.markForCheck();

        this._recovering = false;
    }

}
