import {
    Component,
    Input,
} from '@angular/core';

@Component({
    selector:    'app-button-submit',
    templateUrl: './button-submit.component.html',
    styleUrls:   ['./button-submit.component.scss'],
})
export class ButtonSubmitComponent {

    @Input()
    public busy: boolean = false;

    @Input()
    public disabled: boolean = false;

}
