import {
    Component,
    AfterViewInit,
    ChangeDetectionStrategy,
    ElementRef,
    ViewChild,
    ViewEncapsulation,
}                           from '@angular/core';
import { EvermedEqualizer } from '@evermed/components-angular';

@Component({
    selector:        'app-button-group',
    templateUrl:     './button-group.component.html',
    styleUrls:       ['./button-group.component.scss'],
    encapsulation:   ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonGroupComponent implements AfterViewInit {

    private readonly _element: HTMLElement;

    @ViewChild('equalizer')
    private readonly _equalizer: EvermedEqualizer;

    public constructor(elementRef: ElementRef) {
        this._element = elementRef.nativeElement;
    }

    public ngAfterViewInit(): void {
        let elements: HTMLElement[] = Array.from(this._element.querySelectorAll('.btn, button'));

        elements.forEach((element: HTMLElement): void => {
            element.setAttribute('data-equalize', 'app-button-group');
        });

        this._equalizer.refresh();
    }

}
