/* eslint-disable max-len */
import {
    BehaviorSubject,
    fromEvent,
    Observable,
    Subject,
    Unsubscribable,
}                     from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
}                     from 'rxjs/operators';
import { TouchEvent } from './touch-event';
import {
    CarouselConfigurationInterface,
    createDefaultCarouselConfiguration,
}                     from './carousel-configuration.interface';
import {
    createDefaultInteractionState,
    InteractionStateInterface,
}                     from './interaction-state.interface';

export class CarouselEngine {

    /**
     * Subscribe to initialization event.
     */
    public get init$(): Observable<boolean> {
        return this._init$.asObservable();
    }

    /**
     * Subscribe to change of number of slides per page.
     */
    public get perPage$(): Observable<number> {
        return this._perPage$.pipe(distinctUntilChanged());
    }

    /**
     * Subscribe to change of draggability of slides.
     */
    public get draggable$(): Observable<boolean> {
        return this._draggable$.pipe(distinctUntilChanged());
    }

    /**
     * Subscribe to carousel rendering complete event.
     */
    public get rendered$(): Observable<void> {
        return this._rendered$.asObservable();
    }

    /**
     * Subscribe to slide index change event.
     */
    public get slide$(): Observable<number> {
        return this._slide$.pipe(distinctUntilChanged());
    }

    /**
     * Subscribe to carousel slideability change event.
     */
    public get slideable$(): Observable<boolean> {
        return this._slideable$.pipe(distinctUntilChanged());
    }

    /**
     * Subscribe to carousel slideability to next change event.
     */
    public get slideableNext$(): Observable<boolean> {
        return this._slideableNext$.pipe(distinctUntilChanged());
    }

    /**
     * Subscribe to carousel slideability to previous change event.
     */
    public get slideablePrevious$(): Observable<boolean> {
        return this._slideablePrevious$.pipe(distinctUntilChanged());
    }

    /**
     * Element wrapping all sliders.
     */
    private readonly _stage: HTMLElement;

    /**
     * Direct sibling to a stage, sliding element.
     */
    private readonly _slider: HTMLElement;

    /**
     * A configuration.
     */
    private readonly _configuration: CarouselConfigurationInterface;

    /**
     * Slides to slide within slider, its direct siblings.
     */
    private _slides: HTMLElement[] = [];

    /**
     * Which transform property to use for sliding animation.
     */
    private readonly _transformProperty: string;

    /**
     * Reference to a document.
     */
    private _document: Document;

    /**
     * Placeholder for current interaction state.
     */
    private _interactionState: InteractionStateInterface;

    /**
     * Index of current slide, zero based.
     */
    private _currentSlide: number = null;

    /**
     * Number of displayed item per page.
     */
    private _perPage: number = null;

    /**
     * All event handlers are managed via observables.
     */
    private _eventSubscriptions: Unsubscribable[] = [];

    /**
     * Notify when carousel has been initialized.
     */
    private readonly _init$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /**
     * Notify when carousel number of items per page has been changed.
     */
    private readonly _perPage$: BehaviorSubject<number> = new BehaviorSubject<number>(1);

    /**
     * Notify when carousel supports dragging.
     */
    private readonly _draggable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /**
     * Notify when carousel is rendered.
     */
    private readonly _rendered$: Subject<void> = new Subject<void>();

    /**
     * Notify when slide number changes.
     */
    private readonly _slide$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    /**
     * Notify when slideability of carousel changes.
     */
    private readonly _slideable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /**
     * Notify when slideability to next slide of carousel changes.
     */
    private readonly _slideableNext$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /**
     * Notify when slideability to previous slide of carousel changes.
     */
    private readonly _slideablePrevious$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /**
     * It is possible to invoke equalize method several times during
     * preloading of images. In order not to execute it several times
     * we will try to optimize number of execution through simple
     * execution counter.
     */
    private _queue: number = 0;

    public constructor(
        stage: HTMLElement,
        document: Document,
        configuration?: Partial<CarouselConfigurationInterface>,
    ) {
        this._configuration = createDefaultCarouselConfiguration(configuration);

        if (this._configuration.loop) {
            throw new Error('Carousel behaviour is not currently supported, only as slider may be used.');
        }

        this._stage                 = stage;
        this._slider                = this._stage.firstElementChild as HTMLElement; // direct sibling of stage.
        this._slides                = Array.from(this._slider.childNodes).filter((node: ChildNode) => node instanceof HTMLElement) as HTMLElement[]; // direct siblings of slider.
        this._document              = document;
        this._transformProperty     = 'string' === typeof this._document.documentElement.style.transform ? 'transform' : 'WebkitTransform';
        this._stage.style.overflow  = 'hidden';
        this._stage.style.direction = this._configuration.rtl ? 'rtl' : 'ltr';
        this._interactionState      = createDefaultInteractionState();

        // we need to resolve these values at the very initialization.
        this._perPage      = this.resolveNumberOfSlidesPerPage();
        this._currentSlide = this._configuration.loop ? this._configuration.startIndex % this._slides.length : Math.max(0, Math.min(this._configuration.startIndex, this._slides.length - this._perPage));

        // and notify observer about it.
        this._perPage$.next(this._perPage);
        this._slide$.next(this._currentSlide);

        this.rebuild().then((): void => {
            this._init$.next(true);
            this._init$.complete();
        });
    }

    public async rebuild(): Promise<void> {
        // there is ongoing execution, so we will just return.
        if (++this._queue > 1) {
            return;
        }

        await this._configuration.beforeRender();

        let previousPerPage: number = this._perPage;
        this._slides                = Array.from(this._slider.childNodes).filter((node: ChildNode) => node instanceof HTMLElement) as HTMLElement[]; // direct siblings of slider.
        this._perPage               = this.resolveNumberOfSlidesPerPage();
        let stageWidth: number      = this._stage.offsetWidth;
        let itemWidth: number       = stageWidth / this._perPage;

        // this logic will be applicable when carousel is supported,
        // at this moment, we do not build additional items, since we
        // do not need a carousel, only slider
        let itemsToBuild: number = this._configuration.loop ? this._slides.length + (2 * this._perPage) : this._slides.length;

        let slideable: boolean = itemsToBuild > this._perPage;
        let draggable: boolean = slideable && this._configuration.draggable;

        // we need to notify if on rebuild it is possible to slide slider at all.
        this._slideable$.next(slideable);
        this._draggable$.next(draggable);

        this.disableTransition();
        this._slider.style.width = `${itemWidth * itemsToBuild}px`;
        this.enableTransition();

        this._slides.forEach((slide: HTMLElement): void => {
            slide.style.cssFloat = this._configuration.rtl ? 'right' : 'left';
            slide.style.float    = this._configuration.rtl ? 'right' : 'left';
            slide.style.width    = `${this._configuration.loop ? 100 / (this._slides.length + (this._perPage * 2)) : 100 / (this._slides.length)}%`;
        });

        // if resolution has been changed, we need to reset carousel.
        // otherwise, we have to re-calculate slide number.
        let slideNumber: number = 0;
        if (previousPerPage === this._perPage) {
            slideNumber = this._configuration.loop ? this._currentSlide + this._perPage : this._currentSlide;
        }

        this.slideTo(slideNumber, false);

        // Attach events on rebuild
        this.attachEvents();

        this._perPage$.next(this._perPage);
        this._slide$.next(this._currentSlide);
        this._rendered$.next();

        let shouldExecuteAgain: boolean = this._queue > 1;
        this._queue                     = 0;

        if (shouldExecuteAgain) {
            await this.rebuild();
        }
    }

    /**
     * Go to slide with particular index
     *
     * @param {number} index - Item index to slide to.
     */
    public goto(index: number): void {
        // early return when there is nothing to slide
        if (this._slides.length <= this._perPage) {
            return;
        }

        let beforeChange: number = this._currentSlide;
        let slideNumber: number  = this._configuration.loop ? index % this._slides.length : Math.min(Math.max(index, 0), this._slides.length - this._perPage);

        if (beforeChange === slideNumber) {
            return;
        }

        this.slideTo(slideNumber, false);
    }

    /**
     * Go to next slide.
     *
     * @param {number} [howManySlides=1] - How many items to slide forward.
     */
    public next(howManySlides: number = 1): void {
        // early return when there is nothing to slide
        if (this._slides.length <= this._perPage) {
            return;
        }

        if (this._configuration.loop) {
            let isNewIndexClone: boolean = this._currentSlide + howManySlides > this._slides.length - this._perPage;

            if (!isNewIndexClone) {
                let slideNumber: number = this._currentSlide + howManySlides;
                this.slideTo(slideNumber, true);
                return;
            }

            this.disableTransition();

            let stageWidth: number                      = this._stage.offsetWidth;
            let mirrorSlideIndex: number                = this._currentSlide - this._slides.length;
            let mirrorSlideIndexOffset: number          = this._perPage;
            let moveTo: number                          = mirrorSlideIndex + mirrorSlideIndexOffset;
            let offset: number                          = (this._configuration.rtl ? 1 : -1) * moveTo * (stageWidth / this._perPage);
            let dragDistance: number                    = this._configuration.draggable ? this._interactionState.drag.endX - this._interactionState.drag.startX : 0;
            this._slider.style[this._transformProperty] = `translate3d(${offset + dragDistance}px, 0, 0)`;

            this.slideTo(mirrorSlideIndex + howManySlides, true);
            return;
        }

        let slideNumber: number = Math.min(this._currentSlide + howManySlides, this._slides.length - this._perPage);
        this.slideTo(slideNumber, false);
    }

    /**
     * Go to previous slide.
     *
     * @param {number} [howManySlides=1] - How many items to slide backward.
     */
    public previous(howManySlides: number = 1): void {
        // early return when there is nothing to slide
        if (this._slides.length <= this._perPage) {
            return;
        }

        if (this._configuration.loop) {
            let isNewIndexClone: boolean = this._currentSlide - howManySlides < 0;

            if (!isNewIndexClone) {
                let slideNumber: number = this._currentSlide - howManySlides;
                this.slideTo(slideNumber, true);
                return;
            }

            this.disableTransition();

            let mirrorSlideIndex: number                = this._currentSlide + this._slides.length;
            let mirrorSlideIndexOffset: number          = this._perPage;
            let moveTo: number                          = mirrorSlideIndex + mirrorSlideIndexOffset;
            let offset: number                          = (this._configuration.rtl ? 1 : -1) * moveTo * (this._stage.offsetWidth / this._perPage);
            let dragDistance: number                    = this._configuration.draggable ? this._interactionState.drag.endX - this._interactionState.drag.startX : 0;
            this._slider.style[this._transformProperty] = `translate3d(${offset + dragDistance}px, 0, 0)`;

            this.slideTo(mirrorSlideIndex - howManySlides, true);
            return;
        }

        let slideNumber: number = Math.max(this._currentSlide - howManySlides, 0);
        this.slideTo(slideNumber, false);
    }

    /**
     * Release all resources taken by engine.
     */
    public destroy(): void {
        this.detachEvents();
        this._perPage$.complete();
        this._draggable$.complete();
        this._rendered$.complete();
        this._slide$.complete();
        this._slideable$.complete();
        this._slideablePrevious$.complete();
        this._slideableNext$.complete();
    }

    /**
     * Slide to given slide number.
     */
    private slideTo(slideNumber: number, enableTransition: boolean): void {
        let stageWidth: number = this._stage.offsetWidth;
        let offset: number     = (this._configuration.rtl ? 1 : -1) * slideNumber * (stageWidth / this._perPage);

        if (enableTransition) {
            // This one is tricky, I know but this is a perfect explanation:
            // https://youtu.be/cCOL7MC4Pl0
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    this.enableTransition();
                    this._slider.style[this._transformProperty] = `translate3d(${offset}px, 0, 0)`;
                });
            });
            return;
        }

        this._slider.style[this._transformProperty] = `translate3d(${offset}px, 0, 0)`;

        this._currentSlide             = slideNumber;
        let slideable: boolean         = this._slideable$.value;
        let slideableNext: boolean     = slideable && (this._configuration.loop || this._currentSlide < this._slides.length - this._perPage);
        let slideablePrevious: boolean = slideable && (this._configuration.loop || this._currentSlide !== 0);

        this._slide$.next(this._currentSlide);
        this._slideablePrevious$.next(slideablePrevious);
        this._slideableNext$.next(slideableNext);
    }

    /**
     * Determine number of slides per page based on current rendered width of
     * stage or window (depending on "reference" configuration) and
     * breakpoints configuration.
     */
    private resolveNumberOfSlidesPerPage(): number {
        if ('number' === typeof this._configuration.breakpoints) {
            return this._configuration.breakpoints as number;
        }

        let perPage: number    = 1;
        let stageWidth: number = 'window' === this._configuration.reference ? this._document.defaultView.innerWidth : this._stage.offsetWidth;

        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for (let breakpoint in this._configuration.breakpoints) {
            let current: number = parseInt(breakpoint, 10);
            if (stageWidth >= current) {
                perPage = this._configuration.breakpoints[current];
            }
        }

        return perPage;
    }

    /**
     * Attaches listeners to required events.
     */
    private attachEvents(): void {
        if (this._eventSubscriptions.length > 0) {
            this.detachEvents();
        }

        this._eventSubscriptions.push(
            fromEvent(this._document.defaultView, 'resize', {
                passive: true,
            }).pipe(debounceTime(300)).subscribe(this.onResizeEventHandler),
        );

        // If element is not draggable / swipeable, do not add additional event handlers
        if (!this._configuration.draggable || this._slides.length <= this._perPage) {
            return;
        }

        this._eventSubscriptions.push(
            // Touch events
            fromEvent(this._stage, 'touchstart', {
                passive: true,
            }).subscribe(this.onTouchStartHandler),
            fromEvent(this._stage, 'touchend', {
                passive: true,
            }).subscribe(this.onTouchEndHandler),
            fromEvent(this._stage, 'touchmove', {
                passive: true,
            }).subscribe(this.onTouchMoveHandler),
            // Mouse events
            fromEvent(this._stage, 'mousedown').subscribe(this.onMouseDownHandler),
            fromEvent(this._stage, 'mouseup').subscribe(this.onMouseUpHandler),
            fromEvent(this._stage, 'mouseleave').subscribe(this.onMouseLeaveHandler),
            fromEvent(this._stage, 'mousemove').subscribe(this.onMouseMoveHandler),
            // Click
            fromEvent(this._stage, 'click').subscribe(this.onClickHandler),
        );

    }

    /**
     * Detaches listeners from required events.
     */
    private detachEvents(): void {
        this._eventSubscriptions.forEach((subscription: Unsubscribable): void => {
            subscription.unsubscribe();
        });
        this._eventSubscriptions = [];
    }

    /**
     * Enable transition on slider.
     */
    private enableTransition(): void {
        this._slider.style.webkitTransition = `all ${this._configuration.duration}ms ${this._configuration.easing}`;
        this._slider.style.transition       = `all ${this._configuration.duration}ms ${this._configuration.easing}`;
    }

    /**
     * Disable transition on slider.
     */
    private disableTransition(): void {
        this._slider.style.webkitTransition = `all 0ms ${this._configuration.easing}`;
        this._slider.style.transition       = `all 0ms ${this._configuration.easing}`;
    }

    /**
     * Recalculate drag /swipe event and reposition the frame of a slider
     */
    private updateAfterDrag(): void {
        let movement: number             = (this._configuration.rtl ? -1 : 1) * (this._interactionState.drag.endX - this._interactionState.drag.startX);
        let movementDistance: number     = Math.abs(movement);
        let howManySliderToSlide: number = this._configuration.multipleDrag ? Math.ceil(movementDistance / (this._stage.offsetWidth / this._perPage)) : 1;

        let slideToNegativeClone: boolean = movement > 0 && this._currentSlide - howManySliderToSlide < 0;
        let slideToPositiveClone: boolean = movement < 0 && this._currentSlide + howManySliderToSlide > this._slides.length - this._perPage;

        let moveToPrevious: boolean = movement > 0 && movementDistance > this._configuration.threshold && this._slides.length > this._perPage;
        let moveToNext: boolean     = movement < 0 && movementDistance > this._configuration.threshold && this._slides.length > this._perPage;

        if (moveToPrevious) {
            this.previous(howManySliderToSlide);
        }

        if (moveToNext) {
            this.next(howManySliderToSlide);
        }

        this.slideTo(this._currentSlide, slideToNegativeClone || slideToPositiveClone);
    }

    /**
     * Clear drag intereaction.
     */
    private clearDrag(): void {
        this._interactionState.drag = {
            startX:       0,
            endX:         0,
            startY:       0,
            letItGo:      null,
            preventClick: this._interactionState.drag.preventClick,
        };
    }

    private onResizeEventHandler = (): void => {
        this.rebuild();
    };

    private onTouchStartHandler = (event: TouchEvent): void => {
        event.stopPropagation();
        this._interactionState.pointerDown = true;
        this._interactionState.drag.startX = event.touches[0].pageX;
        this._interactionState.drag.startY = event.touches[0].pageY;
    };

    private onTouchEndHandler = (event: TouchEvent): void => {
        event.stopPropagation();

        this._interactionState.pointerDown = false;

        this.enableTransition();

        if (this._interactionState.drag.endX !== 0) {
            this.updateAfterDrag();
        }

        this.clearDrag();
    };

    private onTouchMoveHandler = (event: TouchEvent): void => {
        event.stopPropagation();

        if (this._interactionState.drag.letItGo === null) {
            this._interactionState.drag.letItGo = Math.abs(this._interactionState.drag.startY - event.touches[0].pageY) < Math.abs(this._interactionState.drag.startX - event.touches[0].pageX);
        }

        if (!this._interactionState.pointerDown || !this._interactionState.drag.letItGo) {
            return;
        }

        event.preventDefault();

        this._interactionState.drag.endX    = event.touches[0].pageX;
        this._slider.style.webkitTransition = `all 0ms ${this._configuration.easing}`;
        this._slider.style.transition       = `all 0ms ${this._configuration.easing}`;

        let currentSlide: number                    = this._configuration.loop ? this._currentSlide + this._perPage : this._currentSlide;
        let currentOffset: number                   = currentSlide * (this._stage.offsetWidth / this._perPage);
        let dragOffset: number                      = this._interactionState.drag.endX - this._interactionState.drag.startX;
        let offset: number                          = this._configuration.rtl ? currentOffset + dragOffset : currentOffset - dragOffset;
        this._slider.style[this._transformProperty] = `translate3d(${(this._configuration.rtl ? 1 : -1) * offset}px, 0, 0)`;
    };

    private onMouseDownHandler = (event: MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();
        this._interactionState.pointerDown = true;
        this._interactionState.drag.startX = event.pageX;
    };

    private onMouseUpHandler = (event: MouseEvent): void => {
        event.stopPropagation();

        this._interactionState.pointerDown = false;

        this.enableTransition();
        if (this._interactionState.drag.endX !== 0) {
            this.updateAfterDrag();
        }

        this.clearDrag();
    };

    private onMouseLeaveHandler = (event: MouseEvent): void => {
        if (!this._interactionState.pointerDown) {
            return;
        }

        this._interactionState.pointerDown       = false;
        this._interactionState.drag.endX         = event.pageX;
        this._interactionState.drag.preventClick = false;

        this.enableTransition();
        this.updateAfterDrag();
        this.clearDrag();
    };

    private onMouseMoveHandler = (event: MouseEvent): void => {
        event.preventDefault();

        if (!this._interactionState.pointerDown) {
            return;
        }

        // if dragged element is a link
        // mark preventClick prop as a true
        // to determine about browser redirection later on
        if ((event.target as HTMLElement).nodeName === 'A') {
            this._interactionState.drag.preventClick = true;
        }

        this._interactionState.drag.endX            = event.pageX;
        this._slider.style.webkitTransition         = `all 0ms ${this._configuration.easing}`;
        this._slider.style.transition               = `all 0ms ${this._configuration.easing}`;
        let currentSlide: number                    = this._configuration.loop ? this._currentSlide + this._perPage : this._currentSlide;
        let currentOffset: number                   = currentSlide * (this._stage.offsetWidth / this._perPage);
        let dragOffset: number                      = this._interactionState.drag.endX - this._interactionState.drag.startX;
        let offset: number                          = this._configuration.rtl ? currentOffset + dragOffset : currentOffset - dragOffset;
        this._slider.style[this._transformProperty] = `translate3d(${(this._configuration.rtl ? 1 : -1) * offset}px, 0, 0)`;
    };

    private onClickHandler = (event: MouseEvent): void => {
        // if the dragged element is a link
        // prevent browsers from following the link
        if (this._interactionState.drag.preventClick) {
            event.preventDefault();
        }

        this._interactionState.drag.preventClick = false;
    };

}
