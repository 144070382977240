import { ComponentRef }       from '@angular/core';
import { AppComponent }       from '../app.component';

export function bootstrapPreventIframeInjection(
    document: Document,
): (ref: ComponentRef<any>) => void {
    let window: Window = document.defaultView;

    return (ref: ComponentRef<any>): void => {
        if (!(ref.instance instanceof AppComponent)) {
            return;
        }

        if (window.self === window.top) {
            document.body.style.visibility = 'visible';
            return;
        }

        window.top.location = window.self.location;

    };
}
