import {
    ComponentRef,
    Injector,
}                       from '@angular/core';
import {
    GOOGLE_TAG_MANAGER,
    GoogleTagManagerInterface,
}                       from '../../shared';
import { AppComponent } from '../app.component';

export function bootstrapGoogleTagManager(injector: Injector): (ref: ComponentRef<any>) => void {

    return (ref: ComponentRef<any>): void => {

        if (!(ref.instance instanceof AppComponent)) {
            return;
        }

        let googleTagManager: GoogleTagManagerInterface = injector.get<GoogleTagManagerInterface>(GOOGLE_TAG_MANAGER);
        googleTagManager.init();
    };

}
