import { DOCUMENT }     from '@angular/common';
import {
    Inject,
    Injectable,
}                       from '@angular/core';
import {
    BehaviorSubject,
    fromEvent,
    Observable,
    PartialObserver,
    Subscribable,
    Unsubscribable,
    OperatorFunction,
    pipe,
}                       from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class WindowWidthObservable implements Subscribable<number> {

    private readonly _subject: BehaviorSubject<number>;

    private readonly _width: number;

    public constructor(
        @Inject(DOCUMENT) document,
    ) {
        let window: Window = document.defaultView;
        this._width        = window.innerWidth;
        this._subject      = new BehaviorSubject(this._width);

        fromEvent(window, 'resize').pipe(debounceTime(50)).subscribe((): void => {
            if (this._width === window.innerWidth) {
                return;
            }

            this._subject.next(window.innerWidth);
        });
    }

    public subscribe(observer?: PartialObserver<number>): Unsubscribable;
    public subscribe(next: null | undefined, error: null | undefined, complete: () => void): Unsubscribable;
    public subscribe(next: null | undefined, error: (error: any) => void, complete?: () => void): Unsubscribable;
    public subscribe(next: (value: number) => void, error: null | undefined, complete: () => void): Unsubscribable;
    public subscribe(next?: (value: number) => void, error?: (error: any) => void, complete?: () => void): Unsubscribable;
    public subscribe(observer?: PartialObserver<number> | null | undefined | ((value: number) => void), error?: null | undefined | ((error: any) => void), complete?: () => void): Unsubscribable {
        return this._subject.subscribe(observer as (value: number) => void, error, complete);
    }

    public pipe(...operations: OperatorFunction<any, any>[]): Observable<number> {
        if (operations.length === 0) {
            return this._subject;
        }

        // @ts-ignore
        return pipe(...operations)(this._subject);
    }

}
