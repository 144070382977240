export interface InteractionStateInterface {
    pointerDown: boolean;
    drag: {
        startX: number;
        endX: number;
        startY: number;
        letItGo: boolean;
        preventClick: boolean;
    }
}

export function createDefaultInteractionState(): InteractionStateInterface {
    return {
        pointerDown: false,
        drag:        {
            startX:       0,
            endX:         0,
            startY:       0,
            letItGo:      null,
            preventClick: false,
        },
    };
}
