import { Type }            from '@angular/core';
import { ActiveRoutePipe } from './active-route.pipe';
import { CountryNamePipe } from './country-name.pipe';
import { IsTodayPipe }     from './is-today.pipe';
import { StripTagsPipe }   from './strip-tags.pipe';
import { TruncatePipe }    from './truncate.pipe';

export { TruncatePipe }    from './truncate.pipe';
export { CountryNamePipe } from './country-name.pipe';
export { IsTodayPipe }     from './is-today.pipe';
export { ActiveRoutePipe } from './active-route.pipe';
export { StripTagsPipe }   from './strip-tags.pipe';

export const EVERMED_SHARED_PIPES: Type<any>[] = [
    TruncatePipe,
    CountryNamePipe,
    IsTodayPipe,
    ActiveRoutePipe,
    StripTagsPipe,
];
