import {
    ChangeDetectorRef,
    TemplateRef,
    ViewContainerRef,
}                                 from '@angular/core';
import { untilDestroyed }         from '@ngneat/until-destroy';
import { WindowWidthObservable }  from '../../services';
import { RESPONSIVE_BREAKPOINTS } from './breakpoints';

export abstract class AbstractResponsiveIfDirective {

    protected _breakpoints: Array<[number, number]> = [];

    protected _width: number = null;

    private readonly _templateRef: TemplateRef<any>;

    private readonly _viewContainer: ViewContainerRef;

    private readonly _cdr: ChangeDetectorRef;

    private _visible: boolean = false;

    protected constructor(
        templateRef: TemplateRef<any>,
        viewContainer: ViewContainerRef,
        resize: WindowWidthObservable,
        cdr: ChangeDetectorRef,
    ) {
        this._templateRef   = templateRef;
        this._viewContainer = viewContainer;
        this._cdr           = cdr;

        resize.pipe(untilDestroyed(this)).subscribe((width: number): void => {
            this._width = width;
            this.recalculate();
        });
    }

    protected abstract recalculate(): void;

    protected hide(): void {
        if (!this._visible) {
            return;
        }

        this._viewContainer.clear();
        this._visible = false;
        this._cdr.markForCheck();
    }

    protected show(): void {
        if (this._visible) {
            return;
        }

        this._viewContainer.createEmbeddedView(this._templateRef);
        this._visible = true;
        this._cdr.markForCheck();
    }

    protected setBreakpoints(breakpoints: string | string[] | [number, number] | Array<[number, number]>): void {
        // clear breakpoints.
        this._breakpoints = [];

        if ('string' === typeof breakpoints) {
            this._breakpoints.push(RESPONSIVE_BREAKPOINTS[breakpoints]);
            this.recalculate();
            return;
        }

        if (!Array.isArray(breakpoints) || 0 === breakpoints.length) {
            this.recalculate();
            return;
        }

        if ('number' === typeof breakpoints[0]) {
            this._breakpoints.push(<[number, number]>(breakpoints as any));
            this.recalculate();
            return;
        }

        (<string[] | Array<[number, number]>>breakpoints).forEach((value: string | [number, number]): void => {
            if ('string' === typeof value) {
                this._breakpoints.push(RESPONSIVE_BREAKPOINTS[value]);
                return;
            }

            this._breakpoints.push(value);
        });

        this.recalculate();
    }

}
