import {
    Component,
    OnInit,
}                         from '@angular/core';
import { Router }         from '@angular/router';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';
import {
    Actions,
    ofActionDispatched,
    Select,
    Store,
}                         from '@ngxs/store';
import {
    AuthSelector,
    Registered,
}                         from '@evermed/core';
import { Observable } from 'rxjs';
import {
    PurchaseResolver,
    Toast,
}                     from '../shared';

@UntilDestroy()
@Component({
    selector:    'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

    @Select(AuthSelector.isAuthenticated)
    public authenticated$: Observable<boolean>;

    public authenticated: boolean;

    private readonly _store: Store;

    private readonly _actions: Actions;

    private readonly _router: Router;

    private readonly _purchaseResolver: PurchaseResolver;

    private readonly _toast: Toast;

    public constructor(
        actions: Actions,
        router: Router,
        purchaseResolver: PurchaseResolver,
        store: Store,
        toast: Toast,
    ) {
        this._actions          = actions;
        this._router           = router;
        this._purchaseResolver = purchaseResolver;
        this._toast            = toast;
        this._store = store;

        this.authenticated$.pipe(untilDestroyed(this)).subscribe((authenticated: boolean) => {
            this.authenticated = authenticated;
        });
    }

    public async ngOnInit(): Promise<void> {

        // Listen to purchase actions and execute actions accordingly.
        // TODO Move this to application initializers
        this._purchaseResolver.listen();

        // Listen to registration event
        // TODO Move this to application initializers
        this._actions.pipe(ofActionDispatched(Registered)).subscribe((/* action: Registered */): void => {
            this._toast.success('pages.register.toast.success.message', null, {
                title: 'pages.register.toast.success.title',
            }).then(/* noop */);
        });
    }

}
