import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
} from '@angular/core';

@Component({
    selector:        'app-expiration-date',
    templateUrl:     './expiration-date.component.html',
    styleUrls:       ['./expiration-date.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpirationDateComponent {

    @Input()
    public date: Date | null;

    @Input()
    public format: string | null = 'shortDate';

    private readonly _cdr: ChangeDetectorRef;

    public constructor(cdr: ChangeDetectorRef) {
        this._cdr = cdr;
    }

    @HostBinding('class')
    public get expiration(): 'never' | 'ongoing' | 'expired' {
        if (null === this.date) {
            return 'never';
        }

        if (this.date > new Date()) {
            return 'ongoing';
        }

        return 'expired';
    }

}
