import {
    Inject,
    Injectable,
}                    from '@angular/core';
import { DOCUMENT }  from '@angular/common';
import {
    Event,
    NavigationEnd,
    Router,
}                    from '@angular/router';
import { fromEvent } from 'rxjs';
import {
    filter,
    take,
}                    from 'rxjs/operators';
import { Deferred }  from '@evermed-sdk/core';
import {
    GOOGLE_ANALYTICS_ID,
    GoogleAnalyticsInterface,
}                    from './google-analytics.interface';

declare let gtag: Function;

@Injectable()
export class GoogleAnalytics implements GoogleAnalyticsInterface {

    private static readonly SCRIPT_ID: string = 'GAscript';

    private static readonly SCRIPT_URL: string = 'https://www.googletagmanager.com/gtag/js';

    private readonly _document: Document;

    private readonly _gaId: string;

    private readonly _router: Router;

    private _initialized: boolean = false;

    private readonly _ready: Deferred<boolean> = new Deferred<boolean>();

    public constructor(
        router: Router,
        @Inject(DOCUMENT) document: Document,
        @Inject(GOOGLE_ANALYTICS_ID) googleAnalyticsID: string,
    ) {
        this._document = document;
        this._gaId     = googleAnalyticsID;
        this._router   = router;
        this.init();
    }

    public init(): void {
        if (this._initialized) {
            return;
        }
        this._initialized                 = true;
        let script: HTMLScriptElement     = this._document.createElement('script');
        script.id                         = GoogleAnalytics.SCRIPT_ID;
        script.async                      = true;
        script.src                        = `${GoogleAnalytics.SCRIPT_URL}?id=${this._gaId}`;
        const gaScript: HTMLScriptElement = this._document.createElement('script');
        gaScript.innerHTML                = `
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            `;

        fromEvent(script, 'load').pipe(take(1)).subscribe((): void => {
            this._ready.resolve(true);
            this._router.events.pipe(filter((event: Event): boolean => event instanceof NavigationEnd)).subscribe((event: NavigationEnd): void => {
                gtag('config', this._gaId, {
                    page_path: event.urlAfterRedirects,
                });
            });
        });
        this._document.head.insertBefore(gaScript, this._document.head.firstChild);
        this._document.head.insertBefore(script, this._document.head.firstChild);
    }

    private ready(): Promise<boolean> {
        return this._ready.promise;
    }

}
