import { DOCUMENT }     from '@angular/common';
import {
    Directive,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
}                       from '@angular/core';
import {
    UntilDestroy,
    untilDestroyed,
}                       from '@ngneat/until-destroy';
import { fromEvent }    from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Directive({
    selector: '[evermedEqualize]',
})
export class EqualizeDirective implements OnInit {

    /**
     * How long to debounce until calculating equalization.
     */
    @Input('evermedEqualizeDelay')
    public delay: number = 300;

    /**
     * Set width/height according to the largest or smallest element.
     */
    @Input('evermedEqualizeMode')
    public mode: 'min' | 'max' = 'max';

    /**
     * Set which dimension ought to be equalized, width, height or both.
     */
    @Input('evermedEqualizeProperty')
    public property: 'width' | 'height' | 'both' = 'height';

    /**
     * Disable equalisation.
     */
    @Input('evermedEqualize')
    public disabled: boolean;

    /**
     * Equalization may occur only on load and on manual invocation.
     */
    @Input('evermedEqualizeManually')
    public manual: boolean = false;

    /**
     * Dispatched when equalization process completes.
     */
    @Output('evermedEqualized')
    public readonly equalized: EventEmitter<void> = new EventEmitter<void>();

    private readonly _elementRef: ElementRef;

    private readonly _document: Document;

    /**
     * It is possible to invoke equalize method several times during
     * preloading of images. In order not to execute it several times
     * we will try to optimize number of execution through simple
     * execution counter.
     */
    private _queue: number = 0;

    public constructor(
        elementRef: ElementRef,
        @Inject(DOCUMENT) document: Document,
    ) {
        this._document   = document;
        this._elementRef = elementRef;
    }

    public ngOnInit(): void {
        if (this.manual) {
            return;
        }

        fromEvent(this._document.defaultView, 'resize', {
            passive: true,
        })
            .pipe(debounceTime(this.delay))
            .pipe(untilDestroyed(this))
            .subscribe(this.equalize.bind(this));

        this.equalize().then(/* noop */);
    }

    public async equalize(): Promise<void> {
        if (this.disabled) {
            return;
        }

        // there is ongoing execution, so we will just return.
        if (++this._queue > 1) {
            return;
        }

        await this.reset(); // reset to original state and allow all children to occupy necessary space.

        let properties: Array<'width' | 'height'> = 'both' === this.property ? ['width', 'height'] : [this.property];
        let element: HTMLElement                  = this._elementRef.nativeElement;
        let equalized: HTMLElement[]              = Array.from(element.querySelectorAll('[data-equalize]'));

        let preloading: Promise<void>[]               = [];
        let groups: { [name: string]: HTMLElement[] } = {};
        let widths: { [name: string]: number[] }      = {};
        let heights: { [name: string]: number[] }     = {};

        equalized.forEach((current: HTMLElement): void => {
            preloading.push(this.preload(current));
        });

        await Promise.all(preloading);

        equalized.forEach((current: HTMLElement): void => {
            let name: string    = current.getAttribute('data-equalize');
            let bounds: DOMRect = current.getBoundingClientRect();
            widths[name]        = widths[name] || [];
            heights[name]       = heights[name] || [];
            groups[name]        = groups[name] || [];

            widths[name].push(bounds.width);
            heights[name].push(bounds.height);
            groups[name].push(current);
        });

        Object.keys(groups).forEach((name: string): void => {
            let width: number  = Math.ceil('max' === this.mode ? Math.max(...widths[name]) : Math.min(...widths[name]));
            let height: number = Math.ceil('max' === this.mode ? Math.max(...heights[name]) : Math.min(...heights[name]));

            properties.forEach((property: string): void => {
                let dimension: number = 'width' === property ? width : height;
                groups[name].forEach((current: HTMLElement): void => {
                    current.style[property] = `${dimension}px`;
                });
            });
        });

        let shouldExecuteAgain: boolean = this._queue > 1;
        this._queue                     = 0;

        if (shouldExecuteAgain) {
            await this.equalize();
        }
    }

    public async reset(): Promise<void> {
        let properties: Array<'width' | 'height'> = 'both' === this.property ? ['width', 'height'] : [this.property];
        let element: HTMLElement                  = this._elementRef.nativeElement;
        let equalized: HTMLElement[]              = Array.from(element.querySelectorAll('[data-equalize]'));

        equalized.forEach((current: HTMLElement): void => {
            properties.forEach((declaration: 'width' | 'height'): void => {
                current.style[declaration] = null;
            });
        });

        return new Promise((resolve: () => void): void => {
            setTimeout(resolve, 100);
        });
    }

    // eslint-disable-next-line class-methods-use-this
    private async preload(element: HTMLElement): Promise<void> {
        let images: HTMLImageElement[] = Array.from(element.querySelectorAll('img'));

        if ('IMG' === element.tagName) {
            images.push(element as HTMLImageElement);
        }

        let preload: Promise<void>[] = [];

        images.forEach((current: HTMLImageElement): void => {
            let promise: Promise<void> = new Promise<void>((resolve: () => void): void => {
                let image: HTMLImageElement = new Image();
                image.onerror               = resolve;
                image.onload                = resolve;
                image.src                   = current.src;
            });

            preload.push(promise);
        });

        await Promise.all(preload);
    }

}
