import {
    Component,
    Input,
}                 from '@angular/core';
import { Router } from '@angular/router';
import {
    AbstractProduct,
    RedeemAccessCode,
    RedeemAccessCodeForm,
    ValidationErrors,
}                 from '@evermed/core';
import { Store }  from '@ngxs/store';
import { Toast }  from '../../../services';

@Component({
    selector:    'app-redeem-access-code-modal',
    templateUrl: './redeem-access-code-modal.component.html',
    styleUrls:   ['./redeem-access-code-modal.component.scss'],
})
export class RedeemAccessCodeModalComponent {

    @Input()
    public dismissRef: () => void;

    @Input()
    public product: AbstractProduct = null;

    public form: RedeemAccessCodeForm = new RedeemAccessCodeForm();

    private readonly _store: Store;

    private readonly _toast: Toast;

    private readonly _router: Router;

    public constructor(
        store: Store,
        toast: Toast,
        router: Router,
    ) {
        this._store  = store;
        this._toast  = toast;
        this._router = router;
    }

    public async submit(): Promise<void> {
        if (!this.form.valid) {
            return;
        }

        try {
            let action: RedeemAccessCode = new RedeemAccessCode(this.form.getValue().code, this.product);
            await this._store.dispatch(action).toPromise();
            this.dismissRef();
        } catch (e) {
            if (e instanceof ValidationErrors) {
                e.patch(this.form);
                return;
            }

            throw e;
        }
    }

}
