import {
    ChangeDetectorRef,
    Component, Input,
    OnInit,
} from '@angular/core';

@Component({
    selector:    'app-countdown-timer',
    templateUrl: './countdown-timer.component.html',
    styleUrls:   ['./countdown-timer.component.scss'],
})
export class CountdownTimerComponent implements OnInit {

    @Input()
    public targetDateTime: Date = null;

    public remains: number | null = null;

    public secondsToTargetDate: number = 0;

    public minutesToTargetDate: number = 0;

    public hoursToTargetDate: number = 0;

    public daysToTargetDate: number = 0;

    public duration: number = 0;

    private _interval: ReturnType<typeof setInterval> | null = null;

    private readonly _cdr: ChangeDetectorRef;

    public constructor(cdr: ChangeDetectorRef) {
        this._cdr = cdr;
    }

    public ngOnInit(): void {
        this.start();
    }

    private start(): void {

        this.remains = (this.targetDateTime.getTime() - (new Date()).getTime());
        this._interval = setInterval((): void => {
            this.remains = (this.targetDateTime.getTime() - (new Date()).getTime()) / 1000;

            // eslint-disable-next-line no-mixed-operators
            this.secondsToTargetDate = Math.floor((this.remains) % 60);
            // eslint-disable-next-line no-mixed-operators
            this.minutesToTargetDate = Math.floor((this.remains) / (60) % 60);
            // eslint-disable-next-line no-mixed-operators
            this.hoursToTargetDate = Math.floor((this.remains) / (60 * 60) % 24);
            this.daysToTargetDate = Math.floor((this.remains) / (60 * 60 * 24));

            this._cdr.markForCheck();
        }, 1000);
        this._cdr.markForCheck();
    }


}
