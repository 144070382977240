import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
}                                      from '@angular/core';
import {
    UntilDestroy,
    untilDestroyed,
}                                      from '@ngneat/until-destroy';
import { AbstractCollectionComponent } from '../abstract-collection-component';
// noinspection ES6PreferShortImport - short import creates circular dependency
import { EqualizeDirective }           from '../../../directives/equalize/equalize.directive';

@UntilDestroy()
@Component({
    selector:        'app-grid',
    templateUrl:     './grid.component.html',
    styleUrls:       ['./grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridComponent extends AbstractCollectionComponent implements OnChanges, AfterViewInit, AfterViewChecked {

    /**
     * A CSS class/classes which will be applied on each individual
     * grid cell. A default value should be sensible and applicable
     * throughout major portion of application, while customisation
     * ought to be possible via this binding.
     */
    @Input()
    public cell: string = 'col-md-6 col-lg-4 col-xl-3';

    @ViewChildren('cells')
    private readonly _cells: QueryList<HTMLElement>;

    @ViewChild(EqualizeDirective)
    private readonly _equalizer: EqualizeDirective;

    /**
     * A simple flag denoting if equalization is required
     * changes occurred to a properties which will not
     * inflict changes in cells rendering.
     */
    private _equalize: boolean = false;

    // eslint-disable-next-line no-useless-constructor
    public constructor(cdr: ChangeDetectorRef) {
        super(cdr);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
        this._equalize = true;
    }

    public ngAfterViewInit(): void {
        this._cells.changes.pipe(untilDestroyed(this)).subscribe(async (): Promise<void> => {
            this._equalizer.equalize().then(/* noop */);
        });

        this._equalizer.equalize().then(/* noop */);
    }

    public ngAfterViewChecked(): void {
        if (!this._equalize) {
            return;
        }

        this._equalize = false;
        this._equalizer.equalize().then(/* noop */);
    }

}
