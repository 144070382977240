import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector:        'app-pdf-viewer-adapter',
    templateUrl:     './pdf-viewer-adapter.component.html',
    styleUrls:       ['./pdf-viewer-adapter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation:   ViewEncapsulation.None,
})
export class PdfViewerAdapterComponent {

    @Input()
    public page: number = 1;

    @Input()
    public pdf: string = null;

    @Output()
    public readonly pageChange: EventEmitter<number> = new EventEmitter<number>();

    public onPageChange(page: number): void {
        this.page = page;
        this.pageChange.emit(page);
    }

}
