import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
} from '@angular/core';

@Component({
    selector:        'app-page-spinner',
    templateUrl:     './page-spinner.component.html',
    styleUrls:       ['./page-spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageSpinnerComponent {

    /**
     * Inverse color of spinner.
     */
    @Input()
    public inverse: boolean = false;

    /**
     * Disable component.
     */
    @Input()
    public disabled: boolean = false;

    /**
     * Size of the spinner.
     */
    @Input()
    public size: 'lg' | 'md' | 'sm' | 'xs' = 'lg';

    @HostBinding('class')
    public get sizeClass(): string {
        return this.size;
    }

}
