import { Observable }                       from 'rxjs';
import { ActiveToast as ToastrActiveToast } from 'ngx-toastr';

export class ActiveToast {

    private readonly _activeToast: ToastrActiveToast<void>;

    public constructor(toast: ToastrActiveToast<void>) {
        this._activeToast = toast;
    }

    public onShown(): Observable<void> {
        return this._activeToast.onShown;
    }

    public onHidden(): Observable<void> {
        return this._activeToast.onHidden;
    }

}
