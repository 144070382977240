import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
}                          from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
}                          from '@angular/forms';
import {
    BehaviorSubject,
    Observable,
    Subject,
    Unsubscribable,
}                          from 'rxjs';
import { ChoiceInterface } from '@evermed/core';

@Component({
    selector:        'app-choice-select',
    templateUrl:     './choice-select.component.html',
    styleUrls:       ['./choice-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers:       [
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line no-use-before-define
            useExisting: forwardRef(() => ChoiceSelectComponent),
            multi:       true,
        },
    ],
})
export class ChoiceSelectComponent implements ControlValueAccessor, OnChanges, AfterViewInit {

    @Input()
    public class: string;

    @Input()
    public placeholder: string;

    @Input()
    public disabled: boolean = true;

    @Input()
    public multiple: boolean = false;

    @Input()
    public required: boolean = false;

    @Input()
    public choices: ChoiceInterface[] | Observable<ChoiceInterface[]>;

    @Output()
    public children: EventEmitter<ChoiceInterface[] | null> = new EventEmitter<ChoiceInterface[] | null>();

    public value: string | string[];

    public loaded: ChoiceInterface[] | null = null;

    @ViewChild('select')
    private readonly _select: ElementRef;

    private _subscription: Unsubscribable = null;

    private _onChangeHandler: (value: any) => void;

    private _onTouchedHandler: () => void;

    private readonly _hostElement: ElementRef;

    private readonly _cdr: ChangeDetectorRef;

    public constructor(hostElement: ElementRef, cdr: ChangeDetectorRef) {
        this._hostElement = hostElement;
        this._cdr         = cdr;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.choices) {
            return;
        }

        let current: Subject<ChoiceInterface[] | null> | ChoiceInterface[] | null;
        let source: Subject<ChoiceInterface[] | null>;

        current = changes.choices.currentValue || new BehaviorSubject(null);
        source  = Array.isArray(current) ? new BehaviorSubject(current) : current;

        this.subscribe(source);
    }

    public ngAfterViewInit(): void {
        this._hostElement.nativeElement.classList.remove(this.class);
    }

    public isEmpty(): boolean {
        if (this.multiple) {
            return !this.value || 0 === this.value.length;
        }

        return !this.value;
    }

    public isSelected(value: string): boolean {
        let values: string[] = <string[]>(this.multiple ? this.value : [this.value]);
        return -1 !== values.indexOf(value);
    }

    public onChange(): void {
        this.value = this._select.nativeElement.value;
        this.notifyValueChanged();
    }

    public registerOnChange(fn: any): void {
        this._onChangeHandler = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouchedHandler = fn;
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
        this._cdr.markForCheck();
    }

    public writeValue(obj: string | string[]): void {
        this.value = obj;
        this.notifyChoiceChildrenChangedHandler();
        this._cdr.markForCheck();
    }

    private subscribe(source: Observable<ChoiceInterface[] | null>): void {
        if (null !== this._subscription) {
            this._subscription.unsubscribe();
        }

        this.loaded        = null;
        this._subscription = source.subscribe((choices: ChoiceInterface[]): void => {
            this.onChoicesChangedHandler(choices);
            this._cdr.markForCheck();
        });
    }

    private onChoicesChangedHandler(choices: ChoiceInterface[]): void {
        this.loaded   = choices || null;
        this.disabled = null === this.loaded;

        if (this.isEmpty()) {
            return;
        }

        if (null === this.loaded) {
            this.value = this.multiple ? [] : '';
            this.notifyValueChanged();

            return;
        }

        let current: string[]    = (this.multiple ? this.value : [this.value]) as string[];
        let applicable: string[] = [];
        this.loaded.forEach((choice: ChoiceInterface): void => {
            if (-1 !== current.indexOf(choice.value)) {
                applicable.push(choice.value);
            }
        });

        if (this.multiple) {
            this.value = applicable;
            this.notifyValueChanged();
            return;
        }

        this.value = 1 === applicable.length ? applicable[0] : '';
        this.notifyValueChanged();
    }

    private notifyValueChanged(): void {
        if (!this._onChangeHandler) {
            return;
        }

        this._onChangeHandler(this.value);
        this._onTouchedHandler();
        this.notifyChoiceChildrenChangedHandler();
    }

    private notifyChoiceChildrenChangedHandler(): void {
        if (!this.loaded || this.isEmpty()) {
            this.children.emit(null);
            return;
        }

        let values: string[]            = (this.multiple ? this.value : [this.value]) as string[];
        let children: ChoiceInterface[] = [];

        this.loaded.forEach((current: ChoiceInterface): void => {
            if (-1 !== values.indexOf(current.value) && current.children) {
                children = [
                    ...children,
                    ...current.children,
                ];
            }
        });

        if (0 === children.length) {
            this.children.emit(null);
            return;
        }

        this.children.emit(children);
    }

}
