/* eslint-disable object-curly-newline,prefer-destructuring */
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    Params,
}                         from '@angular/router';
import {
    BehaviorSubject,
    Observable,
    Unsubscribable,
}                         from 'rxjs';
import { sortObjectKeys } from '../functions';

export class ActivatedRouteDecorator {

    private readonly _route: ActivatedRoute;

    private readonly _subject: BehaviorSubject<RouteSnapshot>;

    private _params: Params;

    private _queryParams: Params;

    private _subscriptions: Unsubscribable[] = [];

    private constructor(route: ActivatedRoute) {
        let snapshot: ActivatedRouteSnapshot = route.snapshot;

        this._route       = route;
        this._params      = sortObjectKeys(snapshot.params);
        this._queryParams = sortObjectKeys(snapshot.params);
        this._subject     = new BehaviorSubject<RouteSnapshot>({
            params:      snapshot.params,
            queryParams: snapshot.queryParams,
        });

        this._subscriptions.push(route.params.subscribe((params: Params): void => {
            let merged: Params = sortObjectKeys({
                ...this._params,
                ...params,
            });

            if (JSON.stringify(merged) === JSON.stringify(this._params)) {
                return;
            }

            this._params = merged;
            this.notify();
        }));

        this._subscriptions.push(route.queryParams.subscribe((params: Params): void => {
            let merged: Params = sortObjectKeys({
                ...this._queryParams,
                ...params,
            });

            if (JSON.stringify(merged) === JSON.stringify(this._queryParams)) {
                return;
            }

            this._queryParams = merged;
            this.notify();
        }));
    }

    public get onChange$(): Observable<RouteSnapshot> {
        return this._subject.asObservable();
    }

    public destroy(): void {
        this._subscriptions.forEach((subscription: Unsubscribable) => {
            subscription.unsubscribe();
        });

        this._subscriptions = [];
    }

    private notify(): void {
        if (this._subject.closed) {
            this.destroy();
            return;
        }

        this._subject.next({
            params:      this._params,
            queryParams: this._queryParams,
        });
    }

    public static create(route: ActivatedRoute): ActivatedRouteDecorator {
        return new ActivatedRouteDecorator(route);
    }

}

export interface RouteSnapshot {
    params: Params;
    queryParams: Params;
}
