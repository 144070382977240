import { DOCUMENT }   from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
}                     from '@angular/core';
import Cookies        from 'js-cookie';
import { animations } from './cookies-consent.animation';

@Component({
    selector:        'app-cookies-consent',
    templateUrl:     './cookies-consent.component.html',
    styleUrls:       ['./cookies-consent.component.scss'],
    animations:       animations,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesConsentComponent implements OnInit {

    public haveCookie: boolean = false;

    public hidden: boolean = true;

    private static readonly COOKIE_NAME: string = 'evermed_cookie_consent_confirmed';

    private readonly _window: Window;

    private readonly _cdr: ChangeDetectorRef;

    public constructor(
        cdr: ChangeDetectorRef,
        @Inject(DOCUMENT) document: Document,
    ) {
        this._window = document.defaultView;
        this._cdr    = cdr;
    }

    public ngOnInit(): void {
        if (this.hasConsent()) {
            return;
        }

        this.show();
    }

    public confirm(): void {
        Cookies.set(CookiesConsentComponent.COOKIE_NAME, 'true', {
            expires: 365 * 5, // 5 years
        });
        this._window.localStorage.setItem(CookiesConsentComponent.COOKIE_NAME, 'true');
        this.hidden = true;
        this._cdr.markForCheck();
    }

    private show(): void {
        this.hidden = false;
        this._cdr.markForCheck();
    }

    private hasConsent(): boolean {
        let cookie: boolean  = 'true' === Cookies.get(CookiesConsentComponent.COOKIE_NAME);
        let storage: boolean = 'true' === this._window.localStorage.getItem(CookiesConsentComponent.COOKIE_NAME);

        return cookie || storage;
    }

}
