import {
    Component,
    Input,
    OnInit,
}                       from '@angular/core';
import { Store }        from '@ngxs/store';
import {
    User,
    LoadUsers,
    UserSelector,
    LoadCurrentUser,
    AuthSelector,
}                       from '@evermed/core';

@Component({
    selector:    'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls:   ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {

    private _user: string | User | null;

    @Input()
    public set user(user: string | User | null) {
        this._user = user;
        this.loadUserAvatar().then(/** noop */);
    }

    public avatar: any = null;

    private readonly _store: Store;

    public constructor(store: Store) {
        this._store = store;
    }

    public ngOnInit(): void {
        this.loadUserAvatar().then(/** noop */);
    }

    private async loadUserAvatar(): Promise<void> {
        if (null === this._user || undefined === this._user) {
            let userId: string = this._store.selectSnapshot(AuthSelector.id);
            await this._store.dispatch(new LoadCurrentUser(userId)).toPromise();
            this.avatar = this._store.selectSnapshot(UserSelector.users).get(userId).avatar;
            return;
        }

        if ('string' === typeof this._user) {
            await this._store.dispatch(new LoadUsers(this._user)).toPromise();
            this.avatar = this._store.selectSnapshot(UserSelector.users).get(this._user).avatar;
            return;
        }

        this.avatar = this._user.avatar;
    }

}
