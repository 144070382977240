import {
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    Input,
    Output,
    Type,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
    selector:    'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls:   ['./checkbox.component.scss'],
    providers:   [{
        // eslint-disable-next-line no-use-before-define
        useExisting: forwardRef((): Type<CheckboxComponent> => CheckboxComponent),
        provide:     NG_VALUE_ACCESSOR,
        multi:       true,
    }],
})
export class CheckboxComponent implements ControlValueAccessor {

    @Input()
    public disabled: boolean = false;

    @Input()
    public set value(value: boolean) {
        if (this.disabled) {
            return;
        }

        if (this._value === value) {
            return;
        }

        this._value = value;
        this._onChangeHandler(value);
        this._onTouchedHandler();
        this.change.emit(value);
    }

    public get value(): boolean {
        return this._value;
    }

    @Output()
    public readonly change: EventEmitter<boolean> = new EventEmitter<boolean>();

    public set checked(value: boolean) {
        this.value = value;
    }

    @HostBinding('class.checked')
    public get checked(): boolean {
        return this._value;
    }

    private _value: boolean = false;

    private _onChangeHandler: (_: any) => void = (_: any): void => {};

    private _onTouchedHandler: () => void = (): void => {};

    @HostListener('click')
    public toggle(): void {
        this.value = !this._value;
    }

    public registerOnChange(fn: (_: any) => void): void {
        this._onChangeHandler = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouchedHandler = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(value: any): void {
        this._value = value;
    }

}
