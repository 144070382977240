import {
    Directive,
    Output,
    EventEmitter,
    ElementRef,
    HostListener,
} from '@angular/core';

@Directive({
    selector: '[clickOutside]',
})
export class ClickOutsideDirective {

    @Output()
    public clickOutside: EventEmitter<void> = new EventEmitter<void>();

    // eslint-disable-next-line no-empty-function,no-useless-constructor
    public constructor(private elementRef: ElementRef) {}

    @HostListener('document:click', ['$event.target'])
    public onClick(target: Element): void {
        const clickedInside: boolean = this.elementRef.nativeElement.contains(target);
        if (!clickedInside) {
            this.clickOutside.emit();
        }
    }

}
