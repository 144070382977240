import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isToday',
})
export class IsTodayPipe implements PipeTransform {

    // eslint-disable-next-line class-methods-use-this
    public transform(value: Date): boolean {
        let now: Date = new Date();
        if (value.getFullYear() !== now.getFullYear()) {
            return false;
        }

        if (value.getMonth() !== now.getMonth()) {
            return false;
        }

        if (value.getDate() !== now.getDate()) {
            return false;
        }

        return true;
    }

}
