import { DOCUMENT }              from '@angular/common';
import {
    Injector,
    Provider,
}                                from '@angular/core';
import { Router }                from '@angular/router';
import {
    GOOGLE_ANALYTICS,
    GOOGLE_ANALYTICS_ENABLED,
    GOOGLE_ANALYTICS_ID,
    GoogleAnalytics,
    NullGoogleAnalytics,
    GoogleAnalyticsInterface,
    GOOGLE_TAG_MANAGER,
    GOOGLE_TAG_MANAGER_AUTH,
    GOOGLE_TAG_MANAGER_ENABLED,
    GOOGLE_TAG_MANAGER_ID,
    GOOGLE_TAG_MANAGER_PREVIEW,
    GoogleTagManager,
    GoogleTagManagerInterface,
}                                from './analytics';
import { ModalController }       from './modal';
import { PurchaseResolver }      from './purchase';
import { WindowWidthObservable } from './responsive';
import { Toast }                 from './toast';

export * from './modal';
export * from './purchase';
export * from './toast';
export * from './responsive';
export * from './analytics';

export const EVERMED_SHARED_SERVICES: Provider[] = [
    ModalController,
    PurchaseResolver,
    Toast,
    WindowWidthObservable,
    {
        provide:    GOOGLE_ANALYTICS,
        useFactory: function (
            injector: Injector,
            router: Router,
        ): GoogleAnalyticsInterface {
            let enabled: boolean = injector.get<boolean>(GOOGLE_ANALYTICS_ENABLED);

            if (enabled) {
                return new GoogleAnalytics(router, injector.get<Document>(DOCUMENT), injector.get<string>(GOOGLE_ANALYTICS_ID));
            }

            return new NullGoogleAnalytics();
        },
        deps:       [Injector, Router],
    },
    {
        provide:    GOOGLE_TAG_MANAGER,
        useFactory: function (
            injector: Injector,
            router: Router,
        ): GoogleTagManagerInterface {
            let enabled: boolean = injector.get<boolean>(GOOGLE_TAG_MANAGER_ENABLED);

            if (enabled) {
                return new GoogleTagManager(
                    router,
                    injector.get<Document>(DOCUMENT),
                    injector.get<string | null>(GOOGLE_TAG_MANAGER_ID),
                    injector.get<string | null>(GOOGLE_TAG_MANAGER_AUTH),
                    injector.get<string | null>(GOOGLE_TAG_MANAGER_PREVIEW),
                );
            }

            return new NullGoogleAnalytics();
        },
        deps:       [Injector, Router],
    },
];
