/* eslint-disable prefer-destructuring,class-methods-use-this,angular/document-service */
import {
    Component,
    Type,
}                               from '@angular/core';
import { camelCaseToKebabCase } from '../../../../utils';

@Component({
    selector:  'app-page',
    styleUrls: ['page.component.scss'],
    template:  '<router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>',
})
export class PageComponent {

    private _body: HTMLBodyElement;

    public constructor() {
        this._body = document.querySelector('body');
    }

    public onRouterOutletActivate(type: Type<any>): void {
        let name: string = type.constructor.name;
        name             = name
            .replace('PageComponent', '')
            .replace('Component', '');
        name             = camelCaseToKebabCase(name);

        this._body.setAttribute('page', name);
    }

}
