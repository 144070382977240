import {
    Directive,
    Host,
    OnInit,
    ViewContainerRef,
    Optional,
    Self,
    ComponentFactoryResolver,
    ComponentFactory,
    ComponentRef,
    ElementRef,
    Renderer2,
    Input,
}                                    from '@angular/core';
import {
    FormControl,
    FormControlName,
    FormGroup,
}                                    from '@angular/forms';
import { FormErrorsTranslator }      from '@evermed/core';
import {
    UntilDestroy,
    untilDestroyed,
}                                    from '@ngneat/until-destroy';
import {
    merge,
    Observable,
    interval,
    Unsubscribable,
}                                    from 'rxjs';
import { debounce }                  from 'rxjs/operators';
import { FormControlErrorComponent } from '../../components';
import { FormSubmitDirective }       from './form-submit-directive.directive';

/**
 * For the sake of simplicity, we extract form control from "FormControlName" directive
 * for developer experience. If that causes an issue in the future, we might need
 * to define input and pass form control explicitly.
 */
@UntilDestroy()
@Directive({
    selector: '[evermedFormControlErrors]',
})
export class FormControlErrorsDirective implements OnInit {

    @Input('evermedFormControlErrors')
    public messages: { [key: string]: string } | null = null;

    @Input('evermedFormControlErrorsComponent')
    public errorComponent: FormControlErrorComponent = null;

    private readonly _componentFactoryResolver: ComponentFactoryResolver;

    private readonly _viewContainerRef: ViewContainerRef;

    private readonly _elemRef: ElementRef;

    private readonly _renderer: Renderer2;

    private readonly _translator: FormErrorsTranslator;

    private readonly _formControlNameDirective: FormControlName;

    private readonly _formSubmitDirective: FormSubmitDirective | null;

    private _subscription: Unsubscribable;

    private _control: FormControl;

    public constructor(
        componentFactoryResolver: ComponentFactoryResolver,
        viewContainerRef: ViewContainerRef,
        elemRef: ElementRef,
        renderer: Renderer2,
        translator: FormErrorsTranslator,
        @Self() formControlNameDirective: FormControlName,
        @Optional() @Host() formSubmitDirective?: FormSubmitDirective,
    ) {
        this._componentFactoryResolver = componentFactoryResolver;
        this._viewContainerRef         = viewContainerRef;
        this._elemRef                  = elemRef;
        this._renderer                 = renderer;
        this._translator               = translator;
        this._formControlNameDirective = formControlNameDirective;
        this._formSubmitDirective      = formSubmitDirective || null;
    }

    public ngOnInit(): void {
        if (!this._formSubmitDirective) {
            this._control = this._formControlNameDirective.control;
            this.createFormControlSubscription();
            return;
        }

        this._formSubmitDirective.submitted.pipe(untilDestroyed(this)).subscribe(this.onControlStateChange);
        this._formSubmitDirective.change.pipe(untilDestroyed(this)).subscribe(this.onFormReferenceChange);
    }

    private createFormControlSubscription(): void {
        this._subscription?.unsubscribe();

        let observables: Observable<any>[] = [
            this._control.valueChanges,
            this._control.statusChanges,
        ];

        this._subscription = merge(...observables)
            .pipe(untilDestroyed(this))
            .pipe(debounce(() => interval(500)))
            .subscribe(this.onControlStateChange);
    }

    private onFormReferenceChange = (formGroup: FormGroup | null): void => {
        this._subscription?.unsubscribe();

        if (null === formGroup) {
            return;
        }

        // TODO @TheCelavi should we have complex logic to extract form control?
        this._control = formGroup.get(this._formControlNameDirective.name as string) as FormControl;
        this.createFormControlSubscription();
    };

    // eslint-disable-next-line @typescript-eslint/typedef
    private onControlStateChange = async (): Promise<void> => {
        let clear: () => void = (): void => {
            this._renderer.removeClass(this._elemRef.nativeElement, 'is-invalid');
            if (null !== this.errorComponent) {
                this.errorComponent.error  = null;
                this.errorComponent.hidden = true;
                // This is dirty fix, see component class.
                this.errorComponent.ngOnChanges();
            }
        };

        if (!this._control.touched) {
            clear();
            return;
        }

        if (null === this._control.errors) {
            clear();
            return;
        }

        if (null === this.errorComponent) {
            // eslint-disable-next-line max-len
            let factory: ComponentFactory<FormControlErrorComponent> = this._componentFactoryResolver.resolveComponentFactory(FormControlErrorComponent);
            let component: ComponentRef<FormControlErrorComponent>   = this._viewContainerRef.createComponent(factory);
            this.errorComponent                                      = component.instance;
            this.errorComponent.error                                = null;
            this.errorComponent.hidden                               = true;
        }

        let firstKey: string                   = Object.keys(this._control.errors)[0];
        let params: { [key: string]: string }  = {};
        let details: { [key: string]: string } = this._control.getError(firstKey) || {};

        Object.keys(details).forEach((key: string): void => {
            if (undefined === details[key] || null === details[key]) {
                return;
            }

            params[key] = details[key].toString();
        });

        let error: string = await this._translator.getMessage(firstKey, params, this.messages || {});

        this._renderer.addClass(this._elemRef.nativeElement, 'is-invalid');
        this.errorComponent.error  = error;
        this.errorComponent.hidden = false;
        // This is dirty fix, see component class.
        this.errorComponent.ngOnChanges();
    };

}
