import { Type }              from '@angular/core';
import {
    ButtonGroupComponent,
    ButtonSubmitComponent,
    DownloadComponent,
}                            from './button';
import {
    CardComponent,
    CardImageComponent,
}                            from './card';
import {
    CheckboxComponent, ChoiceSelectComponent, CountrySelectComponent,
    DatePickerComponent, EnumSelectComponent,
    ExtendedSelectComponent,
    FormControlErrorComponent,
    FormErrorsComponent, ImagePickerComponent, SpecialitySelectComponent,
    ToggleComponent,
} from './form';
import { BookmarkComponent } from './impression';
import {
    PurchaseToAccessContentAdvisoryComponent,
    RegisterToAccessContentAdvisoryComponent,
    VideoPreviewWatermarkComponent,
}                            from './inaccessible';
import {
    InterestsCarouselsRendererComponent,
    InterestsLabelsComponent,
}                            from './interests';
import {
    PageSpinnerComponent,
    WidgetSpinnerComponent,
    InfiniteScrollComponent,
    InfiniteScrollSpinnerComponent,
    InfiniteScrollContainerComponent,
}                            from './loader';
import {
    DocumentViewerModalComponent,
    ModalComponent,
    RedeemAccessCodeModalComponent,
    UserConsentModalComponent,
}                            from './modal';
import {
    LiveStreamPlayerComponent,
    VideoOnDemandPlayerComponent,
    VideoOnDemandPreviewPlayerComponent,
}                            from './video-player';

import {
    PdfViewerComponent,
    PdfViewerAdapterComponent,
}                                  from './pdf-viewer';
import { AvatarComponent }         from './avatar/avatar.component';
import {
    CarouselComponent,
    GridComponent,
    PartialCarouselComponent,
}                                  from './collection';
import { ExpirationDateComponent } from './expiration-date/expiration-date.component';
import { PageComponent }           from './page/page.component';
import { ReCaptchaComponent }      from './form/re-captcha/re-captcha.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';

export * from './button';
export * from './card';
export * from './collection';
export * from './form';
export * from './impression';
export * from './inaccessible';
export * from './interests';
export * from './modal';
export * from './video-player';
export * from './loader';

export { PageComponent }           from './page/page.component';
export { AvatarComponent }         from './avatar/avatar.component';
export { ExpirationDateComponent } from './expiration-date/expiration-date.component';
export { PdfViewerComponent }      from './pdf-viewer/pdf-viewer.component';
export { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';

export const EVERMED_ENCAPSULATED_COMPONENTS: Type<any>[] = [
    InterestsCarouselsRendererComponent,
];

export const EVERMED_SHARED_COMPONENTS: Type<any>[] = [
    PageComponent,
    ExtendedSelectComponent,
    CarouselComponent,
    GridComponent,
    PartialCarouselComponent,
    FormErrorsComponent,
    FormControlErrorComponent,
    AvatarComponent,
    InfiniteScrollSpinnerComponent,
    DatePickerComponent,
    DownloadComponent,
    LiveStreamPlayerComponent,
    ButtonGroupComponent,
    ButtonSubmitComponent,
    ExpirationDateComponent,
    CardImageComponent,
    CardComponent,
    InfiniteScrollComponent,
    InfiniteScrollContainerComponent,
    VideoOnDemandPlayerComponent,
    VideoOnDemandPreviewPlayerComponent,
    ModalComponent,
    RedeemAccessCodeModalComponent,
    CheckboxComponent,
    DocumentViewerModalComponent,
    PdfViewerComponent,
    PdfViewerAdapterComponent,
    PageSpinnerComponent,
    WidgetSpinnerComponent,
    ToggleComponent,
    RegisterToAccessContentAdvisoryComponent,
    PurchaseToAccessContentAdvisoryComponent,
    BookmarkComponent,
    InterestsLabelsComponent,
    UserConsentModalComponent,
    ReCaptchaComponent,
    VideoPreviewWatermarkComponent,
    CountdownTimerComponent,
    CountrySelectComponent,
    ChoiceSelectComponent,
    SpecialitySelectComponent,
    ImagePickerComponent,
    EnumSelectComponent,
];
