import { Component }   from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector:    'app-footer',
    templateUrl: './footer.component.html',
    styleUrls:   ['./footer.component.scss'],
})
export class FooterComponent {

    public currentDate: Date = new Date();

    public chineseMarket: boolean = environment.chineseMarket;

    public icpLicenceNo?: string = environment.icpLicenceNo;

}
