import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Input,
    ViewChild,
}                       from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
}                       from '@angular/forms';
import {
    Country,
    getCountries,
}                       from './country';

/* eslint-disable no-multi-spaces */
@Component({
    selector:        'app-country-select',
    templateUrl:     './country-select.component.html',
    styleUrls:       ['./country-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers:       [
        {
            // eslint-disable-next-line no-use-before-define
            useExisting: forwardRef(() => CountrySelectComponent),
            provide:     NG_VALUE_ACCESSOR,
            multi:       true,
        },
    ],
})
export class CountrySelectComponent implements ControlValueAccessor, AfterViewInit {

    @Input()
    public class: string;

    @Input()
    public placeholder: string;

    @Input()
    public disabled: boolean = false;

    @Input()
    public multiple: boolean = false;

    public readonly countries: Country[] = getCountries();

    public value: string | string[];

    @ViewChild('select')
    private readonly _select: ElementRef;

    private readonly _hostElement: ElementRef;

    private readonly _cdr: ChangeDetectorRef;

    private _onChangeHandler: (value: any) => {};

    private _onTouchedHandler: () => {};

    public constructor(
        hostElement: ElementRef,
        cdr: ChangeDetectorRef,
    ) {
        this._hostElement = hostElement;
        this._cdr         = cdr;
    }

    public ngAfterViewInit(): void {
        this._hostElement.nativeElement.classList.remove(this.class);
    }

    public isEmpty(): boolean {
        if (this.multiple) {
            return !this.value || this.value.length === 0;
        }

        return !this.value;
    }

    public isSelected(value: string): boolean {
        let values: string[] = <string[]>(this.multiple ? this.value : [this.value]
        );
        return -1 !== values.indexOf(value);
    }

    public onChange(): void {
        this.value = this._select.nativeElement.value;
        this._cdr.markForCheck();
        this.notify();
    }

    public registerOnChange(fn: any): void {
        this._onChangeHandler = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouchedHandler = fn;
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public writeValue(obj: string | string[]): void {
        this.value = obj;
        this._cdr.markForCheck();
    }

    private notify(): void {
        if (!this._onChangeHandler) {
            return;
        }

        this._onChangeHandler(this.value);
        this._onTouchedHandler();
    }

}
