// eslint-disable-next-line import/no-extraneous-dependencies
import { NgxsReduxDevtoolsPluginModule }  from '@ngxs/devtools-plugin';
import { BrowserModule }                  from '@angular/platform-browser';
import { BrowserAnimationsModule }        from '@angular/platform-browser/animations';
import { NgModule }                       from '@angular/core';
import { RouterModule }                   from '@angular/router';
import {
    TranslateModule,
    TranslateLoader,
}                                         from '@ngx-translate/core';
import { NgxsFormPluginModule }           from '@ngxs/form-plugin';
import { NgxsModule }                     from '@ngxs/store';
import { NgxsStoragePluginModule }        from '@ngxs/storage-plugin';
// import { NgxsWebsocketPluginModule }      from '@ngxs/websocket-plugin';
import {
    TranslateHttpLoader,
    CoreModule,
    EVERMED_API_ENDPOINT,
    EVERMED_LOGIN_PAGE_PATH,
    EVERMED_PURCHASE_PAGE_PATH,
    EVERMED_DASHBOARD_PAGE_PATH,
    CDN_FILTERS,
    APPLICATION_LOCALES,
    APPLICATION_DEFAULT_LOCALE,
    GOOGLE_RE_CAPTCHA_ENABLED,
    GOOGLE_RE_CAPTCHA_SITE_KEY,
}                                         from '@evermed/core';
import { HttpClient }                     from '@runopencode/http';
import {
    GOOGLE_TAG_MANAGER_AUTH,
    GOOGLE_TAG_MANAGER_PREVIEW,
    SharedModule,
    GOOGLE_ANALYTICS_ENABLED,
    GOOGLE_ANALYTICS_ID,
    GOOGLE_TAG_MANAGER_ENABLED,
    GOOGLE_TAG_MANAGER_ID,
}                                         from '../shared';
import { AppComponent }                   from './app.component';
import { AppRoutingModule }               from './app-routing.module';
import { environment }                    from '../../environments/environment';
import { APPLICATION_BOOTSTRAPPERS }      from './bootstrappers';
import { EVERMED_APPLICATION_COMPONENTS } from './components';
import { APPLICATION_INITIALIZERS }       from './initializers';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): any {
    // @ts-ignore
    return new TranslateHttpLoader(http) as any;
}

@NgModule({
    imports:      [
        // @see https://github.com/ngxs/store/issues/375
        // even though they claim it is fixed, it is not.
        // keep ngxs loaded first.
        NgxsModule.forRoot([]),
        NgxsStoragePluginModule.forRoot({
            key: ['auth.token', 'auth.id', 'auth.refreshToken', 'locale.current'],
        }),
        // NgxsWebsocketPluginModule.forRoot({
        //     url:          environment.webSocketEndpoint,
        //     deserializer: (e: MessageEvent) => ({
        //         type:    '[Websocket] Message received',
        //         payload: JSON.parse(e.data),
        //     } as object),
        // }),
        NgxsFormPluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production,
        }),
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule,
        CoreModule,
        // Modules that we need to load for root
        TranslateModule.forRoot({
            loader: {
                provide:    TranslateLoader,
                deps:       [HttpClient],
                useFactory: HttpLoaderFactory,
            },
        }),
        // App routing module must be loaded first, because all routes are lazy.
        AppRoutingModule,
        // Project modules
        SharedModule,
    ],
    declarations: [
        AppComponent,
        ...EVERMED_APPLICATION_COMPONENTS,
    ],
    providers:    [
        {
            provide:  GOOGLE_RE_CAPTCHA_ENABLED,
            useValue: environment.googleReCaptchaEnabled,
        },
        {
            provide:  GOOGLE_RE_CAPTCHA_SITE_KEY,
            useValue: environment.googleReCaptchaSiteKey,
        },
        {
            provide:  GOOGLE_TAG_MANAGER_PREVIEW,
            useValue: environment.googleTagManagerPreview,
        },
        {
            provide:  GOOGLE_TAG_MANAGER_AUTH,
            useValue: environment.googleTagManagerAuth,
        },
        {
            provide:  GOOGLE_TAG_MANAGER_ENABLED,
            useValue: environment.googleTagManagerEnabled,
        },
        {
            provide:  GOOGLE_TAG_MANAGER_ID,
            useValue: environment.googleTagManagerId,
        },
        {
            provide:  GOOGLE_ANALYTICS_ENABLED,
            useValue: environment.googleAnalyticsEnabled,
        },
        {
            provide:  GOOGLE_ANALYTICS_ID,
            useValue: environment.googleAnalyticsId,
        },
        {
            provide:  EVERMED_API_ENDPOINT,
            useValue: environment.endpoint,
        },
        {
            provide:  CDN_FILTERS,
            useValue: environment.cdnFilters,
        },
        {
            provide:  EVERMED_LOGIN_PAGE_PATH,
            useValue: '/login',
        },
        {
            provide:  EVERMED_PURCHASE_PAGE_PATH,
            useValue: '/conferences/all',
        },
        {
            provide:  EVERMED_DASHBOARD_PAGE_PATH,
            useValue: '/events',
        },
        {
            provide:  APPLICATION_LOCALES,
            useValue: environment.locales,
        },
        {
            provide:  APPLICATION_DEFAULT_LOCALE,
            useValue: 'en',
        },
        ...APPLICATION_INITIALIZERS,
        ...APPLICATION_BOOTSTRAPPERS,
    ],
    bootstrap:    [AppComponent],
})
export class AppModule {
}
