import {
    ChangeDetectionStrategy,
    Component,
    Input,
}                                      from '@angular/core';
import { WaitlistApplicableInterface } from '@evermed/core';

@Component({
    selector:        'app-purchase-to-access-content-advisory',
    templateUrl:     './purchase-to-access-content-advisory.component.html',
    styleUrls:       ['./purchase-to-access-content-advisory.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseToAccessContentAdvisoryComponent {

    @Input()
    public subject: WaitlistApplicableInterface<any>;

}
