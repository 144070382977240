import {
    Pipe,
    PipeTransform,
} from '@angular/core';

@Pipe({
    name: 'stripTags',
})
export class StripTagsPipe implements PipeTransform {

    public transform(value: string): string {
        let text: string = value ? value.trim() : '';

        if ('' === text) {
            return value;
        }

        let container: HTMLElement = new DOMParser().parseFromString(text, 'text/html').body;
        let isHtml: boolean        = null !== container.firstElementChild;

        return isHtml ? container.innerText : text;
    }
}
