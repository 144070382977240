import {
    ComponentRef,
    Injector,
}                       from '@angular/core';
import {
    GOOGLE_ANALYTICS,
    GoogleAnalyticsInterface,
}                       from '../../shared';
import { AppComponent } from '../app.component';

export function bootstrapGoogleAnalytics(injector: Injector): (ref: ComponentRef<any>) => void {

    return (ref: ComponentRef<any>): void => {

        if (!(ref.instance instanceof AppComponent)) {
            return;
        }

        let googleAnalytics: GoogleAnalyticsInterface = injector.get<GoogleAnalyticsInterface>(GOOGLE_ANALYTICS);
        googleAnalytics.init();
    };

}
