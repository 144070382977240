import { InjectionToken } from '@angular/core';

export interface GoogleTagManagerInterface {

    init(): void;

}

export const GOOGLE_TAG_MANAGER: InjectionToken<GoogleTagManagerInterface> = new InjectionToken<GoogleTagManagerInterface>('GOOGLE_TAG_MANAGER');

export const GOOGLE_TAG_MANAGER_ENABLED: InjectionToken<string> = new InjectionToken<string>('GOOGLE_TAG_MANAGER_ENABLED');

export const GOOGLE_TAG_MANAGER_ID: InjectionToken<string> = new InjectionToken<string>('GOOGLE_TAG_MANAGER_ID');

export const GOOGLE_TAG_MANAGER_AUTH: InjectionToken<string> = new InjectionToken<string>('GOOGLE_TAG_MANAGER_AUTH');

export const GOOGLE_TAG_MANAGER_PREVIEW: InjectionToken<string> = new InjectionToken<string>('GOOGLE_TAG_MANAGER_PREVIEW');
