import { DOCUMENT }         from '@angular/common';
import {
    ChangeDetectorRef,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    ViewContainerRef,
} from '@angular/core';
import {
    FileReferenceInterface,
    FileDownloader,
    NotExistsError,
    UnauthorizedError,
}                           from '@evermed/core';
import {
    UntilDestroy,
    untilDestroyed,
}                           from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
    fromEvent,
    interval,
    Observable,
}                           from 'rxjs';
import {
    filter,
    throttle,
}                           from 'rxjs/operators';
import {
    DocumentViewerModalComponent,
    WidgetSpinnerComponent,
}                           from '../../components';
import {
    ModalController,
    ModalReference,
    Toast,
}                           from '../../services';

@UntilDestroy()
@Directive({
    selector: '[evermedDownloadFile]',
})
export class DownloadFileDirective implements OnInit {

    @Input('evermedDownloadFile')
    public reference: FileReferenceInterface;

    @Input()
    public disabled: boolean = false;

    @Output()
    public readonly busy: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    public readonly error: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public readonly disabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    private _evermedSpinner: ComponentRef<WidgetSpinnerComponent> = null;

    private _busy: boolean = false;

    private readonly _elementRef: ElementRef;

    private readonly _toast: Toast;

    private readonly _translator: TranslateService;

    private readonly _document: Document;

    private readonly _modalController: ModalController;

    private readonly _downloader: FileDownloader;

    private readonly _componentFactoryResolver: ComponentFactoryResolver;

    private readonly _viewContainerRef: ViewContainerRef;

    private readonly _cdr: ChangeDetectorRef;

    public constructor(
        elementRef: ElementRef,
        toast: Toast,
        translator: TranslateService,
        @Inject(DOCUMENT) document: Document,
        modalController: ModalController,
        downloader: FileDownloader,
        componentFactoryResolver: ComponentFactoryResolver,
        viewContainerRef: ViewContainerRef,
        cdr: ChangeDetectorRef,
    ) {
        this._elementRef               = elementRef;
        this._toast                    = toast;
        this._translator               = translator;
        this._document                 = document;
        this._modalController          = modalController;
        this._downloader               = downloader;
        this._componentFactoryResolver = componentFactoryResolver;
        this._viewContainerRef         = viewContainerRef;
        this._cdr                      = cdr;
    }

    public ngOnInit(): void {

        fromEvent<Event>(this._elementRef.nativeElement, 'click')
            .pipe(untilDestroyed(this))
            .pipe(filter((): boolean => !this.disabled && !this._busy))
            .pipe(throttle((): Observable<number> => interval(500)))
            .subscribe(this.onClick.bind(this));
    }

    public async onClick(event: Event): Promise<void> {

        if (this.disabled || this._busy) {
            return;
        }

        this._busy = true;
        this.busy.emit(true);
        this.renderSpinner();

        if ('A' === this._elementRef.nativeElement.tagName) {
            event.preventDefault();
        }

        try {
            if (!this.reference.readonly) {
                await this.download();
                return;
            }

            await this.preview();
        } finally {
            this._busy = false;
            this.busy.emit(false);
            this._evermedSpinner.location.nativeElement.style.display = 'none';
            this._elementRef.nativeElement.disabled = false;
        }
    }

    private renderSpinner(): void {

        if (null === this._evermedSpinner) {
            let factory: ComponentFactory<WidgetSpinnerComponent> = this._componentFactoryResolver.resolveComponentFactory(WidgetSpinnerComponent);
            this._evermedSpinner   = this._viewContainerRef.createComponent(factory, 0);
            this._evermedSpinner.instance.inverse                          = true;
            this._evermedSpinner.instance.size                             = 'xs';
            // When added like this, component is out of Angular DOM tree and we can't change its properties after appending it.
            this._elementRef.nativeElement.appendChild(this._evermedSpinner.location.nativeElement);

        }
        this._evermedSpinner.location.nativeElement.style.display = 'inline';
        this._elementRef.nativeElement.disabled = true;
        this._cdr.markForCheck();
    }

    private async download(): Promise<void> {
        let data: Blob;

        try {
            data = await this._downloader.download(this.reference);
        } catch (e) {
            if (e instanceof NotExistsError) {
                let key: string     = 'We are so sorry, but this file can not be accessed anymore.';
                let message: string = await this._translator.get(key).toPromise();
                this.disabled       = true;

                this.disabledChange.emit(true);
                this._toast.error(message).then(/* noop */);
                this.error.emit(message);
                return;
            }

            if (e instanceof UnauthorizedError) {
                let key: string     = 'We are so sorry, but you do not have sufficient privileges to download this file.';
                let message: string = await this._translator.get(key).toPromise();
                this.disabled       = true;

                this.disabledChange.emit(true);
                this.error.emit(message);
                this._toast.error(message).then(/* noop */);
                return;
            }

            throw e;
        }

        let link: string                 = URL.createObjectURL(data);
        let linkElement: HTMLLinkElement = this._document.createElement('A') as HTMLLinkElement;
        linkElement.href                 = link;
        linkElement.setAttribute('download', this.reference.file.name);
        this._document.body.appendChild(linkElement);
        linkElement.click();
        this._document.body.removeChild(linkElement);

        URL.revokeObjectURL(link);
    }

    private async preview(): Promise<void> {
        let modal: ModalReference<DocumentViewerModalComponent> = await this._modalController.create(DocumentViewerModalComponent, {
            cssClass: 'document-viewer-modal',
            title:    'View document',
            size:     'xl',
            inputs:   {
                reference: this.reference,
            },
        });

        await modal.dismissed$.toPromise();
    }

}
