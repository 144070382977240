import { Injectable } from '@angular/core';
import { Router }     from '@angular/router';
import {
    Actions,
    ofActionDispatched,
}                     from '@ngxs/store';
import {
    PurchaseSuccessful,
    PurchaseUnsuccessful,
    AbstractProduct,
    ProductPurchase,
    EventsBundleProduct,
    SingleEventProduct,
    BundledEvent,
    AccessCodeViolationType,
}                     from '@evermed/core';
import { Toast }      from '../toast';

/**
 * This class resolves successful/unsuccessful purchases within application.
 *
 * It displays proper success/error messages and redirects user to appropriate
 * page when purchase is executed.
 */
@Injectable()
export class PurchaseResolver {

    private readonly _actions: Actions;

    private readonly _toast: Toast;

    private readonly _router: Router;

    public constructor(
        actions: Actions,
        toast: Toast,
        router: Router,
    ) {
        this._toast   = toast;
        this._router  = router;
        this._actions = actions;
    }

    public listen(): void {
        this._actions.pipe(ofActionDispatched(PurchaseSuccessful)).subscribe(this.onPurchaseSuccessful.bind(this));
        this._actions.pipe(ofActionDispatched(PurchaseUnsuccessful)).subscribe(this.onPurchaseUnsuccessful.bind(this));
    }

    private onPurchaseSuccessful(event: PurchaseSuccessful): void {
        let purchase: ProductPurchase = event.purchase;
        let product: AbstractProduct  = purchase.product;

        if (product instanceof SingleEventProduct) {
            this.resolveSingleEventProductPurchase(product).then(/* noop */);
            return;
        }

        if (product instanceof EventsBundleProduct) {
            this.resolveEventsBundleProductPurchase(product).then(/* noop */);
            return;
        }

        throw new Error(`Unknown product instance provided "${product.constructor.name}"`);
    }

    private async onPurchaseUnsuccessful(event: PurchaseUnsuccessful): Promise<void> {
        await this._router.navigate(['/']);
        switch (event.decision.type) {
            case AccessCodeViolationType.COUNTRY:
                this._toast.warning('product.redeem.toast.fail.country.message', null, {
                    title: 'product.redeem.toast.fail.country.title',
                }).then(/* noop */);
                return;
            case AccessCodeViolationType.INVALID:
                this._toast.error('product.redeem.toast.fail.invalid.message', null, {
                    title: 'product.redeem.toast.fail.invalid.title',
                }).then(/* noop */);
                return;
            case AccessCodeViolationType.TOTAL:
                this._toast.warning('product.redeem.toast.fail.total.message', null, {
                    title: 'product.redeem.toast.fail.total.title',
                }).then(/* noop */);
                return;
            case AccessCodeViolationType.USED:
                this._toast.error('product.redeem.toast.fail.used.message', null, {
                    title: 'product.redeem.toast.fail.used.title',
                }).then(/* noop */);
                return;
            case AccessCodeViolationType.FORBIDDEN:
                this._toast.error('product.redeem.toast.fail.forbidden.message', null, {
                    title: 'product.redeem.toast.fail.forbidden.title',
                }).then(/* noop */);
                return;
            default:
                throw new Error(`Unknown violation type "${event.decision.type}" provided.`);
        }
    }

    private async resolveSingleEventProductPurchase(product: SingleEventProduct): Promise<void> {
        if (product.event.releaseDate <= new Date()) {
            await this._router.navigate(['/events', product.event.id]);
            this._toast.success('product.redeem.toast.success.singleEvent.message', {
                product: product.name,
            }, {
                title: 'product.redeem.toast.success.singleEvent.title',
            }).then(/* noop */);
            return;
        }

        await this._router.navigate(['/']);
        this._toast.success('product.redeem.toast.unreleased.message', {
            product: product.name,
        }, {
            title: 'product.redeem.toast.unreleased.title',
        }).then(/* noop */);
    }

    private async resolveEventsBundleProductPurchase(product: EventsBundleProduct): Promise<void> {
        let now: Date                       = new Date();
        let events: BundledEvent[]          = product.events;
        let released: BundledEvent[]        = events.filter((current: BundledEvent): boolean => current.releaseDate <= now);
        let unreleased: BundledEvent | null = events.find((current: BundledEvent): boolean => current.releaseDate > now) || null;

        // we have mixed content access, booth released and unreleased
        if (0 !== released.length && null !== unreleased) {
            // If only one event is released, redirect there. Otherwise, redirect to dashboard.
            let command: string[] = 1 === released.length ? ['/events', released[0].id] : ['/'];
            await this._router.navigate(command);

            this._toast.success('product.redeem.toast.success.releasedAndUnreleased.message', {
                product: product.name,
            }, {
                title: 'product.redeem.toast.success.releasedAndUnreleased.title',
            }).then(/* noop */);
            return;
        }

        // nothing is released
        if (0 === released.length) {
            await this._router.navigate(['/']);
            this._toast.success('product.redeem.toast.unreleased.message', {
                product: product.name,
            }, {
                title: 'product.redeem.toast.unreleased.title',
            }).then(/* noop */);
            return;
        }

        // everything is released
        await this._router.navigate(['/']);
        this._toast.success('product.redeem.toast.success.eventsBundle.message', {
            product: product.name,
        }, {
            title: 'product.redeem.toast.success.eventsBundle.title',
        }).then(/* noop */);
    }

}
