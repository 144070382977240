import { DOCUMENT }                    from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
}                                      from '@angular/core';
import {
    IdentifiableInterface,
    timeout,
}                                      from '@evermed/core';
import {
    UntilDestroy,
    untilDestroyed,
}                                      from '@ngneat/until-destroy';
import { AbstractCollectionComponent } from '../abstract-collection-component';
import { CarouselEngine }              from './engine';
// noinspection ES6PreferShortImport - short import creates circular dependency
import { EqualizeDirective }           from '../../../directives/equalize/equalize.directive';

@UntilDestroy()
@Component({
    selector:        'app-carousel',
    templateUrl:     './carousel.component.html',
    styleUrls:       ['./carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent extends AbstractCollectionComponent implements AfterViewInit, OnDestroy {

    /**
     * A custom responsive breakpoints. Key of the object are
     * maximum container widths (NOT WINDOW widths) and values are
     * number of items to display.
     *
     * See default configuration in file: "engine/carousel-configuration.interface.ts"
     */
    @Input()
    public breakpoints: { [size: number]: number } = null;

    /**
     * Which reference point should be used in order to determine breakpoint and width.
     */
    @Input()
    public reference: 'window' | 'container' = 'window';

    /**
     * You may limit a max number of element which should be rendered
     * within this collection, regardless of the total number of items
     * within collection
     */
    @Input()
    public max: number | null = 8;

    /**
     * Notify if carousel sliced provided collection and rendered
     * only a portion of it.
     */
    @Output()
    public readonly sliced: EventEmitter<boolean> = new EventEmitter<boolean>();

    public slideable: boolean = false;

    public slideablePrevious: boolean = false;

    public slideableNext: boolean = false;

    public draggable: boolean = false;

    public perPage: number = 1;

    public slide: number = 0;

    public get renderable(): IdentifiableInterface[] {
        let renderable: IdentifiableInterface[] = Array.from(this.items);

        if (null === this.max) {
            this.sliced.emit(false);
            return renderable;
        }

        let sliced: IdentifiableInterface[] = renderable.slice(0, this.max);
        this.sliced.emit(sliced.length !== renderable.length);

        return sliced;
    }

    public get pagination(): number {
        return this._slides.length - this.perPage + 1;
    }

    @ViewChild('stage')
    private readonly _stage: ElementRef;

    @ViewChildren('slides')
    private readonly _slides: QueryList<HTMLElement>;

    @ViewChild(EqualizeDirective)
    private readonly _equalizer: EqualizeDirective;

    private readonly _cdr: ChangeDetectorRef;

    private readonly _document: Document;

    private _engine: CarouselEngine;

    public constructor(
        cdr: ChangeDetectorRef,
        @Inject(DOCUMENT) document: Document,
    ) {
        super(cdr);
        this._cdr      = cdr;
        this._document = document;
    }

    public async ngAfterViewInit(): Promise<void> {
        this._engine = new CarouselEngine(this._stage.nativeElement, this._document, {
            breakpoints:  this.breakpoints,
            reference:    this.reference,
            beforeRender: (): Promise<void> => this._equalizer.equalize(),
        });

        this._slides.changes.pipe(untilDestroyed(this)).subscribe(async (): Promise<void> => {
            this._engine.rebuild().then(/* noop */);
        });
        this._engine.draggable$.pipe(untilDestroyed(this)).subscribe((draggable: boolean): void => {
            this.draggable = draggable;
            this._cdr.markForCheck();
        });
        this._engine.slideable$.pipe(untilDestroyed(this)).subscribe((slideable: boolean): void => {
            this.slideable = slideable;
            this._cdr.markForCheck();
        });
        this._engine.slideablePrevious$.pipe(untilDestroyed(this)).subscribe((slideablePrevious: boolean): void => {
            this.slideablePrevious = slideablePrevious;
            this._cdr.markForCheck();
        });
        this._engine.slideableNext$.pipe(untilDestroyed(this)).subscribe((slideableNext: boolean): void => {
            this.slideableNext = slideableNext;
            this._cdr.markForCheck();
        });
        this._engine.perPage$.pipe(untilDestroyed(this)).subscribe((perPage: number): void => {
            this.perPage = perPage;
            this._cdr.markForCheck();
        });
        this._engine.slide$.pipe(untilDestroyed(this)).subscribe((slide: number): void => {
            this.slide = slide;
            this._cdr.markForCheck();
        });

        // Little bit a hackish solution, but seams that on initialization
        // only when we invoke this twice, we get proper results.
        await timeout(200);
        await this._engine.rebuild();
    }

    public next(): void {
        if (!this.slideableNext) {
            return;
        }

        this._engine.next();
    }

    public previous(): void {
        if (!this.slideablePrevious) {
            return;
        }

        this._engine.previous();
    }

    public goto(index: number): void {
        if (!this.slideable) {
            return;
        }

        this._engine.goto(index);
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this._engine.destroy();
    }

}
