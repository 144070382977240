import {
    ChangeDetectorRef,
    Directive,
    Input,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { UntilDestroy }                  from '@ngneat/until-destroy';
import { WindowWidthObservable }         from '../../services';
import { AbstractResponsiveIfDirective } from './abstract-responsive-if.directive';

@UntilDestroy()
@Directive({
    selector: '[evermedVisibleIf]',
})
export class VisibleIfDirective extends AbstractResponsiveIfDirective {

    @Input()
    public set evermedVisibleIf(breakpoints: string | string[] | [number, number] | Array<[number, number]>) {
        super.setBreakpoints(breakpoints);
    }

    public constructor(
        templateRef: TemplateRef<any>,
        viewContainer: ViewContainerRef,
        resize: WindowWidthObservable,
        cdr: ChangeDetectorRef,
    ) {
        super(templateRef, viewContainer, resize, cdr);
    }

    protected recalculate(): void {
        if (0 === this._breakpoints.length || null === this._width) {
            this.hide();
            return;
        }

        let breakpoint: [number, number] = this._breakpoints.find((value: [number, number]): boolean => {
            return value[0] <= this._width && value[1] >= this._width;
        }) || null;

        if (null === breakpoint) {
            this.hide();
            return;
        }

        this.show();
    }

}
