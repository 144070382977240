import {
    Component,
    Input,
} from '@angular/core';

@Component({
    selector:    'app-infinite-scroll-spinner',
    templateUrl: './infinite-scroll-spinner.component.html',
    styleUrls:   ['./infinite-scroll-spinner.component.scss'],
})
/**
 * @deprecated
 */
export class InfiniteScrollSpinnerComponent {

    @Input()
    public inverse: boolean = false;

}
