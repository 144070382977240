import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
}                 from '@angular/core';
import { Router } from '@angular/router';
import {
    WaitlistApplicableInterface,
    ApplyForWaitlist,
    UnauthenticatedError,
    UnauthorizedError,
    isIdentifiable,
}                 from '@evermed/core';
import { Store }  from '@ngxs/store';
import { Toast }  from '../../services';

@Directive({
    selector: '[evermedApplyForWaitlist]',
})
export class ApplyForWaitlistDirective implements OnInit {

    @Input('evermedApplyForWaitlist')
    public subject: WaitlistApplicableInterface<any>;

    @Output('evermedWaitlistApplied')
    public applied: EventEmitter<void> = new EventEmitter<void>();

    private readonly _store: Store;

    private readonly _toast: Toast;

    private readonly _router: Router;

    private readonly _element: HTMLElement;

    public constructor(
        store: Store,
        toast: Toast,
        router: Router,
        element: ElementRef,
    ) {
        this._store   = store;
        this._toast   = toast;
        this._router  = router;
        this._element = element.nativeElement;
    }

    public ngOnInit(): void {
        if (!isIdentifiable(this.subject)) {
            throw new Error('Directive "evermedApplyForWaitlist" expects to provide instance of IdentifiableInterface.');
        }
    }

    // TODO: Optimize this, this is not only source of determining if action should be allowed
    @HostListener('click')
    public async onClick(): Promise<void> {
        this._element.classList.add('disabled');
        if (this._element instanceof HTMLButtonElement) {
            this._element.disabled = true;
        }

        try {
            await this._store.dispatch(new ApplyForWaitlist(this.subject)).toPromise();
            this.applied.emit();
            this._toast.success(
                'waitlist.toast.success.message',
                null,
                {
                    title: 'waitlist.toast.success.title',
                },
            ).then(/* noop */);
        } catch (e) {
            if (e instanceof UnauthorizedError) {
                this._toast.warning(
                    'waitlist.toast.unauthenticated.message',
                    null,
                    {
                        title: 'waitlist.toast.unauthenticated.title',
                    },
                ).then(/* noop */);
                this.applied.emit();
                return;
            }

            if (e instanceof UnauthenticatedError) {
                await this._router.navigate(['/login']);
                this._toast.warning(
                    'waitlist.toast.unauthenticated.message',
                    null,
                    {
                        title: 'waitlist.toast.unauthenticated.title',
                    },
                ).then(/* noop */);
                return;
            }

            throw e;
        } finally {
            this._element.classList.remove('disabled');
            if (this._element instanceof HTMLButtonElement) {
                this._element.disabled = false;
            }
        }
    }

}
