import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
}                               from '@angular/core';
import { FormGroup }            from '@angular/forms';
import { FormErrorsTranslator } from '@evermed/core';
import {
    UntilDestroy,
    untilDestroyed,
}                               from '@ngneat/until-destroy';
import {
    interval,
    Unsubscribable,
}                               from 'rxjs';
import { debounce }             from 'rxjs/operators';

@UntilDestroy()
@Component({
    selector:        'app-form-errors',
    templateUrl:     './form-errors.component.html',
    styleUrls:       ['./form-errors.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormErrorsComponent implements OnChanges {

    @Input()
    public form: FormGroup = null;

    @Input()
    public messages: { [key: string]: string } = null;

    @Input()
    public set errors(value: string | string[]) {
        if (Array.isArray(value)) {
            this._errors = value;
        }

        this._errors = (value ? [value] : []) as string[];

        if (0 === this._errors.length) {
            this.hidden = true;
        }
    }

    public get errors(): string | string[] {
        return this._errors;
    }

    @Input()
    public hidden: boolean = true;

    private _errors: string[] = [];

    private _subscription: Unsubscribable;

    private readonly _translator: FormErrorsTranslator;

    private readonly _cdr: ChangeDetectorRef;

    public constructor(
        translator: FormErrorsTranslator,
        cdr: ChangeDetectorRef,
    ) {
        this._translator = translator;
        this._cdr        = cdr;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!changes.form) {
            return;
        }

        this._subscription?.unsubscribe();

        if (null === this.form) {
            this._errors = []
            this.hidden  = true;
            this._cdr.markForCheck();
            return;
        }

        this._subscription = this.form.statusChanges.pipe(
            untilDestroyed(this),
            debounce(() => interval(300)),
        ).subscribe(this.onChange)
    }

    // eslint-disable-next-line @typescript-eslint/typedef
    private onChange = async (): Promise<void> => {
        let clear: () => void = (): void => {
            this.errors = [];
            this.hidden = true;
            this._cdr.markForCheck();
        };

        if (!this.form.touched) {
            clear();
            return;
        }

        if (null === this.form.errors) {
            clear();
            return;
        }

        let translations: Promise<string>[] = [];

        Object.keys(this.form.errors).forEach((err: string): void => {
            let params: { [key: string]: string } = {};
            let details: { [key: string]: any }   = this.form.getError(err) || {};

            // we need to handle special case first, it is possible that
            // we got a server message that we are handling as a key for
            // translation. In that case, "err" will be "server" and
            // "details" will have parameter "message". If we have translation
            // for key provided within details, we should use that first,
            // otherwise, we should fallback to usual translation process.
            if ('server' === err && 1 === Object.keys(details).length && details.message && this.messages && this.messages[details.message]) {
                // eslint-disable-next-line no-param-reassign
                err = details.message;
            }

            Object.keys(details).forEach((key: string): void => {
                if (undefined === details[key] || null === details[key]) {
                    return;
                }

                params[key] = details[key].toString();
            });

            let translation: Promise<string> = this._translator.getMessage(err, params, this.messages || {});

            translations.push(translation);
        });

        this.errors = await Promise.all(translations);

        this.hidden = false;
        this._cdr.markForCheck();
    };

}
