import {
    Comment,
    Node,
} from '@angular/compiler';
import {
    Pipe,
    PipeTransform,
} from '@angular/core';

/*
  This pipe truncates a string.
  Use it like so {{ String expression | truncate:10 }}
  This truncates the string to 10 letters and adds '...' to end.
*/
@Pipe({
    name: 'truncate',
})
export class TruncatePipe implements PipeTransform {

    private static readonly REMAINS = '...';

    // eslint-disable-next-line class-methods-use-this
    public transform(value: string, limit: number, stripTags: boolean = false, preserveWords: boolean = true): string {
        let text: string = value ? value.trim() : '';

        if ('' === text) {
            return value;
        }

        let container: HTMLElement = new DOMParser().parseFromString(text, 'text/html').body;
        let isHtml: boolean        = null !== container.firstElementChild;

        // if we have to strip tags, or content is not a HTML code,
        // we can just work with simple text, no issues there.
        if (stripTags || !isHtml) {
            text = container.innerText.trim();

            if (text.length <= limit) {
                return text;
            }

            if (!preserveWords) {
                return `${text.slice(0, limit)}${TruncatePipe.REMAINS}`;
            }

            return `${text.substr(0, text.lastIndexOf(' ', limit))}${TruncatePipe.REMAINS}`;
        }

        // we need to keep tags so we need to determine when to stop
        // lets traverse nodes, count words/literals and see with backtracking
        // when we need to cutout extra text (within or out of node).
        //
        // but, before that, we maybe have a string which is shorter then
        // actual limit, so lets try that, it is cheap.
        if (text.length <= limit || container.innerText.length <= limit) {
            return text;
        }

        // we have to traverse nodes...
        let nodes: ChildNode[] = [];
        let currentLength      = 0;

        Array.from(container.childNodes).find((node: ChildNode): boolean => {
            // skip comment.
            if (node instanceof Comment) {
                return false;
            }

            let newLength = currentLength + node.textContent.length;

            // regardless if it is a text node or html node, if fits perfectly, it fits perfectly
            if (newLength === limit) {
                nodes.push(node);
                // don' traverse anymore
                return true;
            }

            // regardless if it is a text node or html node, if there is a room to spare, there is a room to spare.
            if (newLength < limit) {
                currentLength = newLength;
                nodes.push(node);
                // keep traversing.
                return false;
            }

            // we need to use what we have remaining and stop traversing.
            node.textContent = preserveWords ? `${node.textContent.substr(0, node.textContent.lastIndexOf(' ', limit - currentLength))}` : `${node.textContent.slice(0, limit - currentLength)}`;
            node.textContent = node.textContent.replace(/\s+$/, ''); // rtrim

            if ('' !== node.textContent) {
                nodes.push(node);
                return true;
            }

            // previous node is empty, rtrim on last node that we have in collection.
            nodes[nodes.length - 1].textContent = nodes[nodes.length - 1].textContent.replace(/\s+$/, ''); // rtrim
            return true;
        });

        // reuse container, remove everything from it.
        container.innerHTML = '';
        nodes.push(document.createTextNode(TruncatePipe.REMAINS));

        nodes.forEach((node: ChildNode): void => container.append(node));

        return container.innerHTML;
    }

}
