import {
    Inject,
    Injectable,
    Optional,
}                   from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
    Event,
    NavigationEnd,
    Router,
}                   from '@angular/router';
import { Deferred } from '@evermed-sdk/core';
import { filter }   from 'rxjs/operators';
import {
    GOOGLE_TAG_MANAGER_AUTH,
    GOOGLE_TAG_MANAGER_ID,
    GOOGLE_TAG_MANAGER_PREVIEW,
    GoogleTagManagerInterface,
}                   from './google-tag-manager.interface';

/**
 * We can init google tag manager only once
 */
let initStarted: boolean = false;

@Injectable()
export class GoogleTagManager implements GoogleTagManagerInterface {

    private static readonly SCRIPT_ID: string = 'GTMscript';

    private static readonly NO_SCRIPT_ID: string = 'GTMiframe';

    private static readonly SCRIPT_URL: string = '//www.googletagmanager.com/gtm.js';

    private static readonly NO_SCRIPT_URL: string = '//www.googletagmanager.com/ns.html';

    private readonly _ready: Deferred<boolean> = new Deferred<boolean>();

    private readonly _gtmId: string | null;

    private readonly _gtmAuth: string | null;

    private readonly _gtmPreview: string | null;

    private readonly _window: Window;

    private readonly _document: Document;

    public constructor(
        router: Router,
        @Inject(DOCUMENT) document: Document,
        @Inject(GOOGLE_TAG_MANAGER_ID) googleTagManagerId: string | null,
        @Optional() @Inject(GOOGLE_TAG_MANAGER_AUTH) googleTagManagerAuth: string | null,
        @Optional() @Inject(GOOGLE_TAG_MANAGER_PREVIEW) googleTagManagerPreview: string | null,
    ) {
        this._gtmId      = googleTagManagerId;
        this._gtmAuth    = googleTagManagerAuth;
        this._gtmPreview = googleTagManagerPreview;
        this._document   = document;
        this._window     = document.defaultView;

        router.events.pipe(filter((event: Event): boolean => event instanceof NavigationEnd)).subscribe((event: NavigationEnd): void => {
            this.pushTag({
                // eslint-disable-next-line quote-props
                'event':    'page',
                // eslint-disable-next-line quote-props
                'pageName': event.url,
            });
        });

        this.init();
    }

    private getDataLayer(): object[] | null {
        let browserWindow: any  = this._window as any;
        browserWindow.dataLayer = browserWindow.dataLayer || [];
        return browserWindow.dataLayer;
    }

    private pushOnDataLayer(obj: object): void {
        this.getDataLayer().push(obj);
    }

    public init(): void {
        if (initStarted) {
            return;
        }

        initStarted               = true;
        let domDocument: Document = this._document;
        this.pushOnDataLayer({
            'gtm.start': new Date().getTime(),
            // eslint-disable-next-line quote-props
            'event':     'gtm.js',
        });

        let gtmScript: any = domDocument.createElement('script');
        gtmScript.id       = GoogleTagManager.SCRIPT_ID;
        gtmScript.async    = true;
        gtmScript.src      = this.applyGtmQueryParams(
            GoogleTagManager.SCRIPT_URL,
        );
        domDocument.head.insertBefore(gtmScript, domDocument.head.firstChild);

        let iframe: any = domDocument.createElement('iframe');
        iframe.setAttribute(
            'src',
            this.applyGtmQueryParams(GoogleTagManager.NO_SCRIPT_URL),
        );
        iframe.style.width      = '0';
        iframe.style.height     = '0';
        iframe.style.display    = 'none';
        iframe.style.visibility = 'hidden';

        let noscript: any = domDocument.createElement('noscript');
        noscript.id       = GoogleTagManager.NO_SCRIPT_ID;
        noscript.appendChild(iframe);

        domDocument.body.insertBefore(noscript, domDocument.body.firstChild);
    }

    public pushTag(item: object): void {
        this.pushOnDataLayer(item);
    }

    private applyGtmQueryParams(url: string): string {

        let params: string[] = [];

        if (this._gtmId) {
            params.push(`id=${this._gtmId}`);
        }

        if (this._gtmAuth) {
            params.push(`gtm_auth=${this._gtmAuth}`);
        }

        if (this._gtmPreview) {
            params.push(`gtm_preview=${this._gtmPreview}`);
        }

        let urlWithParams: string = url;

        if (urlWithParams.indexOf('?') === -1) {
            urlWithParams += '?';
        }

        return urlWithParams + params.join('&');
    }

    private ready(): Promise<boolean> {
        return this._ready.promise;
    }

}
