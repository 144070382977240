import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnInit,
    ViewChild,
}                     from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
}                     from '@angular/forms';
import { Store }      from '@ngxs/store';
import {
    ClassificationSelector,
    LoadTaxonomy,
    Speciality,
}                     from '@evermed/core';

@Component({
    selector:    'app-speciality-select',
    templateUrl: './speciality-select.component.html',
    styleUrls:   ['./speciality-select.component.scss'],
    providers:   [
        {
            provide:     NG_VALUE_ACCESSOR,
            // eslint-disable-next-line no-use-before-define
            useExisting: forwardRef(() => SpecialitySelectComponent),
            multi:       true,
        },
    ],
})
export class SpecialitySelectComponent implements ControlValueAccessor, AfterViewInit, OnInit {

    @Input()
    public class: string;

    @Input()
    public placeholder: string;

    @Input()
    public disabled: boolean = false;

    @Input()
    public multiple: boolean = false;

    @Input()
    public required: boolean = false;

    public specialities: Speciality[] = [];

    public value: string | string[];

    public busy: boolean = true;

    @ViewChild('select')
    private readonly _select: ElementRef;

    private _store: Store;

    private _hostElement: ElementRef;

    private _onChangeHandler: (value: any) => void;

    private _onTouchedHandler: () => void;

    private readonly _cdr: ChangeDetectorRef;

    public constructor(hostElement: ElementRef, store: Store, cdr: ChangeDetectorRef) {
        this._hostElement = hostElement;
        this._store       = store;
        this._cdr         = cdr;
    }

    public async ngOnInit(): Promise<void> {
        await this._store.dispatch(new LoadTaxonomy()).toPromise();
        this.specialities = this._store.selectSnapshot(ClassificationSelector.specialities);
        this.busy         = false;
        this._cdr.markForCheck();
    }

    public ngAfterViewInit(): void {
        this._hostElement.nativeElement.classList.remove(this.class);
    }

    public isEmpty(): boolean {
        if (this.multiple) {
            return !this.value || 0 === this.value.length;
        }

        return !this.value;
    }

    public isSelected(value: string): boolean {
        let values: string[] = <string[]>(this.multiple ? this.value : [this.value]);
        return -1 !== values.indexOf(value);
    }

    public onChange(): void {
        this.value = this._select.nativeElement.value;
        this.notify();
    }

    public registerOnChange(fn: any): void {
        this._onChangeHandler = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouchedHandler = fn;
    }

    public setDisabledState(disabled: boolean): void {
        this.disabled = disabled;
    }

    public writeValue(obj: string | string[]): void {
        this.value = obj;
    }

    private notify(): void {
        if (!this._onChangeHandler) {
            return;
        }

        this._onChangeHandler(this.value);
        this._onTouchedHandler();
    }

}
