import {
    ChangeDetectorRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    Type,
}                                from '@angular/core';
import { IdentifiableInterface } from '@evermed/core';
import {
    Observable,
    Unsubscribable,
}                                from 'rxjs';

/**
 * A base for collection rendering component.
 */
export abstract class AbstractCollectionComponent implements OnChanges, OnDestroy {

    /**
     * Collection of items to render
     */
    @Input()
    public collection: Iterable<IdentifiableInterface> | Observable<Iterable<IdentifiableInterface>>;

    /**
     * Name of the component input for each individual item.
     */
    @Input()
    public inputName: string = 'item';

    /**
     * Any other input parameters required for component to be rendered.
     */
    @Input()
    public inputs: { [key: string]: any } = {};

    /**
     * Any listeners which needs to be registered for component outputs.
     */
    @Input()
    public outputs: { [key: string]: ($event?: any) => void } = {};

    /**
     * Function to determine which component to use for rendering item, or...
     */
    @Input()
    public componentRef: (item: IdentifiableInterface) => Type<any> = null;

    /**
     * ...a component to use for item rendering.
     */
    @Input()
    public component: Type<any> = null;

    /**
     * Similar concept as ngFor, we may compare objects in collection by
     * other value then reference. By default, identifier is used.
     */
    @Input()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
    public trackBy: (index: number, item: any) => string = (index: number, item: IdentifiableInterface): string => item.getIdentifier();

    /**
     * Emitted when collection has been changed.
     */
    @Output()
    public change: EventEmitter<void> = new EventEmitter<void>();

    /**
     * We will track a list of items here to simplify rendering components.
     */
    public items: Iterable<IdentifiableInterface> | null = null;

    private readonly _changeDetectorRef: ChangeDetectorRef;

    /**
     * If source is observable, we will track subscription here.
     */
    private _subscription: Unsubscribable;

    protected constructor(changeDetectorRef: ChangeDetectorRef) {
        this._changeDetectorRef = changeDetectorRef;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.parameters && changes.parameters.currentValue) {
            let parameters: { [key: string]: any } = changes.parameters.currentValue;

            if (undefined !== parameters.index) {
                throw new Error('You must not use "index" as key of your dynamically rendered inner component.');
            }

            if (undefined !== parameters[this.inputName]) {
                throw new Error(`You must not use "${this.inputName}" as key of your dynamically rendered inner component.`);
            }
        }

        if (!changes.collection) {
            return;
        }

        // change occurred, so unsubscribe either way.
        this._subscription?.unsubscribe();

        if (null === changes.collection.currentValue) {
            this.items = null;
            this._changeDetectorRef.markForCheck();
            this.change.emit();
            return;
        }

        if (changes.collection.currentValue instanceof Observable) {
            this._subscription = changes.collection.currentValue.subscribe((items: Iterable<IdentifiableInterface>): void => {
                this.items = items;
                this._changeDetectorRef.markForCheck();
                this.change.emit();
            });
            return;
        }

        this.items = changes.collection.currentValue;
        this._changeDetectorRef.markForCheck();
        this.change.emit();
    }

    public ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    /**
     * Resolve inner component class.
     */
    public getComponent(item: any): Type<any> {
        if (null !== this.component) {
            return this.component;
        }

        if (null !== this.componentRef) {
            return this.componentRef(item);
        }

        throw new Error('You need to provide either component class or function to resolve component class based on item.');
    }

    /**
     * Resolve inner component inputs.
     */
    public getInput(item: any, index: number): { [key: string]: any } {
        return {
            ...(this.inputs || {}),
            ...{
                index:            index,
                [this.inputName]: item,
            },
        };
    }

}
