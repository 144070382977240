import { InjectionToken } from '@angular/core';

export interface GoogleAnalyticsInterface {

    init(): void;

}

export const GOOGLE_ANALYTICS: InjectionToken<GoogleAnalyticsInterface> = new InjectionToken<GoogleAnalyticsInterface>('GOOGLE_ANALYTICS');

export const GOOGLE_ANALYTICS_ENABLED: InjectionToken<string> = new InjectionToken<string>('GOOGLE_ANALYTICS_ENABLED');

export const GOOGLE_ANALYTICS_ID: InjectionToken<string> = new InjectionToken<string>('GOOGLE_ANALYTICS_ID');
