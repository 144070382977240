import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    Input,
    ViewChild,
}                         from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
}                         from '@angular/forms';
import { ImageProcessor } from '@evermed-sdk/core';

@Component({
    selector:        'app-image-picker',
    templateUrl:     './image-picker.component.html',
    styleUrls:       ['./image-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers:       [
        {
            provide:     NG_VALUE_ACCESSOR,
            // eslint-disable-next-line no-use-before-define
            useExisting: forwardRef(() => ImagePickerComponent),
            multi:       true,
        },
    ],
})
export class ImagePickerComponent implements ControlValueAccessor {

    @Input()
    public disabled: boolean = false;

    @Input()
    public placeholder: string = null;

    @Input()
    public width: number = 800;

    @Input()
    public height: number = 800;

    @Input()
    public method: 'scale' | 'thumbnail' = 'thumbnail';

    @Input()
    public accept: string = 'image/jpeg,image/jpg,image/png,.jpg,.png,.jpeg';

    @ViewChild('input')
    private readonly _input: ElementRef;

    private readonly _processor: ImageProcessor;

    private readonly _cdr: ChangeDetectorRef;

    private _value: string = null;

    private _onChangeHandler: (value: any) => {};

    private _onTouchedHandler: () => {};

    public constructor(
        processor: ImageProcessor,
        cdr: ChangeDetectorRef,
    ) {
        this._processor = processor;
        this._cdr       = cdr;
    }

    @HostBinding('style.paddingBottom')
    public get paddingBottom(): string {
        return `${this.width / this.height * 100}%`;
    }

    public get backgroundImage(): string {
        let value: string | null = this._value || this.placeholder;
        return value ? `url(${value})` : '';
    }

    public registerOnChange(fn: any): void {
        this._onChangeHandler = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouchedHandler = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(obj: any): void {
        this._value = obj;
        this._cdr.markForCheck();
    }

    public clear(): void {
        this._value = null;
        this.notify();
    }

    public select(): void {
        this._input.nativeElement.click();
    }

    public async onInputChange(event: Event): Promise<void> {
        let input: HTMLInputElement = event.target as HTMLInputElement;
        let files: FileList         = input.files;

        if (null === files || 0 === files.length) {
            return;
        }

        this._value = await this._processor[this.method].apply(this._processor, [
            files.item(0),
            this.width,
            this.height,
        ]);

        this._cdr.markForCheck();
        this.notify();
    }

    private notify(): void {
        if (!this._onChangeHandler) {
            return;
        }

        this._onChangeHandler(this._value);
        this._onTouchedHandler();
    }

}
