import { ComponentRef } from '@angular/core';
import {
    Actions,
    ofActionDispatched,
    Store,
}                       from '@ngxs/store';
import {
    PurchaseSuccessful,
    UserSelector,
    VerifyConsentable,
}                       from '@evermed/core';
import { AppComponent } from '../app.component';

export function requireAfterPurchaseConsents(
    actions: Actions,
    store: Store,
): (ref: ComponentRef<any>) => void {
    return (ref: ComponentRef<any>): void => {

        if (!(ref.instance instanceof AppComponent)) {
            return;
        }

        actions.pipe(ofActionDispatched(PurchaseSuccessful)).subscribe((): void => {
            // after purchase has been made, there might be additional consents required to
            // be accepted in regard to projects/products/etc...
            store.dispatch(new VerifyConsentable(store.selectSnapshot(UserSelector.current)));
        });
    };
}
