import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
}                             from '@angular/core';
import { Router }             from '@angular/router';
import {
    SearchFilterFormData,
    SearchSelector,
    ResetSearchFilter,
}                             from '@evermed/core';
import {
    UntilDestroy,
    untilDestroyed,
}                             from '@ngneat/until-destroy';
import { UpdateFormValue }    from '@ngxs/form-plugin';
import {
    Actions,
    ofActionDispatched,
    Store,
}                             from '@ngxs/store';

@UntilDestroy()
@Component({
    selector:    'app-menu-search-bar',
    templateUrl: './menu-search-bar.component.html',
    styleUrls:   ['./menu-search-bar.component.scss'],
})
export class MenuSearchBarComponent implements OnInit, AfterViewInit {

    private readonly _router: Router;

    private readonly _store: Store;

    private readonly _actions: Actions;

    @ViewChild('input')
    private readonly _input: ElementRef;

    private _lastValue: string = null;

    public constructor(
        store: Store,
        router: Router,
        actions: Actions,
    ) {
        this._store   = store;
        this._router  = router;
        this._actions = actions;
    }

    public ngOnInit(): void {
        this._actions.pipe(ofActionDispatched(ResetSearchFilter)).subscribe((): void => {
            this._lastValue = '';
            this._input.nativeElement.clear();
        });
    }

    public ngAfterViewInit(): void {
        this._store.select(SearchSelector.filter).pipe(untilDestroyed(this)).subscribe((filter: SearchFilterFormData): void => {
            if (null === filter || !filter.term) {
                this._lastValue = null;
                this._input.nativeElement.clear();
                return;
            }

            this._lastValue                 = filter.term;
            this._input.nativeElement.value = filter.term;
        });
    }

    public async search(event: CustomEvent): Promise<void> {
        let value: string = event.detail.trim();

        // there is no need to trigger search, nothing changed.
        if (this._lastValue === value) {
            return;
        }

        this._lastValue = value;

        await this._store.dispatch(new UpdateFormValue({
            path:  'search.filterForm',
            value: {
                term: value,
            },
        })).toPromise();
    }

    public async submit(event: CustomEvent): Promise<void> {
        await this.search(event);
        let redirect: boolean = !this._router.isActive('/search', false);

        if (redirect) {
            await this._router.navigate(['/search']);
        }
    }

}
