import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
}                                from '@angular/core';
import { AuthMetadataInterface } from '@evermed-sdk/core';
import {
    UntilDestroy,
    untilDestroyed,
}                                from '@ngneat/until-destroy';
import { Store }        from '@ngxs/store';
import { HttpClient }   from '@runopencode/http';
import { Router }       from '@runopencode/router';
import {
    Video,
    VideoOnDemandInterface,
    Resume,
    AuthSelector,
    ContinuitySelector,
    TextTrack,
}                       from '@evermed/core';

/**
 * This component displays video player for video-on-demand (video, virtual booth). However, state of this component
 * depends of various factors, so decision tree below displays what needs to be taken into consideration when designing
 * an UI for this component:
 *
 * Description of use-cases:
 *
 *  -> User is authenticated
 *      -> User can access video: play it
 *      -> User can not access video: provide possiblity to join waitlist and/or redeem access code
 *  -> User is not authenticated
 *      -> If possible, show video preview (30 seconds, metadata can be resolved to something accessible).
 *      -> Display thumbnail and invite user to register.
 *
 */
@UntilDestroy()
@Component({
    selector:        'app-video-on-demand-player',
    templateUrl:     './video-on-demand-player.component.html',
    styleUrls:       ['./video-on-demand-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoOnDemandPlayerComponent implements OnInit, OnChanges {

    /**
     * If remaining number of seconds for given video is less then
     * defined number below, continuity will be ignored and video
     * will be played starting from beginning.
     */
    private static readonly CONTINUITY_RESET_THRESHOLD = 30;

    /**
     * Video which needs to be played.
     */
    @Input()
    public video: VideoOnDemandInterface;

    /**
     * Autoplay video, if possible (or not).
     *
     * Applicable only to full video, preview will
     * be always auto-played (if possible).
     */
    @Input()
    public autoplay: boolean = true;

    /**
     * Video has ended.
     */
    @Output()
    public readonly ended: EventEmitter<void> = new EventEmitter<void>();

    public authenticated: boolean;

    /**
     * In order to prevent re-rendering of video player,
     * metadata will be updated only when video source
     * actually changes.
     */
    public metadata: AuthMetadataInterface;

    /**
     * In order to prevent re-rendering of video player,
     * metadata will be updated only when thumbnail source
     * actually changes.
     */
    public thumbnail: AuthMetadataInterface;

    /**
     * In order to prevent re-rendering of video player,
     * text tracks will be updated only when video source
     * actually changes.
     */
    public tracks: TextTrack[];

    /**
     * In order to prevent re-rendering of video player,
     * checkpoint will be updated only when video source
     * actually changes.
     */
    public stoppedAt: number;

    public readonly http: HttpClient;

    public readonly router: Router;

    private readonly _cdr: ChangeDetectorRef;

    private readonly _store: Store;

    public constructor(
        http: HttpClient,
        router: Router,
        cdr: ChangeDetectorRef,
        store: Store,
    ) {
        this.http   = http;
        this.router = router;
        this._cdr   = cdr;
        this._store = store;
    }

    public ngOnInit(): void {
        this._store.select(AuthSelector.isAuthenticated).pipe(untilDestroyed(this)).subscribe((authenticated: boolean): void => {
            this.authenticated = authenticated;
            this._cdr.markForCheck();
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // video is not subject of the change
        if (!changes.video) {
            return;
        }

        let previous: Video = changes.video.previousValue;
        let current: Video  = changes.video.currentValue;

        // there is no current value associated at all, so we will just remove it
        if (!current) {
            this.metadata  = null;
            this.thumbnail = null;
            this.tracks    = null;
            this.stoppedAt = 0;
            this._cdr.markForCheck();
            return;
        }

        // it is actually a same video, source have not been changed.
        if (previous && previous.id === current.id) {
            return;
        }

        this.setUpVideo().then(/* noop */);
    }

    private async setUpVideo(): Promise<void> {
        this.metadata  = null;
        this.thumbnail = null;
        this.tracks    = null;
        this.stoppedAt = 0;

        // re init video because router is not provided
        // TODO: @TheCelavi move analytics from stencil to directive
        this._cdr.markForCheck();
        this.thumbnail = this.video.thumbnail;
        this._cdr.markForCheck();

        try {
            await this._store.dispatch(new Resume(this.video)).toPromise();
            this.stoppedAt = this._store.selectSnapshot(ContinuitySelector.resume(this.video));

            if (VideoOnDemandPlayerComponent.CONTINUITY_RESET_THRESHOLD >= this.video.duration - this.stoppedAt) {
                this.stoppedAt = 0;
            }
        } catch (e) {
            // noop
        }

        try {
            this.metadata = await this.video.video.metadata;
            this.tracks   = this.video.tracks;
        } catch (e) {
            // noop
        }

        this._cdr.markForCheck();
    }

}
