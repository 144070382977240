import { ComponentRef }   from '@angular/core';
import { Observable }     from 'rxjs';
import { first }          from 'rxjs/operators';
import { ModalComponent } from '../../components';

export class ModalReference<T> {

    private readonly _modal: ComponentRef<ModalComponent<T>>;

    private readonly _component: T;

    private dismissed: boolean = false;

    public constructor(modal: ComponentRef<ModalComponent<T>>, componenet: T) {
        this._modal     = modal;
        this._component = componenet;
        this._modal.instance.dismissed.pipe(first()).subscribe((): void => {
            this.dismissed = true;
        });
    }

    public get dismissed$(): Observable<void> {
        return this._modal.instance.dismissed.asObservable();
    }

    public get component(): T {
        return this._component;
    }

    public get modal(): ModalComponent<T> {
        return this._modal.instance;
    }

    public async dismiss(): Promise<void> {
        if (true === this.dismissed) {
            return;
        }

        this.dismissed = true;

        await this._modal.instance.dismiss();
    }

}
