import {
    Pipe,
    PipeTransform,
} from '@angular/core';
import * as CountryData from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';

CountryData.registerLocale(en);

@Pipe({
    name: 'countryName',
})
export class CountryNamePipe implements PipeTransform {

    // eslint-disable-next-line class-methods-use-this
    public transform(value: string, language: string = 'en'): string {
        return CountryData.getName(value, language);
    }

}
