import {
    Component,
    forwardRef,
    Input,
    Type,
}                          from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
}                          from '@angular/forms';
import { ChoiceInterface } from '@evermed/core';

@Component({
    selector:    'app-extended-select',
    templateUrl: './extended-select.component.html',
    styleUrls:   ['./extended-select.component.scss'],
    providers:   [{
        // eslint-disable-next-line no-use-before-define
        useExisting: forwardRef((): Type<ExtendedSelectComponent> => ExtendedSelectComponent),
        multi:       true,
        provide:     NG_VALUE_ACCESSOR,
    }],
})
export class ExtendedSelectComponent implements ControlValueAccessor {

    @Input()
    public choices: ChoiceInterface[];

    @Input()
    public multiple: boolean = true;

    @Input()
    public disabled: boolean = false;

    @Input()
    public styleType: 'primary' | 'secondary' = 'primary';

    /**
     * Currently selected values.
     */
    private _values: string[] = [];

    private _onChangeHandler: (_: any) => void;

    private _onTouchedHandler: () => void;

    public onSelect(value: boolean, choice: ChoiceInterface): void {
        // selected single value
        if (value && !this.multiple) {
            this._values = [choice.value];
            this._onChangeHandler(choice.value);
            return;
        }

        // deselected single value
        if (!value && !this.multiple) {
            this._values = [];
            this._onChangeHandler(null);
            return;
        }

        // selected multiple values
        if (value && this.multiple) {
            this._values.push(choice.value);
            this._values = [...new Set(this._values)];
            this._onChangeHandler(this._values);
            return;
        }

        // deselected multiple values
        if (!value && this.multiple) {
            this._values = this._values.filter((current: string) => current !== choice.value);
            this._onChangeHandler(this._values);
            return;
        }

        throw new Error('Impossible condition.');
    }

    public onBlur(): void {
        this._onTouchedHandler();
    }

    public isSelected(choice: ChoiceInterface): boolean {
        return -1 !== this._values.indexOf(choice.value);
    }

    public registerOnChange(fn: (_: any) => void): void {
        this._onChangeHandler = fn;
    }

    public registerOnTouched(fn: any): void {
        this._onTouchedHandler = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(obj: any): void {
        if (!obj) {
            this._values = [];
            return;
        }

        if (this.multiple) {
            this._values = obj;
            return;
        }

        this._values = [obj];
    }

}
