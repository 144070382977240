import {
    APP_BOOTSTRAP_LISTENER,
    Injector,
    Provider,
}                                          from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService }                from '@ngx-translate/core';
import {
    Actions,
    Store,
}                                          from '@ngxs/store';
import { ModalController }                 from '../../shared';
import { bootstrapPreventIframeInjection } from './bootstrap-prevent-iframe-injection';
import { requireAfterPurchaseConsents }    from './require-after-purchase-consents';
import { requireUserProfileConsents }      from './require-user-profile-consents';
import { bootstrapConsentCollection }      from './bootstrap-consent-collection';
import { bootstrapGoogleAnalytics }        from './bootstrap-google-analytics';
import { bootstrapGoogleTagManager }       from './bootstrap-google-tag-manager';

export const APPLICATION_BOOTSTRAPPERS: Provider[] = [
    {
        provide:    APP_BOOTSTRAP_LISTENER,
        useFactory: bootstrapConsentCollection,
        multi:      true,
        deps:       [Actions, Store, ModalController, TranslateService],
    },
    {
        provide:    APP_BOOTSTRAP_LISTENER,
        useFactory: bootstrapGoogleAnalytics,
        multi:      true,
        deps:       [Injector],
    },
    {
        provide:    APP_BOOTSTRAP_LISTENER,
        useFactory: bootstrapGoogleTagManager,
        multi:      true,
        deps:       [Injector],
    },
    {
        provide:    APP_BOOTSTRAP_LISTENER,
        useFactory: requireUserProfileConsents,
        multi:      true,
        deps:       [Store],
    },
    {
        provide:    APP_BOOTSTRAP_LISTENER,
        useFactory: requireAfterPurchaseConsents,
        multi:      true,
        deps:       [Actions, Store],
    },
    {
        provide:    APP_BOOTSTRAP_LISTENER,
        useFactory: bootstrapPreventIframeInjection,
        multi:      true,
        deps:       [DOCUMENT],
    },
];
