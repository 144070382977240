import { Component } from '@angular/core';
import {
    Select,
    Store,
} from '@ngxs/store';
import {
    AuthSelector,
    Logout,
    User,
    UserSelector,
} from '@evermed/core';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@UntilDestroy()
@Component({
    selector:    'app-menu',
    templateUrl: './menu.component.html',
    styleUrls:   ['./menu.component.scss'],
})
export class MenuComponent {

    @Select(AuthSelector.isAuthenticated)
    public authenticated$: Observable<boolean>;

    @Select(UserSelector.current)
    public user$: Observable<User>;

    public authenticated: boolean;

    public responsiveMenuOpen: boolean = false;

    public profileDropdownOpen: boolean = false;

    private readonly _store: Store;

    private readonly _router: Router;

    public constructor(
        store: Store,
        router: Router,
    ) {
        this._store = store;
        this._router = router;

        this.authenticated$.pipe(untilDestroyed(this)).subscribe((authenticated: boolean) => {
            this.authenticated = authenticated;
        });
    }

    public isHomepageRouteActive(): boolean {
        return this._router.url.indexOf('events') >= 0;
    }

    public toggleResponsiveMenu(): void {
        this.responsiveMenuOpen = !this.responsiveMenuOpen;
    }

    public toggleProfileDropdown(): void {
        this.profileDropdownOpen = !this.profileDropdownOpen;
    }

    public closeProfileDropdown(): void {
        this.profileDropdownOpen = false;
    }

    public async logout(): Promise<void> {
        await this._store.dispatch(new Logout()).toPromise();
    }

}
