/* eslint-disable no-param-reassign */
import { Injectable }            from '@angular/core';
import { TranslateService }      from '@ngx-translate/core';
import {
    IndividualConfig,
    ToastrService,
}                                from 'ngx-toastr';
import { ActiveToast }           from './active-toast';
import { ToastOptionsInterface } from './toast-options.interface';

/**
 * We are proxying concrete implementation with our own service
 * in order to be able to replace it with ease if needed.
 *
 * Of course, our toaster translates messages by default.
 */
@Injectable()
export class Toast {

    private readonly _toaster: ToastrService;

    private readonly _translator: TranslateService;

    public constructor(
        toaster: ToastrService,
        translator: TranslateService,
    ) {
        this._toaster    = toaster;
        this._translator = translator;
    }

    public async success(
        message: string,
        params?: { [key: string]: any } | null,
        options?: ToastOptionsInterface,
    ): Promise<ActiveToast> {
        return this.getToast('success', message, params, options);
    }

    public async error(
        message: string,
        params?: { [key: string]: any } | null,
        options?: ToastOptionsInterface,
    ): Promise<ActiveToast> {
        return this.getToast('error', message, params, options);
    }

    public async warning(
        message: string,
        params?: { [key: string]: any } | null,
        options?: ToastOptionsInterface,
    ): Promise<ActiveToast> {
        return this.getToast('warning', message, params, options);
    }

    public async info(
        message: string,
        params?: { [key: string]: any } | null,
        options?: ToastOptionsInterface,
    ): Promise<ActiveToast> {
        return this.getToast('info', message, params, options);
    }

    private async getToast(
        type: 'success' | 'error' | 'info' | 'warning',
        message: string,
        params?: { [key: string]: any } | null,
        options?: ToastOptionsInterface,
    ): Promise<ActiveToast> {
        options                       = options || {};
        let title: string | undefined = options.title || undefined;
        message                       = await this._translator.get(message, params || {}).toPromise();

        if (false !== options.title) {
            title = await this._translator.get(title || `toast.${type}.title`, params || {}).toPromise();
        }

        let resolvedOptions: Partial<IndividualConfig> = {
            closeButton:       undefined !== options.closeable ? options.closeable : true,
            timeOut:           undefined !== options.timeout ? options.timeout : 5000,
            enableHtml:        undefined !== options.enableHtml ? options.enableHtml : false,
            progressBar:       undefined !== options.progressBar ? options.progressBar : false,
        };

        return new ActiveToast(this._toaster[type].call(
            this._toaster,
            message,
            title,
            resolvedOptions,
        ));
    }

}
