import {
    ChangeDetectorRef,
    PipeTransform,
    Pipe,
} from '@angular/core';
import {
    Event as RouterEvent,
    NavigationEnd,
    Router,
} from '@angular/router';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';

@UntilDestroy()
@Pipe({
    name: 'activeRoute',
    pure: false,
})
export class ActiveRoutePipe implements PipeTransform {

    private readonly _router: Router;

    private readonly _cdr: ChangeDetectorRef;

    private _url: string = '';

    private _value: boolean = false;

    private _exact: boolean = false;

    public constructor(
        router: Router,
        cdr: ChangeDetectorRef,
    ) {
        this._router = router;
        this._cdr    = cdr;

        this.init();
    }

    public transform(url: string, exact: boolean = false): boolean {
        if (this._url !== url || this._exact !== exact) {
            this._exact = exact;
            this._url   = url;
            this._value = this._router.isActive(url, exact);
        }

        return this._value;
    }

    public init(): void {
        let lastRoute: string = null;

        this._router.events
            .pipe(untilDestroyed(this))
            .subscribe((event: RouterEvent): void => {
                if (event instanceof NavigationEnd) {
                    if (lastRoute === this._router.url) {
                        return;
                    }

                    lastRoute   = this._router.url;
                    this._value = this._router.isActive(this._url, this._exact);
                    this._cdr.markForCheck();
                }
            });
    }

}
