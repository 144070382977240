import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
}                                      from '@angular/core';
import { Router }                      from '@angular/router';
import {
    CollectionInterface,
    IdentifiableInterface,
}                                      from '@evermed/core';
import { AbstractCollectionComponent } from '../abstract-collection-component';

/**
 * This is a proxy component to a CarouselComponent. Purpose of this
 * component is to display carousel, however, if carousel has less
 * elements to display, or source is paginated/cursored collection
 * which can load more elements, this component will display navigation
 * link per your desire to page rendering all elements with possibility
 * of additional element loading.
 */
@Component({
    selector:        'app-partial-carousel',
    templateUrl:     './partial-carousel.component.html',
    styleUrls:       ['./partial-carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartialCarouselComponent extends AbstractCollectionComponent {

    /**
     * @see CarouselComponent.breakpoints
     */
    @Input()
    public breakpoints: null;

    /**
     * @see CarouselComponent.max
     */
    @Input()
    public max: number | null = 8;

    /**
     * Route compatible with routerLink directive
     */
    @Input()
    public route: any[] | string | null | undefined = null;

    /**
     * Optionally, instead of route, you may provide a
     * reference to a function to invoke, followed by
     * optional parameters to
     */
    @Input()
    public navigate: [functionReference: (...args: any) => void, ...args: any] = null;

    /**
     * Notify if carousel sliced provided collection and rendered
     * only a portion of it. However, this will return true even
     * if all items are displayed but more can be lazy loaded.
     */
    @Output()
    public readonly sliced: EventEmitter<boolean> = new EventEmitter<boolean>();

    /**
     * Should link to page with all items be displayed.
     */
    public navigatable: boolean = false;

    private readonly _cdr: ChangeDetectorRef;

    private readonly _router: Router;

    public constructor(
        cdr: ChangeDetectorRef,
        router: Router,
    ) {
        super(cdr);
        this._cdr    = cdr;
        this._router = router;
    }

    public onSliced(sliced: boolean): void {
        if (sliced) {
            this.navigatable = this.hasNavigation();
            this._cdr.markForCheck();
            this.sliced.emit(true);
        }

        let hasMore: boolean | undefined = (this.items as CollectionInterface<IdentifiableInterface>).hasMore;

        if (undefined !== hasMore) {
            this.navigatable = this.hasNavigation() && hasMore;
            this._cdr.markForCheck();
            this.sliced.emit(hasMore);
        }

        this.navigatable = this.hasNavigation() && sliced;
        this._cdr.markForCheck();
        this.sliced.emit(sliced);
    }

    public viewAll(): void {
        if (this.route) {
            this._router.navigate(Array.isArray(this.route) ? this.route : [this.route]).then(/* noop */);
            return;
        }

        if (this.navigate) {
            let functionReference: (...args: any) => void = this.navigate[0];
            let args: any[]                               = this.navigate.slice(1);
            functionReference(...args);
        }
    }

    private hasNavigation(): boolean {
        return !!this.route || !!this.navigate;
    }


}
