import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    Input,
}                from '@angular/core';
import {
    Bookmark,
    BookmarkableInterface,
    Unbookmark,
}                from '@evermed/core';
import { Store } from '@ngxs/store';

@Component({
    selector:        'app-bookmark',
    templateUrl:     './bookmark.component.html',
    styleUrls:       ['./bookmark.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookmarkComponent {

    @Input()
    public subject: BookmarkableInterface<any>;

    @Input()
    public inverse: boolean = false;

    public busy: boolean = false;

    private readonly _store: Store;

    private readonly _cdr: ChangeDetectorRef;

    public constructor(store: Store, cdr: ChangeDetectorRef) {
        this._store = store;
        this._cdr   = cdr;
    }

    @HostListener('click', ['$event'])
    public async onClick(event: Event): Promise<void> {
        event.stopPropagation();

        if (this.busy) {
            return;
        }

        let action: Bookmark<any> | Unbookmark<any> = this.subject.bookmarked ? new Unbookmark(this.subject) : new Bookmark(this.subject);
        this.busy                                   = true;
        this._cdr.markForCheck();

        await this._store.dispatch(action).toPromise();
        this.busy = false;
        this._cdr.markForCheck();
    }

}
