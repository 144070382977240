import { __decorate } from 'tslib';
import { ɵɵdirectiveInject, ChangeDetectorRef, ElementRef, NgZone, ɵɵdefineComponent, ɵɵprojectionDef, ɵɵprojection, ɵɵdefineNgModule, ɵɵdefineInjector, ɵɵsetNgModuleScope } from '@angular/core';
import { fromEvent } from 'rxjs';
import { defineCustomElements } from '@evermed/components/loader';

const proxyInputs = (Cmp, inputs) => {
    const Prototype = Cmp.prototype;
    inputs.forEach((item) => {
        Object.defineProperty(Prototype, item, {
            get() {
                return this.el[item];
            },
            set(val) {
                this.z.runOutsideAngular(() => (this.el[item] = val));
            },
        });
    });
};
const proxyMethods = (Cmp, methods) => {
    const Prototype = Cmp.prototype;
    methods.forEach((methodName) => {
        Prototype[methodName] = function () {
            const args = arguments;
            return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
        };
    });
};
const proxyOutputs = (instance, el, events) => {
    events.forEach((eventName) => (instance[eventName] = fromEvent(el, eventName)));
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
    const decorator = function (cls) {
        if (opts.inputs) {
            proxyInputs(cls, opts.inputs);
        }
        if (opts.methods) {
            proxyMethods(cls, opts.methods);
        }
        return cls;
    };
    return decorator;
}

const _c0 = ["*"];
class EvermedAccessCodeConstraints {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
}
EvermedAccessCodeConstraints.ɵfac = function EvermedAccessCodeConstraints_Factory(t) { return new (t || EvermedAccessCodeConstraints)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedAccessCodeConstraints.ɵcmp = ɵɵdefineComponent({ type: EvermedAccessCodeConstraints, selectors: [["evermed-access-code-constraints"]], ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedAccessCodeConstraints_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
let EvermedAccessCodeCountryConstraint = class EvermedAccessCodeCountryConstraint {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedConstraintValueChange']);
    }
};
EvermedAccessCodeCountryConstraint.ɵfac = function EvermedAccessCodeCountryConstraint_Factory(t) { return new (t || EvermedAccessCodeCountryConstraint)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedAccessCodeCountryConstraint.ɵcmp = ɵɵdefineComponent({ type: EvermedAccessCodeCountryConstraint, selectors: [["evermed-access-code-country-constraint"]], inputs: { constraint: "constraint" }, outputs: { evermedConstraintValueChange: "evermedConstraintValueChange" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedAccessCodeCountryConstraint_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedAccessCodeCountryConstraint = __decorate([
    ProxyCmp({
        inputs: ['constraint']
    })
], EvermedAccessCodeCountryConstraint);
let EvermedAccessCodeTotalConstraint = class EvermedAccessCodeTotalConstraint {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedConstraintValueChange']);
    }
};
EvermedAccessCodeTotalConstraint.ɵfac = function EvermedAccessCodeTotalConstraint_Factory(t) { return new (t || EvermedAccessCodeTotalConstraint)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedAccessCodeTotalConstraint.ɵcmp = ɵɵdefineComponent({ type: EvermedAccessCodeTotalConstraint, selectors: [["evermed-access-code-total-constraint"]], inputs: { constraint: "constraint" }, outputs: { evermedConstraintValueChange: "evermedConstraintValueChange" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedAccessCodeTotalConstraint_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedAccessCodeTotalConstraint = __decorate([
    ProxyCmp({
        inputs: ['constraint']
    })
], EvermedAccessCodeTotalConstraint);
let EvermedAccessCodeUnconstrained = class EvermedAccessCodeUnconstrained {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedAccessCodeUnconstrained.ɵfac = function EvermedAccessCodeUnconstrained_Factory(t) { return new (t || EvermedAccessCodeUnconstrained)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedAccessCodeUnconstrained.ɵcmp = ɵɵdefineComponent({ type: EvermedAccessCodeUnconstrained, selectors: [["evermed-access-code-unconstrained"]], inputs: { constraint: "constraint" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedAccessCodeUnconstrained_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedAccessCodeUnconstrained = __decorate([
    ProxyCmp({
        inputs: ['constraint']
    })
], EvermedAccessCodeUnconstrained);
let EvermedAlert = class EvermedAlert {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedHidden', 'evermedShown']);
    }
};
EvermedAlert.ɵfac = function EvermedAlert_Factory(t) { return new (t || EvermedAlert)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedAlert.ɵcmp = ɵɵdefineComponent({ type: EvermedAlert, selectors: [["evermed-alert"]], inputs: { dismissable: "dismissable", sticky: "sticky", ttl: "ttl", type: "type", visible: "visible" }, outputs: { evermedHidden: "evermedHidden", evermedShown: "evermedShown" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedAlert_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedAlert = __decorate([
    ProxyCmp({
        inputs: ['dismissable', 'sticky', 'ttl', 'type', 'visible'],
        methods: ['hide', 'show']
    })
], EvermedAlert);
let EvermedBadge = class EvermedBadge {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedChange']);
    }
};
EvermedBadge.ɵfac = function EvermedBadge_Factory(t) { return new (t || EvermedBadge)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedBadge.ɵcmp = ɵɵdefineComponent({ type: EvermedBadge, selectors: [["evermed-badge"]], inputs: { color: "color", count: "count", hideable: "hideable" }, outputs: { evermedChange: "evermedChange" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedBadge_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedBadge = __decorate([
    ProxyCmp({
        inputs: ['color', 'count', 'hideable']
    })
], EvermedBadge);
let EvermedBookmark = class EvermedBookmark {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedChange']);
    }
};
EvermedBookmark.ɵfac = function EvermedBookmark_Factory(t) { return new (t || EvermedBookmark)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedBookmark.ɵcmp = ɵɵdefineComponent({ type: EvermedBookmark, selectors: [["evermed-bookmark"]], inputs: { bookmarked: "bookmarked" }, outputs: { evermedChange: "evermedChange" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedBookmark_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedBookmark = __decorate([
    ProxyCmp({
        inputs: ['bookmarked']
    })
], EvermedBookmark);
let EvermedChoiceType = class EvermedChoiceType {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedChoiceType.ɵfac = function EvermedChoiceType_Factory(t) { return new (t || EvermedChoiceType)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedChoiceType.ɵcmp = ɵɵdefineComponent({ type: EvermedChoiceType, selectors: [["evermed-choice-type"]], inputs: { filters: "filters", http: "http", localization: "localization", route: "route", router: "router" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedChoiceType_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedChoiceType = __decorate([
    ProxyCmp({
        inputs: ['filters', 'http', 'localization', 'route', 'router']
    })
], EvermedChoiceType);
let EvermedComments = class EvermedComments {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedChange']);
    }
};
EvermedComments.ɵfac = function EvermedComments_Factory(t) { return new (t || EvermedComments)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedComments.ɵcmp = ɵɵdefineComponent({ type: EvermedComments, selectors: [["evermed-comments"]], inputs: { count: "count", label: "label" }, outputs: { evermedChange: "evermedChange" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedComments_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedComments = __decorate([
    ProxyCmp({
        inputs: ['count', 'label']
    })
], EvermedComments);
let EvermedContent = class EvermedContent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedContent.ɵfac = function EvermedContent_Factory(t) { return new (t || EvermedContent)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedContent.ɵcmp = ɵɵdefineComponent({ type: EvermedContent, selectors: [["evermed-content"]], inputs: { name: "name" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedContent_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedContent = __decorate([
    ProxyCmp({
        inputs: ['name']
    })
], EvermedContent);
let EvermedDateRangeType = class EvermedDateRangeType {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedChange']);
    }
};
EvermedDateRangeType.ɵfac = function EvermedDateRangeType_Factory(t) { return new (t || EvermedDateRangeType)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedDateRangeType.ɵcmp = ɵɵdefineComponent({ type: EvermedDateRangeType, selectors: [["evermed-date-range-type"]], inputs: { form: "form", format: "format", maxDate: "maxDate", minDate: "minDate", model: "model", placeholder: "placeholder", time: "time" }, outputs: { evermedChange: "evermedChange" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedDateRangeType_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedDateRangeType = __decorate([
    ProxyCmp({
        inputs: ['form', 'format', 'maxDate', 'minDate', 'model', 'placeholder', 'time'],
        methods: ['show', 'hide', 'clear', 'getValue', 'setValue']
    })
], EvermedDateRangeType);
let EvermedDateType = class EvermedDateType {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedChange']);
    }
};
EvermedDateType.ɵfac = function EvermedDateType_Factory(t) { return new (t || EvermedDateType)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedDateType.ɵcmp = ɵɵdefineComponent({ type: EvermedDateType, selectors: [["evermed-date-type"]], inputs: { form: "form", format: "format", maxDate: "maxDate", minDate: "minDate", model: "model", placeholder: "placeholder", time: "time" }, outputs: { evermedChange: "evermedChange" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedDateType_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedDateType = __decorate([
    ProxyCmp({
        inputs: ['form', 'format', 'maxDate', 'minDate', 'model', 'placeholder', 'time'],
        methods: ['show', 'hide', 'clear', 'getValue', 'setValue']
    })
], EvermedDateType);
let EvermedDownloadAnalytics = class EvermedDownloadAnalytics {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedDownloadAnalytics.ɵfac = function EvermedDownloadAnalytics_Factory(t) { return new (t || EvermedDownloadAnalytics)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedDownloadAnalytics.ɵcmp = ɵɵdefineComponent({ type: EvermedDownloadAnalytics, selectors: [["evermed-download-analytics"]], inputs: { disabled: "disabled", http: "http", identifier: "identifier", router: "router", throttle: "throttle", user: "user" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedDownloadAnalytics_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedDownloadAnalytics = __decorate([
    ProxyCmp({
        inputs: ['disabled', 'http', 'identifier', 'router', 'throttle', 'user']
    })
], EvermedDownloadAnalytics);
let EvermedDropdown = class EvermedDropdown {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['hiddenChange']);
    }
};
EvermedDropdown.ɵfac = function EvermedDropdown_Factory(t) { return new (t || EvermedDropdown)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedDropdown.ɵcmp = ɵɵdefineComponent({ type: EvermedDropdown, selectors: [["evermed-dropdown"]], inputs: { behaviour: "behaviour", container: "container", hidden: "hidden" }, outputs: { hiddenChange: "hiddenChange" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedDropdown_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedDropdown = __decorate([
    ProxyCmp({
        inputs: ['behaviour', 'container', 'hidden'],
        methods: ['refresh', 'show', 'hide', 'toggle']
    })
], EvermedDropdown);
let EvermedDropzone = class EvermedDropzone {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedAccept']);
    }
};
EvermedDropzone.ɵfac = function EvermedDropzone_Factory(t) { return new (t || EvermedDropzone)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedDropzone.ɵcmp = ɵɵdefineComponent({ type: EvermedDropzone, selectors: [["evermed-dropzone"]], inputs: { clickable: "clickable", folders: "folders", icon: "icon", maxFiles: "maxFiles", maxSize: "maxSize", minFiles: "minFiles", minSize: "minSize", types: "types" }, outputs: { evermedAccept: "evermedAccept" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedDropzone_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedDropzone = __decorate([
    ProxyCmp({
        inputs: ['clickable', 'folders', 'icon', 'maxFiles', 'maxSize', 'minFiles', 'minSize', 'types']
    })
], EvermedDropzone);
let EvermedEqualizer = class EvermedEqualizer {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedEqualized']);
    }
};
EvermedEqualizer.ɵfac = function EvermedEqualizer_Factory(t) { return new (t || EvermedEqualizer)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedEqualizer.ɵcmp = ɵɵdefineComponent({ type: EvermedEqualizer, selectors: [["evermed-equalizer"]], inputs: { delay: "delay", disabled: "disabled", manual: "manual", mode: "mode", property: "property", reference: "reference" }, outputs: { evermedEqualized: "evermedEqualized" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedEqualizer_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedEqualizer = __decorate([
    ProxyCmp({
        inputs: ['delay', 'disabled', 'manual', 'mode', 'property', 'reference'],
        methods: ['refresh', 'reset']
    })
], EvermedEqualizer);
let EvermedFormFieldErrors = class EvermedFormFieldErrors {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedFormFieldErrors.ɵfac = function EvermedFormFieldErrors_Factory(t) { return new (t || EvermedFormFieldErrors)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedFormFieldErrors.ɵcmp = ɵɵdefineComponent({ type: EvermedFormFieldErrors, selectors: [["evermed-form-field-errors"]], inputs: { errors: "errors", path: "path" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedFormFieldErrors_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedFormFieldErrors = __decorate([
    ProxyCmp({
        inputs: ['errors', 'path']
    })
], EvermedFormFieldErrors);
let EvermedIcon = class EvermedIcon {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedIcon.ɵfac = function EvermedIcon_Factory(t) { return new (t || EvermedIcon)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedIcon.ɵcmp = ɵɵdefineComponent({ type: EvermedIcon, selectors: [["evermed-icon"]], inputs: { type: "type" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedIcon_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedIcon = __decorate([
    ProxyCmp({
        inputs: ['type']
    })
], EvermedIcon);
let EvermedImage = class EvermedImage {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedImage.ɵfac = function EvermedImage_Factory(t) { return new (t || EvermedImage)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedImage.ɵcmp = ɵɵdefineComponent({ type: EvermedImage, selectors: [["evermed-image"]], inputs: { alt: "alt", maxHeight: "maxHeight", placeholder: "placeholder", spinner: "spinner", src: "src", upscale: "upscale" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedImage_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedImage = __decorate([
    ProxyCmp({
        inputs: ['alt', 'maxHeight', 'placeholder', 'spinner', 'src', 'upscale'],
        methods: ['ready']
    })
], EvermedImage);
let EvermedImageType = class EvermedImageType {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedImageType.ɵfac = function EvermedImageType_Factory(t) { return new (t || EvermedImageType)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedImageType.ɵcmp = ɵɵdefineComponent({ type: EvermedImageType, selectors: [["evermed-image-type"]], inputs: { emptyPlaceholder: "emptyPlaceholder", errorPlaceholder: "errorPlaceholder", form: "form", height: "height", model: "model", width: "width" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedImageType_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedImageType = __decorate([
    ProxyCmp({
        inputs: ['emptyPlaceholder', 'errorPlaceholder', 'form', 'height', 'model', 'width'],
        methods: ['clear', 'set']
    })
], EvermedImageType);
let EvermedInfiniteScroll = class EvermedInfiniteScroll {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedInfiniteScroll']);
    }
};
EvermedInfiniteScroll.ɵfac = function EvermedInfiniteScroll_Factory(t) { return new (t || EvermedInfiniteScroll)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedInfiniteScroll.ɵcmp = ɵɵdefineComponent({ type: EvermedInfiniteScroll, selectors: [["evermed-infinite-scroll"]], inputs: { disabled: "disabled", initialize: "initialize", threshold: "threshold" }, outputs: { evermedInfiniteScroll: "evermedInfiniteScroll" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedInfiniteScroll_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedInfiniteScroll = __decorate([
    ProxyCmp({
        inputs: ['disabled', 'initialize', 'threshold'],
        methods: ['complete']
    })
], EvermedInfiniteScroll);
let EvermedInputSearch = class EvermedInputSearch {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedChange', 'evermedSubmit']);
    }
};
EvermedInputSearch.ɵfac = function EvermedInputSearch_Factory(t) { return new (t || EvermedInputSearch)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedInputSearch.ɵcmp = ɵɵdefineComponent({ type: EvermedInputSearch, selectors: [["evermed-input-search"]], inputs: { collapsed: "collapsed", debounce: "debounce", disabled: "disabled", name: "name", placeholder: "placeholder", value: "value" }, outputs: { evermedChange: "evermedChange", evermedSubmit: "evermedSubmit" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedInputSearch_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedInputSearch = __decorate([
    ProxyCmp({
        inputs: ['collapsed', 'debounce', 'disabled', 'name', 'placeholder', 'value'],
        methods: ['clear']
    })
], EvermedInputSearch);
let EvermedLargeFileType = class EvermedLargeFileType {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedLargeFileType.ɵfac = function EvermedLargeFileType_Factory(t) { return new (t || EvermedLargeFileType)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedLargeFileType.ɵcmp = ɵɵdefineComponent({ type: EvermedLargeFileType, selectors: [["evermed-large-file-type"]], inputs: { current: "current", required: "required", types: "types" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedLargeFileType_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedLargeFileType = __decorate([
    ProxyCmp({
        inputs: ['current', 'required', 'types'],
        methods: ['set', 'clear']
    })
], EvermedLargeFileType);
let EvermedLikes = class EvermedLikes {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedChange']);
    }
};
EvermedLikes.ɵfac = function EvermedLikes_Factory(t) { return new (t || EvermedLikes)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedLikes.ɵcmp = ɵɵdefineComponent({ type: EvermedLikes, selectors: [["evermed-likes"]], inputs: { color: "color", colorActive: "colorActive", count: "count", disabled: "disabled", icon: "icon", label: "label", liked: "liked" }, outputs: { evermedChange: "evermedChange" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedLikes_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedLikes = __decorate([
    ProxyCmp({
        inputs: ['color', 'colorActive', 'count', 'disabled', 'icon', 'label', 'liked']
    })
], EvermedLikes);
class EvermedNotificationBell {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
}
EvermedNotificationBell.ɵfac = function EvermedNotificationBell_Factory(t) { return new (t || EvermedNotificationBell)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedNotificationBell.ɵcmp = ɵɵdefineComponent({ type: EvermedNotificationBell, selectors: [["evermed-notification-bell"]], ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedNotificationBell_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
let EvermedPlayButton = class EvermedPlayButton {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedPlayButton.ɵfac = function EvermedPlayButton_Factory(t) { return new (t || EvermedPlayButton)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedPlayButton.ɵcmp = ɵɵdefineComponent({ type: EvermedPlayButton, selectors: [["evermed-play-button"]], inputs: { backgroundColor: "backgroundColor", foregroundColor: "foregroundColor" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedPlayButton_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedPlayButton = __decorate([
    ProxyCmp({
        inputs: ['backgroundColor', 'foregroundColor']
    })
], EvermedPlayButton);
let EvermedPlaylistBuilder = class EvermedPlaylistBuilder {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedPlaylistBuilder.ɵfac = function EvermedPlaylistBuilder_Factory(t) { return new (t || EvermedPlaylistBuilder)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedPlaylistBuilder.ɵcmp = ɵɵdefineComponent({ type: EvermedPlaylistBuilder, selectors: [["evermed-playlist-builder"]], inputs: { filters: "filters", http: "http", name: "name", route: "route", router: "router" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedPlaylistBuilder_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedPlaylistBuilder = __decorate([
    ProxyCmp({
        inputs: ['filters', 'http', 'name', 'route', 'router']
    })
], EvermedPlaylistBuilder);
let EvermedPlaylistBuilderAvailableItem = class EvermedPlaylistBuilderAvailableItem {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedPlaylistBuilderItemSelect']);
    }
};
EvermedPlaylistBuilderAvailableItem.ɵfac = function EvermedPlaylistBuilderAvailableItem_Factory(t) { return new (t || EvermedPlaylistBuilderAvailableItem)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedPlaylistBuilderAvailableItem.ɵcmp = ɵɵdefineComponent({ type: EvermedPlaylistBuilderAvailableItem, selectors: [["evermed-playlist-builder-available-item"]], inputs: { item: "item", selected: "selected" }, outputs: { evermedPlaylistBuilderItemSelect: "evermedPlaylistBuilderItemSelect" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedPlaylistBuilderAvailableItem_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedPlaylistBuilderAvailableItem = __decorate([
    ProxyCmp({
        inputs: ['item', 'selected']
    })
], EvermedPlaylistBuilderAvailableItem);
let EvermedPlaylistBuilderAvailableItems = class EvermedPlaylistBuilderAvailableItems {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedPlaylistBuilderSelectionChange']);
    }
};
EvermedPlaylistBuilderAvailableItems.ɵfac = function EvermedPlaylistBuilderAvailableItems_Factory(t) { return new (t || EvermedPlaylistBuilderAvailableItems)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedPlaylistBuilderAvailableItems.ɵcmp = ɵɵdefineComponent({ type: EvermedPlaylistBuilderAvailableItems, selectors: [["evermed-playlist-builder-available-items"]], inputs: { available: "available", loading: "loading", selected: "selected" }, outputs: { evermedPlaylistBuilderSelectionChange: "evermedPlaylistBuilderSelectionChange" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedPlaylistBuilderAvailableItems_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedPlaylistBuilderAvailableItems = __decorate([
    ProxyCmp({
        inputs: ['available', 'loading', 'selected']
    })
], EvermedPlaylistBuilderAvailableItems);
let EvermedPlaylistBuilderFilter = class EvermedPlaylistBuilderFilter {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedPlaylistBuilderItemsLoaded', 'evermedPlaylistBuilderLoading']);
    }
};
EvermedPlaylistBuilderFilter.ɵfac = function EvermedPlaylistBuilderFilter_Factory(t) { return new (t || EvermedPlaylistBuilderFilter)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedPlaylistBuilderFilter.ɵcmp = ɵɵdefineComponent({ type: EvermedPlaylistBuilderFilter, selectors: [["evermed-playlist-builder-filter"]], inputs: { filters: "filters", repository: "repository" }, outputs: { evermedPlaylistBuilderItemsLoaded: "evermedPlaylistBuilderItemsLoaded", evermedPlaylistBuilderLoading: "evermedPlaylistBuilderLoading" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedPlaylistBuilderFilter_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedPlaylistBuilderFilter = __decorate([
    ProxyCmp({
        inputs: ['filters', 'repository']
    })
], EvermedPlaylistBuilderFilter);
let EvermedPlaylistBuilderSelectedItem = class EvermedPlaylistBuilderSelectedItem {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedPlaylistBuilderItemRemove']);
    }
};
EvermedPlaylistBuilderSelectedItem.ɵfac = function EvermedPlaylistBuilderSelectedItem_Factory(t) { return new (t || EvermedPlaylistBuilderSelectedItem)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedPlaylistBuilderSelectedItem.ɵcmp = ɵɵdefineComponent({ type: EvermedPlaylistBuilderSelectedItem, selectors: [["evermed-playlist-builder-selected-item"]], inputs: { item: "item" }, outputs: { evermedPlaylistBuilderItemRemove: "evermedPlaylistBuilderItemRemove" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedPlaylistBuilderSelectedItem_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedPlaylistBuilderSelectedItem = __decorate([
    ProxyCmp({
        inputs: ['item']
    })
], EvermedPlaylistBuilderSelectedItem);
let EvermedPlaylistBuilderSelectedItems = class EvermedPlaylistBuilderSelectedItems {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedPlaylistBuilderSelectionChange']);
    }
};
EvermedPlaylistBuilderSelectedItems.ɵfac = function EvermedPlaylistBuilderSelectedItems_Factory(t) { return new (t || EvermedPlaylistBuilderSelectedItems)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedPlaylistBuilderSelectedItems.ɵcmp = ɵɵdefineComponent({ type: EvermedPlaylistBuilderSelectedItems, selectors: [["evermed-playlist-builder-selected-items"]], inputs: { repository: "repository", selected: "selected" }, outputs: { evermedPlaylistBuilderSelectionChange: "evermedPlaylistBuilderSelectionChange" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedPlaylistBuilderSelectedItems_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedPlaylistBuilderSelectedItems = __decorate([
    ProxyCmp({
        inputs: ['repository', 'selected']
    })
], EvermedPlaylistBuilderSelectedItems);
let EvermedProgressBar = class EvermedProgressBar {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedProgressBar.ɵfac = function EvermedProgressBar_Factory(t) { return new (t || EvermedProgressBar)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedProgressBar.ɵcmp = ɵɵdefineComponent({ type: EvermedProgressBar, selectors: [["evermed-progress-bar"]], inputs: { max: "max", min: "min", value: "value" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedProgressBar_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedProgressBar = __decorate([
    ProxyCmp({
        inputs: ['max', 'min', 'value']
    })
], EvermedProgressBar);
let EvermedRegistrationForm = class EvermedRegistrationForm {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedRegistrationForm.ɵfac = function EvermedRegistrationForm_Factory(t) { return new (t || EvermedRegistrationForm)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedRegistrationForm.ɵcmp = ɵɵdefineComponent({ type: EvermedRegistrationForm, selectors: [["evermed-registration-form"]], inputs: { http: "http", router: "router" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedRegistrationForm_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedRegistrationForm = __decorate([
    ProxyCmp({
        inputs: ['http', 'router']
    })
], EvermedRegistrationForm);
let EvermedRegistrationFormRenderer = class EvermedRegistrationFormRenderer {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedRegistrationFormRendererSubmit', 'evermedRegistrationFormRendererHide']);
    }
};
EvermedRegistrationFormRenderer.ɵfac = function EvermedRegistrationFormRenderer_Factory(t) { return new (t || EvermedRegistrationFormRenderer)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedRegistrationFormRenderer.ɵcmp = ɵɵdefineComponent({ type: EvermedRegistrationFormRenderer, selectors: [["evermed-registration-form-renderer"]], inputs: { busy: "busy", errors: "errors", visible: "visible" }, outputs: { evermedRegistrationFormRendererSubmit: "evermedRegistrationFormRendererSubmit", evermedRegistrationFormRendererHide: "evermedRegistrationFormRendererHide" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedRegistrationFormRenderer_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedRegistrationFormRenderer = __decorate([
    ProxyCmp({
        inputs: ['busy', 'errors', 'visible']
    })
], EvermedRegistrationFormRenderer);
let EvermedSortableSelect = class EvermedSortableSelect {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedSortableSelect.ɵfac = function EvermedSortableSelect_Factory(t) { return new (t || EvermedSortableSelect)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedSortableSelect.ɵcmp = ɵɵdefineComponent({ type: EvermedSortableSelect, selectors: [["evermed-sortable-select"]], inputs: { mutable: "mutable", source: "source" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedSortableSelect_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedSortableSelect = __decorate([
    ProxyCmp({
        inputs: ['mutable', 'source']
    })
], EvermedSortableSelect);
let EvermedSortableSelectItem = class EvermedSortableSelectItem {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        proxyOutputs(this, this.el, ['evermedRemove']);
    }
};
EvermedSortableSelectItem.ɵfac = function EvermedSortableSelectItem_Factory(t) { return new (t || EvermedSortableSelectItem)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedSortableSelectItem.ɵcmp = ɵɵdefineComponent({ type: EvermedSortableSelectItem, selectors: [["evermed-sortable-select-item"]], inputs: { disabled: "disabled", item: "item", removable: "removable" }, outputs: { evermedRemove: "evermedRemove" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedSortableSelectItem_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedSortableSelectItem = __decorate([
    ProxyCmp({
        inputs: ['disabled', 'item', 'removable']
    })
], EvermedSortableSelectItem);
let EvermedSpinner = class EvermedSpinner {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedSpinner.ɵfac = function EvermedSpinner_Factory(t) { return new (t || EvermedSpinner)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedSpinner.ɵcmp = ɵɵdefineComponent({ type: EvermedSpinner, selectors: [["evermed-spinner"]], inputs: { inverse: "inverse", size: "size", speed: "speed", type: "type" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedSpinner_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedSpinner = __decorate([
    ProxyCmp({
        inputs: ['inverse', 'size', 'speed', 'type']
    })
], EvermedSpinner);
let EvermedTextareaAutosize = class EvermedTextareaAutosize {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedTextareaAutosize.ɵfac = function EvermedTextareaAutosize_Factory(t) { return new (t || EvermedTextareaAutosize)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedTextareaAutosize.ɵcmp = ɵɵdefineComponent({ type: EvermedTextareaAutosize, selectors: [["evermed-textarea-autosize"]], inputs: { disabled: "disabled" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedTextareaAutosize_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedTextareaAutosize = __decorate([
    ProxyCmp({
        inputs: ['disabled']
    })
], EvermedTextareaAutosize);
let EvermedToggleContent = class EvermedToggleContent {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedToggleContent.ɵfac = function EvermedToggleContent_Factory(t) { return new (t || EvermedToggleContent)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedToggleContent.ɵcmp = ɵɵdefineComponent({ type: EvermedToggleContent, selectors: [["evermed-toggle-content"]], inputs: { input: "input", listen: "listen", match: "match", property: "property" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedToggleContent_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedToggleContent = __decorate([
    ProxyCmp({
        inputs: ['input', 'listen', 'match', 'property']
    })
], EvermedToggleContent);
let EvermedTranslate = class EvermedTranslate {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedTranslate.ɵfac = function EvermedTranslate_Factory(t) { return new (t || EvermedTranslate)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedTranslate.ɵcmp = ɵɵdefineComponent({ type: EvermedTranslate, selectors: [["evermed-translate"]], inputs: { html: "html", message: "message" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedTranslate_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedTranslate = __decorate([
    ProxyCmp({
        inputs: ['html', 'message']
    })
], EvermedTranslate);
let EvermedTranslateAttribute = class EvermedTranslateAttribute {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedTranslateAttribute.ɵfac = function EvermedTranslateAttribute_Factory(t) { return new (t || EvermedTranslateAttribute)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedTranslateAttribute.ɵcmp = ɵɵdefineComponent({ type: EvermedTranslateAttribute, selectors: [["evermed-translate-attribute"]], inputs: { attribute: "attribute", message: "message" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedTranslateAttribute_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedTranslateAttribute = __decorate([
    ProxyCmp({
        inputs: ['attribute', 'message']
    })
], EvermedTranslateAttribute);
let EvermedVideoAnalytics = class EvermedVideoAnalytics {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedVideoAnalytics.ɵfac = function EvermedVideoAnalytics_Factory(t) { return new (t || EvermedVideoAnalytics)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedVideoAnalytics.ɵcmp = ɵɵdefineComponent({ type: EvermedVideoAnalytics, selectors: [["evermed-video-analytics"]], inputs: { http: "http", identifier: "identifier", router: "router", segmentBufferSize: "segmentBufferSize", segmentThreshold: "segmentThreshold", selector: "selector", user: "user" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedVideoAnalytics_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedVideoAnalytics = __decorate([
    ProxyCmp({
        inputs: ['http', 'identifier', 'router', 'segmentBufferSize', 'segmentThreshold', 'selector', 'user']
    })
], EvermedVideoAnalytics);
let EvermedVideoErrorScreen = class EvermedVideoErrorScreen {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedVideoErrorScreen.ɵfac = function EvermedVideoErrorScreen_Factory(t) { return new (t || EvermedVideoErrorScreen)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedVideoErrorScreen.ɵcmp = ɵɵdefineComponent({ type: EvermedVideoErrorScreen, selectors: [["evermed-video-error-screen"]], inputs: { delay: "delay", message: "message" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedVideoErrorScreen_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedVideoErrorScreen = __decorate([
    ProxyCmp({
        inputs: ['delay', 'message']
    })
], EvermedVideoErrorScreen);
let EvermedVideoPlayButton = class EvermedVideoPlayButton {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedVideoPlayButton.ɵfac = function EvermedVideoPlayButton_Factory(t) { return new (t || EvermedVideoPlayButton)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedVideoPlayButton.ɵcmp = ɵɵdefineComponent({ type: EvermedVideoPlayButton, selectors: [["evermed-video-play-button"]], inputs: { player: "player" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedVideoPlayButton_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedVideoPlayButton = __decorate([
    ProxyCmp({
        inputs: ['player']
    })
], EvermedVideoPlayButton);
let EvermedVideoThumbnail = class EvermedVideoThumbnail {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
    }
};
EvermedVideoThumbnail.ɵfac = function EvermedVideoThumbnail_Factory(t) { return new (t || EvermedVideoThumbnail)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedVideoThumbnail.ɵcmp = ɵɵdefineComponent({ type: EvermedVideoThumbnail, selectors: [["evermed-video-thumbnail"]], inputs: { player: "player", src: "src" }, ngContentSelectors: _c0, decls: 1, vars: 0, template: function EvermedVideoThumbnail_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedVideoThumbnail = __decorate([
    ProxyCmp({
        inputs: ['player', 'src']
    })
], EvermedVideoThumbnail);

const _c0$1 = ["*"];
const evermedVideoPlayerProxiedInputs = [
    'autoplay',
    'controls',
    'currentTime',
    'paused',
    'muted',
    'volume',
    'aspectRatio',
    'width',
    'height',
    'loop',
    'poster',
    'fluid',
];
const evermedVideoPlayerProxiedOutputs = [
    'abort',
    'beforepluginsetup',
    'canplay',
    'canplaythrough',
    'componentresize',
    'controlsdisabled',
    'controlsenabled',
    'dispose',
    'durationchange',
    'emptied',
    'ended',
    'enterFullWindow',
    'enterpictureinpicture',
    'error',
    'exitFullWindow',
    'firstplay',
    'fullscreenchange',
    'leavepictureinpicture',
    'loadeddata',
    'loadedmetadata',
    'loadstart',
    'pause',
    'play',
    'playerresize',
    'playing',
    'pluginsetup',
    'posterchange',
    'progress',
    'ratechange',
    'ready',
    'resize',
    'seeked',
    'seeking',
    'sourceset',
    'stalled',
    'suspend',
    'tap',
    'textdata',
    'texttrackchange',
    'timeupdate',
    'useractive',
    'userinactive',
    'usingcustomcontrols',
    'usingnativecontrols',
    'volumechange',
    'waiting',
    'inaccessible',
];
const evermedVideoPlayerStenciljsInputs = ['src', 'thumbnail', 'type'];
let EvermedVideoPlayer = class EvermedVideoPlayer {
    constructor(c, r, z) {
        this.z = z;
        c.detach();
        this.el = r.nativeElement;
        evermedVideoPlayerProxiedOutputs.forEach((eventName) => {
            let prop = eventName;
            if (-1 !== ['play', 'pause'].indexOf(eventName)) {
                prop = `_${eventName}`;
            }
            this[prop] = fromEvent(this.el, eventName);
        });
    }
};
EvermedVideoPlayer.ɵfac = function EvermedVideoPlayer_Factory(t) { return new (t || EvermedVideoPlayer)(ɵɵdirectiveInject(ChangeDetectorRef), ɵɵdirectiveInject(ElementRef), ɵɵdirectiveInject(NgZone)); };
EvermedVideoPlayer.ɵcmp = ɵɵdefineComponent({ type: EvermedVideoPlayer, selectors: [["evermed-video-player"]], inputs: { src: "src", thumbnail: "thumbnail", type: "type", autoplay: "autoplay", controls: "controls", currentTime: "currentTime", paused: "paused", muted: "muted", volume: "volume", aspectRatio: "aspectRatio", width: "width", height: "height", loop: "loop", poster: "poster", fluid: "fluid" }, outputs: { abort: "abort", beforepluginsetup: "beforepluginsetup", canplay: "canplay", canplaythrough: "canplaythrough", componentresize: "componentresize", controlsdisabled: "controlsdisabled", controlsenabled: "controlsenabled", dispose: "dispose", durationchange: "durationchange", emptied: "emptied", ended: "ended", enterFullWindow: "enterFullWindow", enterpictureinpicture: "enterpictureinpicture", error: "error", exitFullWindow: "exitFullWindow", firstplay: "firstplay", fullscreenchange: "fullscreenchange", leavepictureinpicture: "leavepictureinpicture", loadeddata: "loadeddata", loadedmetadata: "loadedmetadata", loadstart: "loadstart", pause: "pause", play: "play", playerresize: "playerresize", playing: "playing", pluginsetup: "pluginsetup", posterchange: "posterchange", progress: "progress", ratechange: "ratechange", ready: "ready", resize: "resize", seeked: "seeked", seeking: "seeking", sourceset: "sourceset", stalled: "stalled", suspend: "suspend", tap: "tap", textdata: "textdata", texttrackchange: "texttrackchange", timeupdate: "timeupdate", useractive: "useractive", userinactive: "userinactive", usingcustomcontrols: "usingcustomcontrols", usingnativecontrols: "usingnativecontrols", volumechange: "volumechange", waiting: "waiting", inaccessible: "inaccessible", _pause: "pause", _play: "play" }, ngContentSelectors: _c0$1, decls: 1, vars: 0, template: function EvermedVideoPlayer_Template(rf, ctx) { if (rf & 1) {
        ɵɵprojectionDef();
        ɵɵprojection(0);
    } }, encapsulation: 2, changeDetection: 0 });
EvermedVideoPlayer = __decorate([
    ProxyCmp({
        inputs: [...evermedVideoPlayerStenciljsInputs, ...evermedVideoPlayerProxiedInputs],
    })
], EvermedVideoPlayer);

defineCustomElements(window);
class EvermedComponentsAngularModule {
}
EvermedComponentsAngularModule.ɵmod = ɵɵdefineNgModule({ type: EvermedComponentsAngularModule });
EvermedComponentsAngularModule.ɵinj = ɵɵdefineInjector({ factory: function EvermedComponentsAngularModule_Factory(t) { return new (t || EvermedComponentsAngularModule)(); } });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && ɵɵsetNgModuleScope(EvermedComponentsAngularModule, { declarations: [EvermedAlert,
        EvermedBadge,
        EvermedBookmark,
        EvermedComments,
        EvermedContent,
        EvermedDateRangeType,
        EvermedDateType,
        EvermedDropzone,
        EvermedImage,
        EvermedImageType,
        EvermedInputSearch,
        EvermedLikes,
        EvermedNotificationBell,
        EvermedPlayButton,
        EvermedSpinner,
        EvermedToggleContent,
        EvermedVideoPlayer,
        EvermedIcon,
        EvermedDropdown,
        EvermedTextareaAutosize,
        EvermedEqualizer,
        EvermedInfiniteScroll], exports: [EvermedAlert,
        EvermedBadge,
        EvermedBookmark,
        EvermedComments,
        EvermedContent,
        EvermedDateRangeType,
        EvermedDateType,
        EvermedDropzone,
        EvermedImage,
        EvermedImageType,
        EvermedInputSearch,
        EvermedLikes,
        EvermedNotificationBell,
        EvermedPlayButton,
        EvermedSpinner,
        EvermedToggleContent,
        EvermedVideoPlayer,
        EvermedIcon,
        EvermedDropdown,
        EvermedTextareaAutosize,
        EvermedEqualizer,
        EvermedInfiniteScroll] }); })();

export { EvermedAccessCodeConstraints, EvermedAccessCodeCountryConstraint, EvermedAccessCodeTotalConstraint, EvermedAccessCodeUnconstrained, EvermedAlert, EvermedBadge, EvermedBookmark, EvermedChoiceType, EvermedComments, EvermedComponentsAngularModule, EvermedContent, EvermedDateRangeType, EvermedDateType, EvermedDownloadAnalytics, EvermedDropdown, EvermedDropzone, EvermedEqualizer, EvermedFormFieldErrors, EvermedIcon, EvermedImage, EvermedImageType, EvermedInfiniteScroll, EvermedInputSearch, EvermedLargeFileType, EvermedLikes, EvermedNotificationBell, EvermedPlayButton, EvermedPlaylistBuilder, EvermedPlaylistBuilderAvailableItem, EvermedPlaylistBuilderAvailableItems, EvermedPlaylistBuilderFilter, EvermedPlaylistBuilderSelectedItem, EvermedPlaylistBuilderSelectedItems, EvermedProgressBar, EvermedRegistrationForm, EvermedRegistrationFormRenderer, EvermedSortableSelect, EvermedSortableSelectItem, EvermedSpinner, EvermedTextareaAutosize, EvermedToggleContent, EvermedTranslate, EvermedTranslateAttribute, EvermedVideoAnalytics, EvermedVideoErrorScreen, EvermedVideoPlayButton, EvermedVideoPlayer, EvermedVideoThumbnail };
