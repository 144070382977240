import { DOCUMENT }              from '@angular/common';
import {
    ApplicationRef,
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    EmbeddedViewRef,
    Inject,
    Injectable,
    Injector,
    Type,
}                                from '@angular/core';
import { first }                 from 'rxjs/operators';
import { ModalOptionsInterface } from './modal-options.interface';
import { ModalReference }        from './modal-reference';
import { ModalComponent }        from '../../components/modal/modal.component';

@Injectable()
export class ModalController {

    private readonly _componentFactoryResolver: ComponentFactoryResolver;

    private readonly _applicationRef: ApplicationRef;

    private readonly _injector: Injector;

    private readonly _document: Document;

    public constructor(
        componentFactoryResolver: ComponentFactoryResolver,
        applicationRef: ApplicationRef,
        injector: Injector,
        @Inject(DOCUMENT) document: Document,
    ) {
        this._componentFactoryResolver = componentFactoryResolver;
        this._applicationRef           = applicationRef;
        this._injector                 = injector;
        this._document                 = document;
    }

    public async create<T>(component: Type<T>, options: ModalOptionsInterface): Promise<ModalReference<T>> {
        let props: { [name: string]: any }                           = {
            componentType: component,
            ...options,
        };
        let modalComponentReference: ComponentRef<ModalComponent<T>> = this.createModal<T>(props);
        let instance: ModalComponent<T>                              = modalComponentReference.instance;

        instance.dismissed.pipe(first()).subscribe((): void => {
            this.destroyModal<T>(modalComponentReference);
        });

        let componentReference: T = await instance.displayed.toPromise();

        return new ModalReference(modalComponentReference, componentReference);
    }

    private createModal<T>(props: { [name: string]: any }): ComponentRef<ModalComponent<T>> {
        let factory: ComponentFactory<ModalComponent<T>>;
        let modalComponentReference: ComponentRef<ModalComponent<T>>;
        let domElement: HTMLElement;

        factory                 = this._componentFactoryResolver.resolveComponentFactory<ModalComponent<T>>(ModalComponent);
        modalComponentReference = factory.create(this._injector);

        // ensure that "inputs" exists and additionally provide dismiss reference
        props.inputs = {
            dismissRef: modalComponentReference.instance.dismiss.bind(modalComponentReference.instance),
            ...(props.inputs || {}),
        };

        Object.keys(props).forEach((key: string): void => {
            modalComponentReference.instance[key] = props[key];
        });

        this._applicationRef.attachView(modalComponentReference.hostView);

        domElement = (modalComponentReference.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        this._document.body.appendChild(domElement);

        return modalComponentReference;
    }

    private destroyModal<T>(componentReference: ComponentRef<ModalComponent<T>>): void {
        this._applicationRef.detachView(componentReference.hostView);
        componentReference.destroy();
    }

}
