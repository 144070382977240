import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
}                      from '@angular/core';
import { FormGroup }   from '@angular/forms';
import {
    BehaviorSubject,
    fromEvent,
    Observable,
}                      from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Directive({
    selector: 'form[formGroup]',
})
export class FormSubmitDirective implements OnChanges {

    @Input()
    public formGroup: FormGroup;

    private readonly _submit$: Observable<Event>;

    private readonly _change$: BehaviorSubject<FormGroup | null> = new BehaviorSubject<FormGroup | null>(null);

    public constructor(host: ElementRef<HTMLFormElement>) {
        this._submit$ = <Observable<Event>>fromEvent(host.nativeElement, 'submit').pipe(shareReplay(1));
    }

    public get submitted(): Observable<Event> {
        return this._submit$;
    }

    public get change(): Observable<FormGroup | null> {
        return this._change$.asObservable();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.formGroup) {
            this._change$.next(changes.formGroup.currentValue);
        }
    }

}
